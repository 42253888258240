import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { EnvService } from './env.service';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Router } from '@angular/router';
import { of, Observable } from 'rxjs';
import { delay } from 'rxjs/internal/operators';
import { TabConfig } from './tabs-config';
interface query_history {
  date: Date,
  query_name: string,
  query_string: string,
  is_result_data:boolean
}

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  isLoading = new BehaviorSubject(false);
  // Variables Initialised
    user_dashgroup_data = [];
    group_dashboard_data = [];
    user_name = '';
    dds_ver_id = null;
    roles_map = {};
    resetCache = false
    cached_data: any = {}
    query_history = [];
    tab_panel_json = this.tabs_config.tabs_entities //

  constructor(
    private _common_service: CommonService,
    private _router: Router,
    private _env: EnvService,
    public tabs_config: TabConfig
  ) {

    this.user_name = localStorage.getItem('user_name');
    // if ( this.user_name != undefined) {
    //   this.page_refresh();
    // }

   }

   refresh_timer_list = [
    {
      'value_value': -1,
      'value_name': 'Use Dataview Timer'
    },
    {
      'value_value': 0,
      'value_name': '0s (No Auto Refresh)'
    },
    {
      'value_value': 5,
      'value_name': '5s'
    },
    {
      'value_value': 15,
      'value_name': '15s'
    },
    {
      'value_value': 30,
      'value_name': '30s'
    },
    {
      'value_value': 60,
      'value_name': '1m'
    },
    {
      'value_value': 300,
      'value_name': '5m'
    }
  ]

  async page_refresh() {
    // make service call to update roles_map here!!

    // tslint:disable-next-line:max-line-length
    const filter1 = '?q={"filters":[{"name":"username","op":"eq","val":"' + this.user_name + '"}],"order_by":[{"field":"order","direction":"asc"}]}';

    // tslint:disable-next-line:max-line-length
    const filter2 = '?q={"filters":[{"name":"username","op":"eq","val":"' + this.user_name + '"}],"order_by":[{"field":"order","direction":"asc"}]}';
    let dg_payload = {
      user_name:this.user_name,
      user_profile_name: localStorage.getItem('internal_role')
    }
    var sidebar_data_2 = await this._common_service.get_dshb_grp(dg_payload).toPromise();
    if(sidebar_data_2.errCode == 0){
      var sidebar_data_1 = await this._common_service.get_all_user_dashboard(dg_payload).toPromise();
      if(sidebar_data_1.errCode == 0){
        this.user_dashgroup_data = sidebar_data_2.data;
        this.group_dashboard_data = sidebar_data_1.data;

        localStorage.setItem( 'sidebar_data_1' , JSON.stringify(sidebar_data_1.data));
        localStorage.setItem( 'sidebar_data_2' , JSON.stringify(sidebar_data_2.data));
      }
    }
  }

  get_refresh_classification(){
    return this.refresh_timer_list;
  }


  // update_role_map(roles) {
  //   this.sidebar_data_list_1 = roles;
  // }

  // tslint:disable-next-line:member-ordering
  private sidebar_data_1 = new BehaviorSubject(JSON.parse( localStorage.getItem( 'sidebar_data_1' )));
  // tslint:disable-next-line:member-ordering
  current_sidebar_data_1 = this.sidebar_data_1.asObservable();

  change_sidebar_data_1(data) {
    this.sidebar_data_1.next(data);
  }

  // update_role_map(roles) {
  //   this.sidebar_data_list_1 = roles;
  // }

  // tslint:disable-next-line:member-ordering
  private sidebar_data_2 = new BehaviorSubject(JSON.parse( localStorage.getItem( 'sidebar_data_2' )));
  // tslint:disable-next-line:member-ordering
  current_sidebar_data_2 = this.sidebar_data_2.asObservable();

  change_sidebar_data_2(data) {
    this.sidebar_data_2.next(data);
  }

  // method to show/hide spinner
  spinnerVisibility(is_visible) {
    this.isLoading.next(is_visible);
  }

  set_dds_ver_id(version_id) {
		this.dds_ver_id = version_id
  }
  
    // ROLES CODE
    async promise_fn() {
      if (localStorage.getItem('token') != undefined && localStorage.getItem('token') != null) {
        var res = await this._common_service.get_user_role_map({}).toPromise();
        this.roles_map = res.msg.component_data;
        // this.get_data_after_refresh();
        // this.get_user_role_map();
        return res;
      } else {
        this._router.navigate(['./sessions/signin']);
      }
    }
  
    shared_services_before_routing(): Observable<any> {
      var res = this.promise_fn();
  
      // forced 1 second delay on refresh
      return of(res).pipe(delay(1000));
  
    }
  
    get_roles_map(){
      return this.roles_map;
    }
  
    check_dt_role_access(key) {
      var param = key.split("__");
  
      if(this.roles_map[param[0]] != undefined && this.roles_map[param[0]][param[1]] != undefined){
        if(this.roles_map[param[0]][param[1]] == 1){
          return true;
        } else {
          return false;
        }
      } else {
        return true;    //  should be false.. commiting for demo purpose.
      }
    }

  set_cached_data(service, params, response) {
    switch (service) {
      case "/whapi/all_ao_map":
      case "/whapi/getTableColumns":
        if (this.cached_data[service] == undefined) {
          this.cached_data[service] = response
        }
        break;
    }
  }

  get_cached_data(service, params) {
    let data = {}
    switch (service) {
      case "/whapi/all_ao_map":
      case "/whapi/getTableColumns":
        if (this.cached_data[service] != undefined) {
          data = this.cached_data[service]
        } else {
          return { "status": false, "data": data }
        }
        break;
    }
    return { "status": true, "data": data }
  }

  add_query_to_query_history(query_obj:query_history){
    console.log(query_obj)
    const length = this.query_history.length;
    this.query_history.push(query_obj)
    this.query_history = [...this.query_history];
    if(length >= this._env.max_query_history_length){
      this.query_history = this.query_history.slice(-this._env.max_query_history_length);
      this.query_history = [...this.query_history];
    }
  }

  reset_query_history(){   // to reset query history
    this.query_history = [];
  }

  reset_tabs_config(){  //to reset query tabs
    this.tab_panel_json = this.tabs_config.reset_tabs_config();
  }

}