import { Component, OnInit,EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
@Component({
  selector: 'app-datatable-search',
  templateUrl: './datatable-search.component.html',
  styleUrls: ['./datatable-search.component.css']
})
export class DatatableSearchComponent implements OnInit, OnChanges {

  input_search: FormControl = new FormControl();
  @Input() placeholder_text: string;
  @Input() unique_id =  '';
  @Output() form_search_event: EventEmitter<any> = new EventEmitter<any>();
  @Input() default_value?: string = '';
  @Input() debounceTime?: any = 0; // in milliseconds
  @Input() min_search_length?: Number = 0;
  constructor() { }

  ngOnInit() {
    this.input_search.valueChanges
    .pipe(debounceTime(this.debounceTime))
    .subscribe(value => {
      this.input_search_change_v2(value);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.default_value) {
      this.input_search.setValue(changes.default_value.currentValue)
    }
  }

  search_dt_data(){
    if (this.input_search.value != '' && this.input_search.value != null) {
      this.search_event(this.input_search.value)
    }
  }

  clear(event){}

  private clear_event(value){
    this.form_search_event.emit({'type': 'clear','data': value });
  }

  private search_event(value){
    this.form_search_event.emit({'type': 'search','data': value });
  }

  private change_event(value){
    this.form_search_event.emit({'type': 'change','data': value });
  }

  // input_search_change(event){
  //   console.log(event)
  //   if (event.keyCode != '13') {
  //     if(this.input_search.value != '' && this.input_search.value != null){
  //       this.form_search_event.emit({'type': 'change','data': event.target.value });
  //     }else{
  //       this.clear_event(event);
  //     }
  //   }  
  // }

  private input_search_change_v2(value){
    console.log(value)
    if(value.length > 0) {
      if(value.length >= this.min_search_length) {
        this.change_event(value)
      }
    } else {
      this.clear_event(value);
    }
  }
  public reset_search() {
    this.input_search.setValue("");
  }

}
