import { Component, OnInit, Input, OnChanges, SimpleChanges, OnDestroy, Output, EventEmitter } from '@angular/core';
// import { ViewReportDashboardService } from '../view-report-dashboard.service'
import { ConfigService } from '../../config-builder/service/config.service';
declare var require: any
declare var $:any;
declare var moment:any;
@Component({
  selector: 'app-view-drill-report',
  templateUrl: './view-drill-report.component.html',
  styleUrls: ['./view-drill-report.component.css']
})
export class ViewDrillReportComponent implements OnInit, OnChanges, OnDestroy {
	@Input() report_id = -1;
	report_type = 'multi_level';
	@Input() hidden_filter_object?: any = {};
	@Input() visible_filter_object?: any = {};
	@Input() group_child_report?:boolean = false;
	@Output() ext_btn_event: EventEmitter<any> = new EventEmitter<any>();
	@Input() refresh_timer?: any = -1;
	@Input() pause_refresh_timer?: any = true;
	ext_buttons = []
	data_view_type="drill_report"
	afterService = false;
	show_collapse_all = true
	query_id = -1;
	show_refresh = false
	loading =false;
	report_data_list = []
	cols= []
    attributes_added = false;
    current_index = -1;
	param_def = {
		'hidden_param_val':[],
		'param_val':[],
		'max_records':100
	}
	report_attributes = [{
      "name": "Percentage",
      "check": false
    },{
      "name": "Minimum",
      "check": false
    },{
      "name": "Maximum",
      "check": false
    },{
      "name": "Mean and S.D.",
      "check": false
    // },{
    //   "name": "Standard Deviation",
    //   "check": false
    }];
    qry_string = ''
	levelMap:any = {}
	max_level = 1
	curMaxLevel = 0;
	level_list = []
	row_detail = []
	col_detail = []
	data_view_config: any = {};
	allow_refresh = false;
	interval: any = {}
	auto_refresh_report_types = ['style','flat_pivot']
	constructor(
	    public _configService: ConfigService,

	) { }


	ngOnInit() {
		if(this.report_id != -1){
			this.loading = true
			this.get_report_data();
		}

	}
	ngOnChanges(changes:SimpleChanges) {
		if(this.report_id != -1 && changes['visible_filter_object'] != undefined && changes['visible_filter_object']['previousValue'] != undefined){
			this.loading = true
			this.override_filters();
		}
		if(changes.pause_refresh_timer != undefined && changes.pause_refresh_timer.currentValue != undefined && changes.pause_refresh_timer.currentValue == -1 ){
			this.clear_refresh_interval();
		}
	}

	ngOnDestroy(){
		this.clear_refresh_interval();
	}

	close_modal(){
		this.clear_refresh_interval();
	}

	report_style_config= {
		'report_rules': [],
		'show_aggregate_values': true,
		'calculate_pivot_aggregate': false,
	}
	async get_report_data(){
		let res1 = await this._configService.get_report_by_id({'id':this.report_id}).toPromise()
		this.cols = res1.data.col_def
		this.show_refresh = (res1.data.report_style_config['show_refresh']!= null && res1.data.report_style_config['show_refresh']!= undefined) ?res1.data.report_style_config['show_refresh']:true
		if(res1.data.report_style_config){
			this.report_style_config = res1.data.report_style_config
		}
		let is_drill_report = this.auto_refresh_report_types.includes(res1.data.report_type)?false:true;
		if (this.refresh_timer && !is_drill_report && this.refresh_timer != -1) {
			if(res1.data.report_type != ''){
				this.allow_refresh= true;
				this.pause_refresh_timer = false;
				this.start_refresh_timer();
			}
		}
		else if(!res1.data.report_style_config.auto_refresh_time || is_drill_report || !this.refresh_timer){
			this.allow_refresh = false;
		}else{
			this.refresh_timer = res1.data.report_style_config.auto_refresh_time
			this.allow_refresh = true;
			this.pause_refresh_timer = false;
			this.start_refresh_timer();
		}
		if(res1.data.param_def != null){
			this.param_def = res1.data.param_def;
			if(res1.data.param_def.lg_data_recs_order && res1.data.param_def.lg_data_recs_order!= ""){
				this.col_detail = res1.data.param_def.lg_data_recs_order.split(";")
			}
		}
		if(res1.data.report_type){
			this.report_type = res1.data.report_type
		}else{
			this.report_type = 'multi_level'
		}
		let filter_object = {...this.hidden_filter_object, ...this.visible_filter_object}; 

		let temp_payload = {
			'hidden_filter_data':this.param_def.hidden_param_val,
			'filter_data':this.param_def.param_val,
			"hidden_filter_object": filter_object
		}
  	this.ext_buttons = []
		this.data_view_config = res1.data.data_view_config? res1.data.data_view_config:{};
		if(this.data_view_config){
			if(this.data_view_config.external_buttons){
    		this.ext_buttons = this.data_view_config.external_buttons
			}
			if(this.data_view_config['show_view_filter'] == null || this.data_view_config['show_view_filter'] == undefined){
				this.data_view_config['show_view_filter'] = false
			}
			if(this.data_view_config['show_lg_data_recs'] == null || this.data_view_config['show_lg_data_recs'] == undefined){
				this.data_view_config['show_lg_data_recs'] = true
			}
			if(this.data_view_config['show_lg_data_download'] == null || this.data_view_config['show_lg_data_download'] == undefined){
				this.data_view_config['show_lg_data_download'] = true
			}
    }else{
    }
		this.query_id = res1.data.query_id
		let res4 = await this._configService.process_query_filters(temp_payload).toPromise()
		if(res4.errCode == 0){
			this.param_def.hidden_param_val = res4.msg.hidden_filter_data;
			this.param_def.param_val = res4.msg.filter_data;
			this.override_filters();
			
		}
   	}

	start_refresh_timer(){
		if(!this.interval["report_"+this.report_id]){
			this.interval["report_"+this.report_id]=setInterval(()=>{
				if(!this.pause_refresh_timer){
					this.refresh_report_data(false, 'auto_refresh');
				}
			}, this.refresh_timer * 1000);
		}
	}

	clear_refresh_interval(){
		if(this.interval["report_"+this.report_id]){
			clearInterval(this.interval["report_"+this.report_id]);
			this.interval["report_"+this.report_id]=null;
		}
	}


	checkLevel(name){
		let tname = name.split("_").slice(-1)[0]
		let valid = false
		if(!isNaN(tname)){
			valid = true;
		}
		return [valid,tname]
	}
   	setLevelMap(){
   		this.max_level = 1
   		this.levelMap = {}
   		this.level_list = []
   		for (var i = this.cols.length - 1; i >= 0; i--) {
   			let chkCol = this.checkLevel(this.cols[i].col_name) 
   			if(chkCol[0]){
   				this.levelMap[chkCol[1]] = this.cols[i].col_name
   				if(this.level_list.indexOf(chkCol) == -1){
   					this.level_list.push(chkCol[1])
   				}
   				this.max_level += 1
   			}
   		}
   		this.level_list.sort()
   		this.curMaxLevel = this.max_level-1
   	}
   	first_drill_row:any = {}
   	process_data(data,parent=""){
   		// console.log(data)
   		let col:any = {}
   		for (var i = this.cols.length - 1; i >= 0; i--) {
   			col[this.cols[i].col_name] = null
   		}
		let drill = data.drill_options
		if(this.report_type == 'multi_level' && parent == ""){
			this.first_drill_row = data.widget_map
		}
		let children = data.children
		let temp_list = []
		// for (var i = drill.length - 1; i >= 0; i--) {
		for (var i = 0 ; i < drill.length ; i++) {
			if(this.levelMap[children[drill[i]].level-1]){
				col[this.levelMap[children[drill[i]].level-1]] = {
					'value':drill[i],
					'style':{}
				}
				let colKeys = Object.keys(col) 
				for (var j = colKeys.length - 1; j >= 0; j--) {
					if(children[drill[i]].widget_map[colKeys[j]]){

						let styleData = this.getStyleForValue(children[drill[i]].widget_map[colKeys[j]],colKeys[j],children[drill[i]].level)
						col[colKeys[j]] = {
							'value':children[drill[i]].widget_map[colKeys[j]],
							'style': styleData['_css'],
							'custom_value': styleData['_custom_value'],
							'show_cell_value': styleData['_show_cell_value'],
							'rule_status': styleData['_rule_status'],

						}
					}
				}
				if(children[drill[i]].widget_map['lg_data_recs']){
					col['lg_data_recs'] = children[drill[i]].widget_map['lg_data_recs']
				}
				col['level'] = children[drill[i]].level
				col['parent'] = parent.replace(/&/g,'_')
				col['collapse'] = false
				temp_list.push(JSON.parse(JSON.stringify(col)))
				if(children[drill[i]].drill_options.length > 0){
					let cur_parent = parent+"_"+drill[i].replace(/[^0-9 a-z A-Z]+/g,"_").replace(/\s/g, '').replace(/&/g,'_').split('').reverse().join("")
					let tempList = this.process_data(children[drill[i]],cur_parent)
					temp_list = JSON.parse(JSON.stringify(temp_list)).concat(tempList)
				}
			}
		}
		// console.log(temp_list)
		return temp_list
	}
   	async override_filters(){
   		let data = this._configService.process_and_override_filter(this.param_def.hidden_param_val,this.param_def.param_val,this.hidden_filter_object,this.visible_filter_object)
		this.param_def.hidden_param_val = data["hidden_filter_data"]
		this.param_def.param_val = data["filter_data"]
		if(!this.param_def.max_records){
			this.param_def.max_records = 100
		}

		let res2 = await this._configService.get_query_by_id({'id':this.query_id}).toPromise()
      	{
	      	this.qry_string = res2.data.qry_string;

			this.refresh_report_data();

      	}
   	}

   	pivot_cols_dict = {}
   	view_filter_data = []
	async refresh_report_data(show_loader = true, action="default"){
		if(show_loader){
			this.loading = true
		}
		if(action == "default"){
			this.report_data_list = [];
		}
		this.afterService = false;
		this.view_filter_data = JSON.parse(JSON.stringify(this.param_def.param_val));
		let payload = {
			"report_id": this.report_id,
			"filter_data": this.param_def.param_val,
			"hidden_filter_data": this.param_def.hidden_param_val,
			"drilldown_order_data": []
		}
		if(this.report_type == 'multi_level'){
			let res = await this._configService.get_drill_report(payload).toPromise();
			if(res.errCode == 0){
				this.setLevelMap()
				if(Object.keys(res.msg).length == 0){
					this.afterService = true
				}else{
					this.report_data_list = res.msg
					// this.report_data_list = this.process_data(res.msg)
					this.color_creator('#c4c6c8',"#ffffff")
				}

			}
		}else if(this.report_type == 'pivot'){
			let res = await this._configService.get_pivot_report(payload).toPromise();
			if(res.errCode == 0){
				this.cols = res.pivot_cols
				this.setLevelMap()
				for (var i = res.pivot_cols.length - 1; i >= 0; i--) {
					this.pivot_cols_dict[res.pivot_cols[i].col_name] =  res.pivot_cols[i]
				}
				// this.report_data_list = res.msg
				if(res.msg.length == 0){
					this.afterService = true
				}else{
					this.report_data_list = res.msg
					// this.report_data_list = this.process_data(res.msg)
					this.color_creator('#c4c6c8',"#ffffff")
				}

			}
		}else if(this.report_type == 'style'){
			payload['limit'] = this.param_def.max_records;
			let res = await this._configService.get_style_report(payload).toPromise();
			if(res.errCode == 0){
				this.cols = res.col_def
				this.setLevelMap()
				if(res.msg.length == 0){
					this.afterService = true
				}else{
					this.report_data_list = res.msg
					this.color_creator('#c4c6c8',"#ffffff")
				}

			}
		}else if (this.report_type == 'flat_pivot'){
			let res = await this._configService.get_flat_pivot_report(payload).toPromise();
			if(res.errCode == 0){
				this.cols = res.pivot_cols
				// this.setLevelMap()
				for (var i = res.pivot_cols.length - 1; i >= 0; i--) {
					this.pivot_cols_dict[res.pivot_cols[i].col_name] =  res.pivot_cols[i]
				}
				// this.report_data_list = res.msg
				if(res.msg.length == 0){
					this.afterService = true
				}else{
					this.report_data_list = res.msg
					// this.report_data_list = this.process_data(res.msg)
					this.color_creator('#c4c6c8',"#ffffff")
				}

			}
		}
		if(this.allow_refresh && this.pause_refresh_timer){
			this.pause_refresh_timer = false;
		}
		this.loading = false

	}
	download_btn_text = 'Download Report'

	download_report(){
		this.download_btn_text = 'Downloading'
		var payload = {
			"report_id": this.report_id,
			"filter_data": this.param_def.param_val,
			"hidden_filter_data": this.param_def.hidden_param_val
		};
		this._configService.create_report(payload).subscribe(res => {
			var payload2 = {"file_type": "report", "file_name": res.filename};
			if (((localStorage.getItem('leggero_username') != null) || (localStorage.getItem('leggero_username') != undefined))) {
				const is_admin=JSON.parse(localStorage.getItem('lg_admin'))
				payload2["logdata"] = {
					"logtype": "report_export",
					"logdata": {
						"user_name": localStorage.getItem('leggero_username'),
						"main_itemname": res.filename,
						"main_itemid": this.report_id,
						"dashboard_name": localStorage.getItem('lg_dashboard_name'),
						"system_access_id": localStorage.getItem('system_access_id'),
						"is_admin_panel": is_admin == undefined? 0: is_admin,
					}
				};

			}
			this._configService.download_report(payload2).subscribe(res2 => {
				this.download_btn_text = 'Download Report'
				window.open(res2.download_link, "_blank");
			});

		},error => {
    		this.download_btn_text = "Download Report"
		});
	}
	config_event(event){
		switch(event.case){
			case "apply_filter":
				this.refresh_report_data();
				break;
			case "close_filter_modal":
				if(this.allow_refresh){
					this.pause_refresh_timer = false;
				}
				break;
			case "open_filter_modal":
				this.pause_refresh_timer = true;
				break;
		}
	}

	showLevel(parent_class,current_level,collapse_index){
		current_level = (current_level.replace(/\s/g, '').replace(/[\'/"&\\]/g, '_').split('').reverse().join(""));
		if(this.report_data_list[collapse_index].collapse){
			$('[class^="'+this.report_id+parent_class+"_"+current_level+'"]').show()
			// $('.'+parent_class+"_"+current_level+'').show()
		}else{
			$('[class^="'+this.report_id+parent_class+"_"+current_level+'"]').hide()
		}
		this.report_data_list[collapse_index].collapse = !(this.report_data_list[collapse_index].collapse)
		this.toggleSelected(this.report_data_list[collapse_index].collapse,parent_class+"_"+current_level)
	}
	toggleSelected(flag,parent){
		for (var i = this.report_data_list.length - 1; i >= 0; i--) {
			if(this.report_data_list[i].parent.indexOf(parent) != -1){
				this.report_data_list[i]['collapse'] = flag
			}
		}

	}
	collapseAll(flag){
		this.show_collapse_all = flag
		for (var i = this.report_data_list.length - 1; i >= 0; i--) {
			if(flag){
				this.report_data_list[i]['collapse'] = false
				if(this.report_data_list[i].parent != ""){
					$('[class^="'+ this.report_id + this.report_data_list[i].parent+'"]').show()
				}
			}else{
				this.report_data_list[i]['collapse'] = true
				if(this.report_data_list[i].parent != ""){
					$('[class^="'+ this.report_id + this.report_data_list[i].parent+'"]').hide()
				}
			}
		}
		// $('tr').show();
	}
	color_ = []
	color_creator(color1,color2){
		const colorBetween = require('color-between');
		if(this.level_list.length != 1){
			this.color_.push(color1)
			for (var i = 1; i <= this.level_list.length-2; i++) {
				let grad = +((i/ (this.level_list.length-1)).toFixed(1))
				let tcolor = colorBetween(color1, color2, grad, 'hex')
				this.color_.push(tcolor)
			}
		}
		this.color_.push(color2)
	}
	open_modal(row){
		if(row.lg_data_recs){
			this.row_detail = row.lg_data_recs
			if(this.col_detail.length == 0){
				this.col_detail = Object.keys(row.lg_data_recs[0])
			}
			$('#report_data_'+this.report_id).modal('show');
			if(this.allow_refresh){
				this.pause_refresh_timer = true;
			}
		}
	}
	getStyleForValue(value,col,level){
		let _css = {}
		let _custom_value = {}
		let _show_cell_value= false
		let _rule_status = false

		if(this.report_style_config && ((level != this.max_level && this.report_style_config.show_aggregate_values) || level == this.max_level)){
			for (var i = 0;i <this.report_style_config.report_rules.length ; i++) {
				let style = this.process_rule(value,col,this.report_style_config.report_rules[i].rule)
				if(style){
					_custom_value = this.report_style_config.report_rules[i].cell_value
					_show_cell_value = this.report_style_config.report_rules[i].show_cell_value
					_rule_status = style
					_css = this.report_style_config.report_rules[i].cell_style
					break;
				}
			}
		}
		return {'_css':_css,'_custom_value':_custom_value,'_show_cell_value':_show_cell_value,'_rule_status':_rule_status}
	}

	lambda = {
		'equal': function(op1,op2){ return op1 == op2} ,
		'not_equal': function(op1,op2){ return op1 != op2} ,
		'less': function(op1,op2){ return op1 < op2} ,
		'less_or_equal': function(op1,op2){ return op1 <= op2} ,
		'greater': function(op1,op2){ return op1 > op2} ,
		'greater_or_equal': function(op1,op2){ return op1 >= op2} ,
		'in': function(op1,op2){ return $.inArray(op2,op1) !== -1} ,
		'not_in': function(op1,op2){ return $.inArray(op2,op1) === -1} ,
		'is_not_null': function(op1,op2){ return (op1 != undefined && op1 != null)} ,
		'is_null': function(op1,op2){ return (op1 == undefined || op1 == null)} ,
	}

	process_rule(value,col,decision_data){
		let ret_val = true;
		let temp_list = [];
		let condition = decision_data['condition'];
		let rules = decision_data['rules'];

		for(let i=0; i<rules.length; i++){
			if(rules[i]['condition'] != undefined){
				temp_list.push(this.process_rule(value,col,rules[i]));
			} else {
				if(this.checkColType(rules[i]) == 'pivot'){
					let temp_col = this.pivot_cols_dict[col].disp_name

					if(rules[i]['type'] == 'date'){
						rules[i]['value'] = new Date(new Date(rules[i]['value']).setHours(0,0,0,0))
						temp_col = new Date(new Date(temp_col).setHours(0,0,0,0))
					}
					temp_list.push(this.lambda[rules[i]['operator']](temp_col,rules[i]['value']));
				}else{
					temp_list.push(this.lambda[rules[i]['operator']](value,rules[i]['value']));
				}
			}
		}
		for (let index = 0; index < temp_list.length; index++) {
			if(condition == "AND"){
				if(!temp_list[index]){
					ret_val =  false
					break;
				}else{
					ret_val =  true
				}
			}
			if(condition == "OR"){
				if(temp_list[index]){
					ret_val =  true
					break;
				}else{
					ret_val =  false
				}
			}				
		}
		return ret_val;
	}

	checkColType(col){
		for (var i = this.cols.length - 1; i >= 0; i--) {
			if(this.cols[i].disp_name == col.value || (col.type == 'date' && (moment(col.value).format('YYYY-MM-DD') == this.cols[i].disp_name))){
				return this.cols[i].columntype
			}
		}
		return null
	}

	getColValue(col){
		for (var i = this.cols.length - 1; i >= 0; i--) {
			if(this.cols[i].col_name == col){
				return this.cols[i].disp_name
			}
		}
		return this.cols[i].col_name
	}	

	getRowStyle(color,row){
		if(row['background'] == undefined){
			row['background'] = color
		}
		return row
	}

	ext_btn_event_handler(event){
		// console.log(event)
		this.ext_btn_event.emit(event);
	}

	setHeaderStyle(col) {
		return {
		  'background-color': col.bgcolor != undefined ? col.bgcolor: '' ,
		  'font-weight': col.bold != undefined && col.bold ? 'bold' : 'normal',
		  'font-style': col.italic != undefined && col.italic ? 'italic' : 'normal',
		  'text-decoration': col.strikethrough != undefined && col.strikethrough ? 'line-through' : 'none',
		  'color': col.textcolor != undefined ? col.textcolor : ''
		};
	}

	modal_event(event){
		switch (event.case) {
			case "close_modal":
				if(this.allow_refresh){
					this.pause_refresh_timer = false;
				}
				break;
			default:
				// code...
				break;
		}
	}

}	

