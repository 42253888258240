import { Component, EventEmitter, Input, OnInit, Output,ElementRef,Renderer2, ViewChild, AfterViewInit } from '@angular/core';

@Component({
  selector: '[toggler]',
  templateUrl: './toggler.component.html',
  styleUrls: ['./toggler.component.css']
})

export class TogglerComponent implements OnInit,AfterViewInit {

  @Input() left_title?: string = 'Toggle';
  @Input() right_title?: string = 'Toggle';

  @Output() states: EventEmitter<any> = new EventEmitter<any>(); 

  left_toggle_classes = '';
  right_toggle_classes = '';
  left_toggler:any;
  right_toggler:any;

  show_table = true;
  show_details = true;

  tranisition_class = 'transition-03';
  display_none_class = 'd-none';
  full_screen_class = 'col-12';

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) {}

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.left_toggler = this.el.nativeElement.children[2];
    this.right_toggler = this.el.nativeElement.children[3];

    this.renderer.addClass(this.left_toggler, this.tranisition_class);
    this.renderer.addClass(this.right_toggler, this.tranisition_class);
    this.left_toggle_classes = this.left_toggler.className;
    this.right_toggle_classes = this.right_toggler.className;
    // this.toggle_content();
  }

  toggle(dir) {
    this.remove_classes(this.left_toggler, this.left_toggle_classes);
    this.remove_classes(this.right_toggler, this.right_toggle_classes);
    switch(dir) {
      case 'left':
        this.show_table = !this.show_table;
        this.toggle_content()
      break;
      case 'right':
        this.show_details = !this.show_details;
        this.toggle_content()
      break;
    }
    this.states.emit({left: this.show_table, right: this.show_details})
  }

  toggle_content() {
    if(this.show_table && this.show_details)  {
      this.add_classes(this.left_toggler, this.left_toggle_classes);
      this.add_classes(this.right_toggler, this.right_toggle_classes);
    } else if(!this.show_table && this.show_details)  {
      this.renderer.addClass(this.left_toggler, this.display_none_class);
      this.renderer.addClass(this.right_toggler, this.full_screen_class);
    } else if(this.show_table && !this.show_details)  {
      this.renderer.addClass(this.left_toggler, this.full_screen_class);
      this.renderer.addClass(this.right_toggler, this.display_none_class);
    }
  }

  remove_classes(identifier, className) {
    const classes= className.split(' ');
    for(let i=classes.length-1;i>=0;i--) {
      if(classes[i] != this.tranisition_class) {
        const splitted_class_name_list = classes[i].split('-');
        if(splitted_class_name_list[0] == 'col') {
          this.renderer.removeClass(identifier, classes[i]);
        }
      }
    }
    this.renderer.removeClass(identifier, this.display_none_class);
    this.renderer.removeClass(identifier, this.full_screen_class);
  }

  add_classes(identifier, className) {
    const classes= className.split(' ');
    for(let i=0;i<classes.length;i++) {
      if(classes[i] != this.tranisition_class) {
        const splitted_class_name_list = classes[i].split('-');
        if(splitted_class_name_list[0] == 'col') {
          this.renderer.addClass(identifier, classes[i]);
        } 
      }
    }
  }
}