import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { EnvService } from './env.service';
import { Observable } from 'rxjs';
import {SnotifyService} from 'ng-snotify';
import { UtilityService } from '../modules/config-builder/service/utility.service';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { isArray } from 'util';
declare var navigator:any;

@Injectable({
	providedIn: 'root'
})
export class CommonService {

	constructor(
		private http: HttpClient,
		private env: EnvService,
		private snotifyService: SnotifyService,
		private utilityService: UtilityService,) { }


	//	RESTLESS

	private connection_type_url = `${this.env.db_service_url}/connections/get_all`;
	connection_type = (payload) => {
		return this.http.post<any>(this.connection_type_url, payload);

	}

	private lg_query_url = `${this.env.db_service_url}/api/lg_query`;
	get_lg_query(filter) {
		return this.http.get<any>(this.lg_query_url + filter);
	}

	post_lg_query(payload) {
		return this.http.post<any>(this.lg_query_url, payload);
	}

	patch_lg_query(id, payload) {
		return this.http.patch<any>(this.lg_query_url + "/" + id, payload);
	}


	private lg_vinsights_url = `${this.env.db_service_url}/api/lg_vinsights`;
	get_lg_vinsights(filter) {
		return this.http.get<any>(this.lg_vinsights_url + filter);
	}

	post_lg_vinsights(payload) {
		return this.http.post<any>(this.lg_vinsights_url, payload);
	}

	patch_lg_vinsights(id, payload) {
		return this.http.patch<any>(this.lg_vinsights_url + "/" + id, payload);
	}


	private lg_dashboards_url = `${this.env.db_service_url}/api/lg_dashboards`;
	get_lg_dashboards(filter) {
		return this.http.get<any>(this.lg_dashboards_url + filter);
	}

	post_lg_dashboards(payload) {
		return this.http.post<any>(this.lg_dashboards_url, payload);
	}

	patch_lg_dashboards(id, payload) {
		return this.http.patch<any>(this.lg_dashboards_url + "/" + id, payload);
	}

	//update_lg_dashboards
	private update_lg_dashboard_url = `${this.env.db_service_url}/lg_dashboards/update_dashboard`;
	update_lg_dashboard(payload) {
		return this.http.post<any>(this.update_lg_dashboard_url, payload);
	}

	private lg_dshb_group_url = `${this.env.db_service_url}/api/lg_dshb_group`;
	get_lg_dshb_group(filter) {
		return this.http.get<any>(this.lg_dshb_group_url + filter);
	}

	post_lg_dshb_group(payload) {
		return this.http.post<any>(this.lg_dshb_group_url, payload);
	}

	patch_lg_dshb_group(id, payload) {
		return this.http.patch<any>(this.lg_dshb_group_url + "/" + id, payload);
	}

	private lg_user_url = `${this.env.db_service_url}/api/lg_user`;
	get_lg_user(filter) {
		return this.http.get<any>(this.lg_user_url + filter);
	}
	private add_user_url = `${this.env.db_service_url}/add_user`;
	post_lg_user(payload) {
		return this.http.post<any>(this.add_user_url, payload);
	}

	patch_lg_user(id, payload) {
		return this.http.patch<any>(this.lg_user_url + "/" + id, payload);
	}

	private lg_dshbgroup_dashboard_update_url = `${this.env.db_service_url}/lg_dashgroups/lg_dashboards/bulk_update`
	post_new_lg_dshbgroup_dashboard(payload){
		return this.http.post<any>(this.lg_dshbgroup_dashboard_update_url, payload);
	}

	private lg_dshbgroup_dashboard_url = `${this.env.db_service_url}/api/lg_dshbgroup_dashboard`;
	get_lg_dshbgroup_dashboard(filter) {
		return this.http.get<any>(this.lg_dshbgroup_dashboard_url + filter);
	}

	post_lg_dshbgroup_dashboard(payload) {
		return this.http.post<any>(this.lg_dshbgroup_dashboard_url, payload);
	}

	patch_lg_dshbgroup_dashboard(id, payload) {
		return this.http.patch<any>(this.lg_dshbgroup_dashboard_url + "/" + id, payload);
	}


	private lg_show_user_home_dashboard_url = `${this.env.db_service_url}/api/lg_show_user_home_dashboard`;
	get_lg_show_user_home_dashboard(filter) {
		return this.http.get<any>(this.lg_show_user_home_dashboard_url + filter);
	}

	private lg_user_home_dashboard_url = `${this.env.db_service_url}/api/lg_user_home_dashboard`;
	get_lg_user_home_dashboard(filter) {
		return this.http.get<any>(this.lg_user_home_dashboard_url + filter);
	}

	post_lg_user_home_dashboard(payload) {
		return this.http.post<any>(this.lg_user_home_dashboard_url, payload);
	}

	patch_lg_user_home_dashboard(id, payload) {
		return this.http.patch<any>(this.lg_user_home_dashboard_url + "/" + id, payload);
	}


	private lg_dshb_group_user_url = `${this.env.db_service_url}/api/lg_dshb_group_user`;
	get_lg_dshb_group_user(filter) {
		return this.http.get<any>(this.lg_dshb_group_user_url + filter);
	}

	post_lg_dshb_group_user(payload) {
		return this.http.post<any>(this.lg_dshb_group_user_url, payload);
	}

	patch_lg_dshb_group_user(id, payload) {
		return this.http.patch<any>(this.lg_dshb_group_user_url + "/" + id, payload);
	}


	private get_all_user_dshb_group_url = `${this.env.db_service_url}/lg_dshb/get_all_user_dshb_group`;
	get_all_user_dshb_grp = (payload) => {
		return this.http.post<any>(this.get_all_user_dshb_group_url, payload)
	}

	private save_user_dshb_group_url = `${this.env.db_service_url}/lg_dshb/save_user_dshb_group`;
	save_user_dshb_grp = (payload) => {
		return this.http.post<any>(this.save_user_dshb_group_url, payload)
	}

	private lg_show_dash_group_url = `${this.env.db_service_url}/api/lg_show_dash_group`;
	get_lg_show_dash_group(filter) {
		return this.http.get<any>(this.lg_show_dash_group_url + filter);
	}

	// post_lg_show_dash_group(payload) {
	//   return this.http.post<any>( this.lg_show_dash_group_url, payload);
	// }

	// patch_lg_show_dash_group(id, payload) {
	//   return this.http.patch<any>( this.lg_show_dash_group_url + "/" + id, payload);
	// }


	private lg_show_dashboard_url = `${this.env.db_service_url}/api/lg_show_dashboard`;
	get_lg_show_dashboard(filter) {
		return this.http.get<any>(this.lg_show_dashboard_url + filter);
	}

	// post_lg_show_dashboard(payload) {
	//   return this.http.post<any>( this.lg_show_dashboard_url, payload);
	// }

	// patch_lg_show_dashboard(id, payload) {
	//   return this.http.patch<any>( this.lg_show_dashboard_url + "/" + id, payload);
	// }


	private _mail_templates_url = `${this.env.db_service_url}/api/Communication_Templates`;
	get_mail_templates(filter) {
		return this.http.get<any>(this._mail_templates_url + filter);
	}
	post_mail_template(payload) {
		return this.http.post<any>(this._mail_templates_url, payload);
	}
	patch_mail_template(id, payload) {
		return this.http.patch<any>(this._mail_templates_url + '/' + id, payload);
	}

	private lg_reports_url = `${this.env.db_service_url}/api/lg_reports`;
	get_lg_reports(filter) {
		return this.http.get<any>(this.lg_reports_url + filter);
	}

	post_lg_reports(payload) {
		return this.http.post<any>(this.lg_reports_url, payload);
	}

	patch_lg_reports(id, payload) {
		return this.http.patch<any>(this.lg_reports_url + "/" + id, payload);
	}


	private lg_rep_dashboard_group_to_user_url = `${this.env.db_service_url}/api/lg_rep_dashboard_group_to_user`;
	get_lg_rep_dashboard_group_to_user(filter) {
		return this.http.get<any>(this.lg_rep_dashboard_group_to_user_url + filter);
	}

	post_lg_rep_dashboard_group_to_user(payload) {
		return this.http.post<any>(this.lg_rep_dashboard_group_to_user_url, payload);
	}

	patch_lg_rep_dashboard_group_to_user(id, payload) {
		return this.http.patch<any>(this.lg_rep_dashboard_group_to_user_url + "/" + id, payload);
	}

	private lg_report_dashboard_group_url = `${this.env.db_service_url}/api/lg_report_dashboard_group`;
	get_lg_report_dashboard_group(filter) {
		return this.http.get<any>(this.lg_report_dashboard_group_url + filter);
	}

	post_lg_report_dashboard_group(payload) {
		return this.http.post<any>(this.lg_report_dashboard_group_url, payload);
	}

	patch_lg_report_dashboard_group(id, payload) {
		return this.http.patch<any>(this.lg_report_dashboard_group_url + "/" + id, payload);
	}

	private lg_report_dashboard_url = `${this.env.db_service_url}/api/lg_report_dashboard`;
	get_lg_report_dashboard(filter) {
		return this.http.get<any>(this.lg_report_dashboard_url + filter);
	}
	post_lg_report_dashboard(payload) {
		return this.http.post<any>(this.lg_report_dashboard_url, payload);
	}
	patch_lg_report_dashboard(id, payload) {
		return this.http.patch<any>(this.lg_report_dashboard_url + "/" + id, payload);
	}

	private lg_rep_dashboard_to_dashgroup_url = `${this.env.db_service_url}/api/lg_rep_dashboard_to_dashgroup`;
	get_lg_rep_dashboard_to_dashgroup(filter) {
		return this.http.get<any>(this.lg_rep_dashboard_to_dashgroup_url + filter);
	}

	post_lg_rep_dashboard_to_dashgroup(payload) {
		return this.http.post<any>(this.lg_rep_dashboard_to_dashgroup_url, payload);
	}

	patch_lg_rep_dashboard_to_dashgroup(id, payload) {
		return this.http.patch<any>(this.lg_rep_dashboard_to_dashgroup_url + "/" + id, payload);
	}

	private lg_composite_widgets_url = `${this.env.db_service_url}/api/lg_composite_widgets`;
	get_lg_composite_widgets(filter) {
		return this.http.get<any>(this.lg_composite_widgets_url + filter);
	}

	post_lg_composite_widgets(payload) {
		return this.http.post<any>(this.lg_composite_widgets_url, payload);
	}

	patch_lg_composite_widgets(id, payload) {
		return this.http.patch<any>(this.lg_composite_widgets_url + "/" + id, payload);
	}

	private get_home_dashboard_for_user_and_role_url = `${this.env.db_service_url}/lg_dshb/get_all_home_dshb`;
	get_home_dashboard_for_user_and_role(payload) {
		return this.http.post<any>(this.get_home_dashboard_for_user_and_role_url, payload);
	}

	// RESTFUL

	private login_url = `${this.env.db_service_url}/auth`;
	login(payload) {
		return this.http.post<any>(this.login_url, payload);
	}

	private logout_url = `${this.env.db_service_url}/logout`;
	logout(payload) {
		return this.http.post<any>(this.logout_url, payload);
	}

	// Roles Services

	private get_roles_list_url = `${this.env.db_service_url}/api/get_roles_list`;
	get_roles_list(payload) {
		return this.http.post<any>(this.get_roles_list_url, payload);
	}

	private create_new_role_url = `${this.env.db_service_url}/api/create_new_role`;
	create_new_role(payload) {
		return this.http.post<any>(this.create_new_role_url, payload);
	}

	private _get_sidebar_configuration_url = `${this.env.db_service_url}/api/get_sidebar_configuration`;
	get_sidebar_configuration(payload) {
		return this.http.post<any>(this._get_sidebar_configuration_url, payload);
	}

	private update_sidebar_configuration_url = `${this.env.db_service_url}/api/update_sidebar_configuration`;
	update_sidebar_configuration(payload) {
		return this.http.post<any>(this.update_sidebar_configuration_url, payload);
	}

	private get_component_configuration_url = `${this.env.db_service_url}/api/get_component_configuration`;
	get_component_configuration(payload) {
		return this.http.post<any>(this.get_component_configuration_url, payload);
	}

	private update_component_configuration_url = `${this.env.db_service_url}/api/update_component_configuration`;
	update_component_configuration(payload) {
		return this.http.post<any>(this.update_component_configuration_url, payload);
	}

	private get_user_role_map_url = `${this.env.db_service_url}/api/get_user_role_map`;
	get_user_role_map(payload) {
		return this.http.post<any>(this.get_user_role_map_url, payload);
	}

	private get_sidebar_tree_url = `${this.env.db_service_url}/api/get_sidebar_tree`;
	get_sidebar_tree(payload) {
		return this.http.post<any>(this.get_sidebar_tree_url, payload);
	}

	private update_user_roles_url = `${this.env.db_service_url}/api/update_user_roles`;
	update_user_roles(payload) {
		return this.http.post<any>(this.update_user_roles_url, payload);
	}

	private get_all_user_roles_url = `${this.env.db_service_url}/api/get_all_user_roles`;
	get_all_user_roles(payload) {
		return this.http.post<any>(this.get_all_user_roles_url, payload);
	}

	private get_roles_by_user_id_url = `${this.env.db_service_url}/lg_dshb/get_role_by_user_id`;
	get_roles_by_user_id(payload) {
		return this.http.post<any>(this.get_roles_by_user_id_url, payload);
	}

	//Role Dashboard Services
	private get_dashboard_group_url = `${this.env.db_service_url}/lg_dshb/get_all_role_dshb_group`;
	get_dashboard_group = (payload) => {
		return this.http.post<any>(this.get_dashboard_group_url, payload)
	}

	private link_dashboard_groups_to_role_url = `${this.env.db_service_url}/lg_dshb/save_role_dshb_group`;
	link_dashboard_groups_to_role = (payload) => {
		return this.http.post<any>(this.link_dashboard_groups_to_role_url, payload)
	}

	private get_home_dashboard_url = `${this.env.db_service_url}/api/user_profile_home_dashboard`;
	get_home_dashboard(filter){
		return this.http.get<any>(this.get_home_dashboard_url + filter);
	}

	private patch_home_dashboard_url = `${this.env.db_service_url}/api/user_profile_home_dashboard`;
	patch_home_dashboard(id, payload) {
		return this.http.patch<any>(this.patch_home_dashboard_url + "/" + id, payload);
	}

	private link_home_dashboard_to_role_url = `${this.env.db_service_url}/api/user_profile_home_dashboard`;
	link_home_dashboard_to_role = (payload) => {
		return this.http.post<any>(this.link_home_dashboard_to_role_url, payload)
	}

	// HOLIDAY CALENDER SERVICES
	private get_all_holidays_url = `${this.env.db_service_url}/holiday/get_all_holidays`;
	get_all_holidays(payload) {
		return this.http.post<any>(this.get_all_holidays_url, payload);
	}

	private create_holidays_in_date_range_url = `${this.env.db_service_url}/holiday/create_holidays_in_date_range`;
	create_holidays_in_date_range(payload) {
		return this.http.post<any>(this.create_holidays_in_date_range_url, payload);
	}

	private update_holiday_url = `${this.env.db_service_url}/holiday/update_holiday`;
	update_holiday(payload) {
		return this.http.post<any>(this.update_holiday_url, payload);
	}

	private create_single_holiday_url = `${this.env.db_service_url}/holiday/create_single_holiday`;
	create_single_holiday(payload) {
		return this.http.post<any>(this.create_single_holiday_url, payload);
	}

	//	WAREHOUSE SERVICES
	private export_query_data_url = `${this.env.wh_service_url}/whapi/ExportQuery`
	export_query_data = (payload) => {
		return this.http.post<any>(this.export_query_data_url, payload);
	}

	private all_table_cols_url = `${this.env.wh_service_url}/whapi/getTableColumns`;
	get_all_table_cols(filter) {
		return this.http.get<any>(this.all_table_cols_url+filter);
	}

	private test_query_url = `${this.env.wh_service_url}/whapi/queryTest`;
	test_query(payload) {
		return this.http.post<any>(this.test_query_url, payload).catch(this.errorHandler);
	}

	private process_query_nd_run_url = `${this.env.wh_service_url}/whapi/process_query_filter_and_run`;
	process_query_and_run(payload) {
		return this.http.post<any>(this.process_query_nd_run_url, payload).catch(this.errorHandler);
	}

	private create_report_url = `${this.env.wh_service_url}/whapi/create_report`;
	create_report(payload) {
		return this.http.post<any>(this.create_report_url, payload).catch(this.errorHandler);
	}

	private download_report_url = `${this.env.wh_service_url}/whapi/download_file`;
	download_report(payload) {
		return this.http.post<any>(this.download_report_url, payload).catch(this.errorHandler);
	}

	private download_chart_report_url = `${this.env.wh_service_url}/whapi/create_chart_report`;
	download_chart_report(payload) {
		return this.http.post<any>(this.download_chart_report_url, payload).catch(this.errorHandler);
	}

	errorHandler(error: HttpErrorResponse) {
		console.log(error);
		return Observable.throw(error.message || 'Server Error');
	}

	private filter_from_query_url = `${this.env.wh_service_url}/whapi/filterFromQuery`;
	filter_from_query(payload) {
		return this.http.post<any>(this.filter_from_query_url, payload);
	}

	private get_vi_param_url = `${this.env.wh_service_url}/whapi/getVIParam`;
	get_vi_param(payload) {
		return this.http.post<any>(this.get_vi_param_url, payload);
	}

	private get_comp_widget_url = `${this.env.wh_service_url}/whapi/getCompWidget`;
	get_comp_widget(payload) {
		return this.http.post<any>(this.get_comp_widget_url, payload);
	}

	private send_mail_from_vi_url = `${this.env.wh_service_url}/whapi/send_mail_from_vi`;
	send_mail_from_vi(payload) {
		return this.http.post<any>(this.send_mail_from_vi_url, payload);
	}

	//connection services
	private validate_connection_url = `${this.env.wh_service_url}/whapi/test_connection`;
	validate_connection = (payload) => {
		return this.http.post<any>(this.validate_connection_url, payload)
	}

	private create_connection_url = `${this.env.db_service_url}/connections/create`;
	create_connection = (payload) => {
		return this.http.post<any>(this.create_connection_url, payload)
	}

	private update_connection_url = `${this.env.db_service_url}/connections/update`;
	update_connection = (payload) => {
		return this.http.post<any>(this.update_connection_url, payload)
	}

	private get_all_connection_url = `${this.env.db_service_url}/connections/get_all`;
	get_all_connection = (payload) => {
		return this.http.post<any>(this.get_all_connection_url, payload)
	}

	private get_connection_url = `${this.env.db_service_url}/connections/get`;
	get_connection = (payload) => {
		return this.http.post<any>(this.get_connection_url, payload)
	}

	private remove_connection_url = `${this.env.db_service_url}/connections/remove`;
	remove_connection = (payload) => {
		return this.http.post<any>(this.remove_connection_url, payload)
	}

	private update_password_connection_url = `${this.env.db_service_url}/connections/update_password`;
	update_password_connection = (payload) => {
		return this.http.post<any>(this.update_password_connection_url, payload)
	}


	//query services

	private get_all_query_data_url = `${this.env.db_service_url}/lg_query/get_all_query`;
	get_all_query_data = (payload) => {
		return this.http.post<any>(this.get_all_query_data_url, payload)
	}

	private get_query_by_id_url = `${this.env.db_service_url}/lg_query/get_query_by_query_id`;
	get_query_by_id = (payload) => {
		return this.http.post<any>(this.get_query_by_id_url, payload)
	}
	
	private create_query_url = `${this.env.db_service_url}/lg_query/create`;
	create_query = (payload) => {
		return this.http.post<any>(this.create_query_url, payload)
	}

	private update_query_url = `${this.env.db_service_url}/lg_query/update`;
	update_query = (payload) => {
		return this.http.post<any>(this.update_query_url, payload)
	}

	private get_all_query_by_con_id_url = `${this.env.db_service_url}/lg_query/get_query_by_con_id`;
	get_all_query_by_con_id = (payload) => {
		return this.http.post<any>(this.get_all_query_by_con_id_url, payload)
	}
	private get_all_tags_url = `${this.env.db_service_url}/lg_query/get_all_tags`;
	get_all_tags = (payload) => {
		return this.http.post<any>(this.get_all_tags_url, payload)
	}
	private get_all_filtered_query_url = `${this.env.db_service_url}/lg_query/get_all_filtered_query`;
	get_all_filtered_query = (payload) => {
		return this.http.post<any>(this.get_all_filtered_query_url, payload)
	}
    
	//db stats
	private get_stats_postgres_basic_url = `${this.env.wh_service_url}/stats/postgres/basic`;
	get_stats_postgres_basic(payload){
		return this.http.post<any>(this.get_stats_postgres_basic_url, payload);
	}

	private get_stats_postgres_pg_stat_activity_url = `${this.env.wh_service_url}/stats/postgres/pg_stat_activity`;
	get_stats_postgres_pg_stat_activity(payload){
		return this.http.post<any>(this.get_stats_postgres_pg_stat_activity_url, payload)
	}

	private get_stats_postgres_pg_stat_all_tables_url = `${this.env.wh_service_url}/stats/postgres/pg_stat_all_tables`;
	get_stats_postgres_pg_stat_all_tables(payload){
		return this.http.post<any>(this.get_stats_postgres_pg_stat_all_tables_url, payload)
	}

	private get_stats_postgres_pg_stat_all_indexes_url = `${this.env.wh_service_url}/stats/postgres/pg_stat_all_indexes`;
	get_stats_postgres_pg_stat_all_indexes(payload){
		return this.http.post<any>(this.get_stats_postgres_pg_stat_all_indexes_url, payload)
	}

	private get_stats_postgres_pg_statio_all_tables_url = `${this.env.wh_service_url}/stats/postgres/pg_statio_all_tables`;
	get_stats_postgres_pg_statio_all_tables(payload){
		return this.http.post<any>(this.get_stats_postgres_pg_statio_all_tables_url, payload)
	}

	private get_stats_postgres_pg_statio_all_indexes_url = `${this.env.wh_service_url}/stats/postgres/pg_statio_all_indexes`;
	get_stats_postgres_pg_statio_all_indexes(payload){
		return this.http.post<any>(this.get_stats_postgres_pg_statio_all_indexes_url, payload)
	}

	//widget services

	public get_all_widgets_url = `${this.env.db_service_url}/lg_composite_widget/get_all_widgets`;
	get_all_widgets = (payload) => {
		return this.http.post<any>(this.get_all_widgets_url, payload)
	}

	private get_widget_by_id_url = `${this.env.db_service_url}/lg_composite_widget/get_widget_by_id`;
	get_widget_by_id = (payload) => {
		return this.http.post<any>(this.get_widget_by_id_url, payload)
	}
	
	private create_widget_url = `${this.env.db_service_url}/lg_composite_widget/create`;
	create_widget = (payload) => {
		return this.http.post<any>(this.create_widget_url, payload)
	}

	private update_widget_url = `${this.env.db_service_url}/lg_composite_widget/update`;
	update_widget = (payload) => {
		return this.http.post<any>(this.update_widget_url, payload)
	}

	//report services

	public get_all_reports_url = `${this.env.db_service_url}/lg_report/get_all_reports`;
	get_all_reports = (payload) => {
		return this.http.post<any>(this.get_all_reports_url, payload)
	}

	private get_report_by_id_url = `${this.env.db_service_url}/lg_report/get_report_by_id`;
	get_report_by_id = (payload) => {
		return this.http.post<any>(this.get_report_by_id_url, payload)
	}
	
	private save_report_url = `${this.env.db_service_url}/lg_report/create`;
	save_report = (payload) => {
		return this.http.post<any>(this.save_report_url, payload)
	}

	private update_report_url = `${this.env.db_service_url}/lg_report/update`;
	update_report = (payload) => {
		return this.http.post<any>(this.update_report_url, payload)
	}
    


	//vinsights services

	public get_all_vinsights_url = `${this.env.db_service_url}/lg_vinsights/get_all_vinsights`;
	get_all_vinsights = (payload) => {
		return this.http.post<any>(this.get_all_vinsights_url, payload)
	}

	private get_vinsights_by_id_url = `${this.env.db_service_url}/lg_vinsights/get_vinsights_by_id`;
	get_vinsights_by_id = (payload) => {
		return this.http.post<any>(this.get_vinsights_by_id_url, payload)
	}
	
	private create_vinsights_url = `${this.env.db_service_url}/lg_vinsights/create`;
	create_vinsights = (payload) => {
		return this.http.post<any>(this.create_vinsights_url, payload)
	}

	private update_vinsights_url = `${this.env.db_service_url}/lg_vinsights/update`;
	update_vinsights = (payload) => {
		return this.http.post<any>(this.update_vinsights_url, payload)
	}


	//batch noti services
	private create_notification_url = `${this.env.scheduler_service_url}/batch_notification/create`;
	create_notification = (payload) => {
		return this.http.post<any>(this.create_notification_url, payload)
	}

	private update_notification_url = `${this.env.scheduler_service_url}/batch_notification/update`;
	update_notification = (payload) => {
		return this.http.post<any>(this.update_notification_url, payload)
	}

	private get_all_notification_url = `${this.env.scheduler_service_url}/batch_notification/get_all`;
	get_all_notification = (payload) => {
		return this.http.post<any>(this.get_all_notification_url, payload)
	}

	private get_batch_noti_audit_log_url = `${this.env.scheduler_service_url}/batch_notification/audit/get_all`;
	get_batch_noti_audit_log = (payload) => {
		return this.http.post<any>(this.get_batch_noti_audit_log_url, payload)
	}

	private get_notification_url = `${this.env.scheduler_service_url}/batch_notification/get`;
	get_notification = (payload) => {
		return this.http.post<any>(this.get_notification_url, payload)
	}

	private remove_notification_url = `${this.env.scheduler_service_url}/batch_notification/remove`;
	remove_notification = (payload) => {
		return this.http.post<any>(this.remove_notification_url, payload)
	}

	private activate_notification_url = `${this.env.scheduler_service_url}/batch_notification/activate`;
	activate_notification = (payload) => {
		return this.http.post<any>(this.activate_notification_url, payload)
	}

	private update_noti_attachment_url = `${this.env.scheduler_service_url}/batch_notification/attachment/update`;
	update_noti_attachment = (payload) => {
		return this.http.post<any>(this.update_noti_attachment_url, payload)
	}

	private get_all_noti_attachment_url = `${this.env.scheduler_service_url}/batch_notification/attachment/get_all`;
	get_all_noti_attachment = (payload) => {
		return this.http.post<any>(this.get_all_noti_attachment_url, payload)
	}

	private get_all_report_url = `${this.env.scheduler_service_url}/batch_notification/report/get_all`;
	get_all_report = (payload) => {
		return this.http.post<any>(this.get_all_report_url, payload)
	}

	private get_all_query_url = `${this.env.scheduler_service_url}/batch_notification/query/get_all`;
	get_all_query = (payload) => {
		return this.http.post<any>(this.get_all_query_url, payload)
	}

	private get_all_email_configs_url = `${this.env.db_service_url}/email_config/get_all`;
	get_all_email_configs = (payload) => {
		return this.http.post<any>(this.get_all_email_configs_url, payload);
	}

	//dds services

	private get_project_list_url = `${this.env.dds_service_url}/list_projects`;
	get_project_list = (payload) => {
		return this.http.post<any>(this.get_project_list_url, payload);
	}

	private add_project_url = `${this.env.dds_service_url}/add_project`;
	add_project = (payload) => {
		return this.http.post<any>(this.add_project_url, payload)
	}

	private update_project_url = `${this.env.dds_service_url}/update_project`;
	update_project = (payload) => {
		return this.http.post<any>(this.update_project_url, payload)
	}

	private load_project_url = `${this.env.dds_service_url}/load_project`;
	load_project = (payload) => {
		return this.http.post<any>(this.load_project_url, payload);
	}

	private get_project_detail_url = `${this.env.dds_service_url}/get_project_detail`;
	get_project_detail = (payload) => {
		return this.http.post<any>(this.get_project_detail_url, payload);
	}
	
	private create_version_url = `${this.env.dds_service_url}/create_version`;
	create_version = (payload) => {
		return this.http.post<any>(this.create_version_url, payload);
	}

	private get_version_status_url = `${this.env.dds_service_url}/version/get_version_status`;
	get_version_status = (payload) => {
		return this.http.post<any>(this.get_version_status_url, payload);
	}

	private change_version_status_url = `${this.env.dds_service_url}/version/change_version_status`;
	change_version_status = (payload) => {
		return this.http.post<any>(this.change_version_status_url, payload);
	}

	private load_file_url = `${this.env.dds_service_url}/load_file`;
	load_file = (payload) => {
		return this.http.post<any>(this.load_file_url, payload);
	}

	private version_source_tree_files_url = `${this.env.dds_service_url}/version/source_tree/files`;
	version_source_tree_files = (payload) => {
		return this.http.post<any>(this.version_source_tree_files_url, payload);
	}

	private download_version_file_url = `${this.env.dds_service_url}/version/download_version_file`;
	download_version_file = (payload) => {
		return this.http.post<any>(this.download_version_file_url, payload);
	}

	private dump_version_as_file_url = `${this.env.dds_service_url}/dump_version_as_file`;
	dump_version_as_file = (payload) => {
		return this.http.post<any>(this.dump_version_as_file_url, payload);
	}

	private get_version_info_url = `${this.env.dds_service_url}/version/get_version_info`;
	get_version_info = (payload) => {
		return this.http.post<any>(this.get_version_info_url, payload);
	}

	private get_tooltips_url = `${this.env.dds_service_url}/tooltips/get_tooltips`;
	get_tooltips = (payload) => {
		return this.http.post<any>(this.get_tooltips_url, payload);
	}

	private data_object_logs_url = `${this.env.dds_service_url}/data_object_logs`;
	data_object_logs = (payload) => {
		return this.http.post<any>(this.data_object_logs_url, payload);
	}

	private get_total_records_url = `${this.env.dds_service_url}/data_object/get_total_records`;
	get_total_records = (payload) => {
		return this.http.post<any>(this.get_total_records_url, payload);
	}

	private get_change_log_data_url = `${this.env.dds_service_url}/log/get_change_log_data`;
	get_change_log_data = (payload) => {
		return this.http.post<any>(this.get_change_log_data_url, payload);
	}

	private load_selected_columns_from_file_url = `${this.env.dds_service_url}/load_selected_columns_from_file`;
	load_selected_columns_from_file = (payload) => {
		return this.http.post<any>(this.load_selected_columns_from_file_url, payload);
	}

	private sample_excel_report_url = `${this.env.dds_service_url}/data_object/sample_excel_report`;
	sample_excel_report = (payload) => {
		return this.http.post<any>(this.sample_excel_report_url, payload);
	}

	private write_file_url = `${this.env.dds_service_url}/write_file`;
	write_file = (payload) => {
		return this.http.post<any>(this.write_file_url, payload);
	}

	private write_to_rdbms_url = `${this.env.dds_service_url}/write_to_rdbms`;
	write_to_rdbms = (payload) => {
		return this.http.post<any>(this.write_to_rdbms_url, payload);
	}

	private test_val_url = `${this.env.dds_service_url}/test`;
	test_val = () => {
		return this.http.get<any>(this.test_val_url);
	}

	private get_all_versions_url = `${this.env.dds_service_url}/get_all_versions`;
	get_all_versions = (payload) => {
		return this.http.post<any>(this.get_all_versions_url, payload);
	}

	private load_version_url = `${this.env.dds_service_url}/load_version`;
	load_version = (payload) => {
		return this.http.post<any>(this.load_version_url, payload);
	}

	private unload_version_url = `${this.env.dds_service_url}/unload_version`;
	unload_version = (payload) => {
		return this.http.post<any>(this.unload_version_url, payload);
	}

	private copy_version_url = `${this.env.dds_service_url}/copy_version`;
	copy_version = (payload) => {
		return this.http.post<any>(this.copy_version_url, payload);
	}

	private get_version_state_data_url = `${this.env.dds_service_url}/get_version_state_data`;
	get_version_state_data = (payload) => {
		return this.http.post<any>(this.get_version_state_data_url, payload);
	}

	private get_project_version_backup_files_url = `${this.env.dds_service_url}/get_project_version_backup_files`;
	get_project_version_backup_files = (payload) => {
		return this.http.post<any>(this.get_project_version_backup_files_url, payload);
	}

	private get_decision_filters_for_write_db_config_url = `${this.env.dds_service_url}/get_decision_filters_for_write_db_config`;
	get_decision_filters_for_write_db_config = (payload) => {
		return this.http.post<any>(this.get_decision_filters_for_write_db_config_url, payload);
	}

	private archive_source_file_url = `${this.env.dds_service_url}/data_object/archive_source_file`;
	archive_source_file = (payload) => {
		return this.http.post<any>(this.archive_source_file_url, payload);
	}

	private outbound_file_history_url = `${this.env.dds_service_url}/outbound_file_history`;
	outbound_file_history = (payload) => {
		return this.http.post<any>(this.outbound_file_history_url, payload);
	}

	private create_sequence_url = `${this.env.dds_service_url}/sequence/create`;
	create_sequence = (payload) => {
		return this.http.post<any>(this.create_sequence_url, payload);
	}

	private get_all_sequence_url = `${this.env.dds_service_url}/sequence/get_all`;
	get_all_sequence = (payload) => {
		return this.http.post<any>(this.get_all_sequence_url, payload);
	}

	private get_sequence_url = `${this.env.dds_service_url}/sequence/get`;
	get_sequence = (payload) => {
		return this.http.post<any>(this.get_sequence_url, payload);
	}

	private update_sequence_url = `${this.env.dds_service_url}/sequence/update`;
	update_sequence = (payload) => {
		return this.http.post<any>(this.update_sequence_url, payload);
	}

	private archive_sequence_url = `${this.env.dds_service_url}/sequence/archive`;
	archive_sequence = (payload) => {
		return this.http.post<any>(this.archive_sequence_url, payload);
	}

	private reset_sequence_url = `${this.env.dds_service_url}/sequence/reset`;
	reset_sequence = (payload) => {
		return this.http.post<any>(this.reset_sequence_url, payload);
	}

	private convert_xml_to_json_url = `${this.env.dds_service_url}/convert_xml_to_json`;
	convert_xml_to_json = (payload) => {
		return this.http.post<any>(this.convert_xml_to_json_url, payload);
	}

	private convert_json_to_xml_url = `${this.env.dds_service_url}/convert_json_to_xml`;
	convert_json_to_xml = (payload) => {
		return this.http.post<any>(this.convert_json_to_xml_url, payload);
	}

	private test_db_data_source_url = `${this.env.dds_service_url}/test_db_data_source`;
	test_db_data_source = (payload) => {
		return this.http.post<any>(this.test_db_data_source_url, payload);
	}

	private save_db_data_source_url = `${this.env.dds_service_url}/save_db_data_source`;
	save_db_data_source = (payload) => {
		return this.http.post<any>(this.save_db_data_source_url, payload);
	}

	private get_script_executors_url = `${this.env.dds_service_url}/get_script_executors`;
	get_script_executors = (payload) => {
		return this.http.post<any>(this.get_script_executors_url, payload);
	}

	private get_script_data_url = `${this.env.dds_service_url}/get_script_data`;
	get_script_data = (payload) => {
		return this.http.post<any>(this.get_script_data_url, payload);
	}

	private get_active_scripts_url = `${this.env.dds_service_url}/get_active_scripts`;
	get_active_scripts = (payload) => {
		return this.http.post<any>(this.get_active_scripts_url, payload);
	}

	private script_create_url = `${this.env.dds_service_url}/script_create`;
	script_create = (payload) => {
		return this.http.post<any>(this.script_create_url, payload);
	}

	private update_script_url = `${this.env.dds_service_url}/update_script`;
	update_script = (payload) => {
		return this.http.post<any>(this.update_script_url, payload);
	}

	private delete_script_url = `${this.env.dds_service_url}/delete_script`;
	delete_script = (payload) => {
		return this.http.post<any>(this.delete_script_url, payload);
	}

	private create_email_template_new_record_url = `${this.env.dds_service_url}/email_conf_template/create`;
	create_email_template_new_record = (payload) => {
		return this.http.post<any>(this.create_email_template_new_record_url, payload);
	}

	private get_all_email_configuration_url = `${this.env.dds_service_url}/get_all_email_configuration`;
	get_all_email_configuration = (payload) => {
		return this.http.post<any>(this.get_all_email_configuration_url, payload);
	}

	private get_email_configuration_url = `${this.env.dds_service_url}/get_email_configuration`;
	get_email_configuration = (payload) => {
		return this.http.post<any>(this.get_email_configuration_url, payload);
	}

	private get_email_configuration_template_url = `${this.env.dds_service_url}/email_conf_template/get`;
	get_email_configuration_template = (payload) => {
		return this.http.post<any>(this.get_email_configuration_template_url, payload);
	}
	private update_email_configuration_template_url = `${this.env.dds_service_url}/email_conf_template/update`;
	update_email_configuration_template = (payload) => {
		return this.http.post<any>(this.update_email_configuration_template_url, payload);
	}

	private create_email_configuration_url = `${this.env.dds_service_url}/create_email_configuration`;
	create_email_configuration = (payload) => {
		return this.http.post<any>(this.create_email_configuration_url, payload);
	}

	private update_email_configuration_url = `${this.env.dds_service_url}/update_email_configuration`;
	update_email_configuration = (payload) => {
		return this.http.post<any>(this.update_email_configuration_url, payload);
	}

	private delete_email_configuration_url = `${this.env.dds_service_url}/delete_email_configuration`;
	delete_email_configuration = (payload) => {
		return this.http.post<any>(this.delete_email_configuration_url, payload);
	}

	private change_email_configuration_password_url = `${this.env.dds_service_url}/change_email_configuration_password`;
	change_email_configuration_password = (payload) => {
		return this.http.post<any>(this.change_email_configuration_password_url, payload);
	}

	private update_ftp_password_url = `${this.env.dds_service_url}/update_ftp_password`;
	update_ftp_password = (payload) => {
		return this.http.post<any>(this.update_ftp_password_url, payload);
	}

	private get_all_ftp_config_url = `${this.env.dds_service_url}/get_all_ftp_config`;
	get_all_ftp_config = (payload) => {
		return this.http.post<any>(this.get_all_ftp_config_url, payload);
	}

	private get_ftp_config_url = `${this.env.dds_service_url}/get_ftp_config`;
	get_ftp_config = (payload) => {
		return this.http.post<any>(this.get_ftp_config_url, payload);
	}

	private create_ftp_config_url = `${this.env.dds_service_url}/create_ftp_config`;
	create_ftp_config = (payload) => {
		return this.http.post<any>(this.create_ftp_config_url, payload);
	}

	private update_ftp_config_url = `${this.env.dds_service_url}/update_ftp_config`;
	update_ftp_config = (payload) => {
		return this.http.post<any>(this.update_ftp_config_url, payload);
	}

	private get_ftp_directory_listing_url = `${this.env.dds_service_url}/get_ftp_directory_listing`;
	get_ftp_directory_listing = (payload) => {
		return this.http.post<any>(this.get_ftp_directory_listing_url, payload);
	}

	private create_ftp_directory_url = `${this.env.dds_service_url}/create_ftp_directory`;
	create_ftp_directory = (payload) => {
		return this.http.post<any>(this.create_ftp_directory_url, payload);
	}

	private delete_ftp_file_url = `${this.env.dds_service_url}/delete_ftp_file`;
	delete_ftp_file = (payload) => {
		return this.http.post<any>(this.delete_ftp_file_url, payload);
	}

	private delete_ftp_folder_url = `${this.env.dds_service_url}/delete_ftp_folder`;
	delete_ftp_folder = (payload) => {
		return this.http.post<any>(this.delete_ftp_folder_url, payload);
	}

	private ftp_move_file_url = `${this.env.dds_service_url}/ftp_move_file`;
	ftp_move_file = (payload) => {
		return this.http.post<any>(this.ftp_move_file_url, payload);
	}

	private ftp_check_path_exists_url = `${this.env.dds_service_url}/ftp_check_path_exists`;
	ftp_check_path_exists = (payload) => {
		return this.http.post<any>(this.ftp_check_path_exists_url, payload);
	}

	private ftp_get_file_url = `${this.env.dds_service_url}/ftp_get_file`;
	ftp_get_file = (payload) => {
		return this.http.post<any>(this.ftp_get_file_url, payload);
	}

	private ftp_get_file_properties_url = `${this.env.dds_service_url}/ftp_get_file_properties`;
	ftp_get_file_properties = (payload) => {
		return this.http.post<any>(this.ftp_get_file_properties_url, payload);
	}

	private ftp_put_file_url = `${this.env.dds_service_url}/ftp_put_file`;
	ftp_put_file = (payload) => {
		return this.http.post<any>(this.ftp_put_file_url, payload);
	}

	private get_dynamic_datatype_methods_url = `${this.env.dds_service_url}/get_dynamic_datatype_methods`;
	get_dynamic_datatype_methods = (payload) => {
		return this.http.post<any>(this.get_dynamic_datatype_methods_url, payload);
	}

	private test_api_url = `${this.env.dds_service_url}/test_api`;
	test_api = (payload) => {
		return this.http.post<any>(this.test_api_url, payload);
	}

	private create_api_definition_url = `${this.env.dds_service_url}/create_api_definition`;
	create_api_definition = (payload) => {
		return this.http.post<any>(this.create_api_definition_url, payload);
	}

	private update_api_definition_url = `${this.env.dds_service_url}/update_api_definition`;
	update_api_definition = (payload) => {
		return this.http.post<any>(this.update_api_definition_url, payload);
	}

	private delete_api_definition_url = `${this.env.dds_service_url}/delete_api_definition`;
	delete_api_definition = (payload) => {
		return this.http.post<any>(this.delete_api_definition_url, payload);
	}

	private get_api_definition_url = `${this.env.dds_service_url}/get_api_definition`;
	get_api_definition = (payload) => {
		return this.http.post<any>(this.get_api_definition_url, payload);
	}

	private get_all_api_definitions_url = `${this.env.dds_service_url}/get_all_api_definitions`;
	get_all_api_definitions = (payload) => {
		return this.http.post<any>(this.get_all_api_definitions_url, payload);
	}

	private fire_api_url = `${this.env.dds_service_url}/fire_api`;
	fire_api = (payload) => {
		return this.http.post<any>(this.fire_api_url, payload);
	}

	private create_api_writer_url = `${this.env.dds_service_url}/create_api_writer`;
	create_api_writer = (payload) => {
		return this.http.post<any>(this.create_api_writer_url, payload);
	}

	private update_api_writer_url = `${this.env.dds_service_url}/update_api_writer`;
	update_api_writer = (payload) => {
		return this.http.post<any>(this.update_api_writer_url, payload);
	}

	private get_api_writer_url = `${this.env.dds_service_url}/get_api_writer`;
	get_api_writer = (payload) => {
		return this.http.post<any>(this.get_api_writer_url, payload);
	}

	private delete_api_writer_url = `${this.env.dds_service_url}/delete_api_writer`;
	delete_api_writer = (payload) => {
		return this.http.post<any>(this.delete_api_writer_url, payload);
	}

	private db_write_run_url = `${this.env.dds_service_url}/db_write_run`;
	db_write_run = (payload) => {
		return this.http.post<any>(this.db_write_run_url, payload);
	}

	private create_write_db_config_url = `${this.env.dds_service_url}/create_write_db_config`;
	create_write_db_config = (payload) => {
		return this.http.post<any>(this.create_write_db_config_url, payload);
	}

	private update_write_db_config_url = `${this.env.dds_service_url}/update_write_db_config`;
	update_write_db_config = (payload) => {
		return this.http.post<any>(this.update_write_db_config_url, payload);
	}

	private get_write_db_config_url = `${this.env.dds_service_url}/get_write_db_config`;
	get_write_db_config = (payload) => {
		return this.http.post<any>(this.get_write_db_config_url, payload);
	}

	private get_write_db_config_copy_url = `${this.env.dds_service_url}/get_write_db_config_copy`;
	get_write_db_config_copy = (payload) => {
		return this.http.post<any>(this.get_write_db_config_copy_url, payload);
	}

	private get_all_write_db_configs_url = `${this.env.dds_service_url}/get_all_write_db_configs`;
	get_all_write_db_configs = (payload) => {
		return this.http.post<any>(this.get_all_write_db_configs_url, payload);
	}

	private delete_write_db_config_url = `${this.env.dds_service_url}/delete_write_db_config`;
	delete_write_db_config = (payload) => {
		return this.http.post<any>(this.delete_write_db_config_url, payload);
	}

	private get_all_version_configurations_across_version_url = `${this.env.dds_service_url}/get_all_version_configurations_across_version`;
	get_all_version_configurations_across_version = (payload) => {
		return this.http.post<any>(this.get_all_version_configurations_across_version_url, payload);
	}

	private get_report_configuration_url = `${this.env.dds_service_url}/get_report_configuration`;
	get_report_configuration = (payload) => {
		return this.http.post<any>(this.get_report_configuration_url, payload);
	}

	private create_report_configuration_url = `${this.env.dds_service_url}/create_report_configuration`;
	create_report_configuration = (payload) => {
		return this.http.post<any>(this.create_report_configuration_url, payload);
	}

	private update_report_configuration_url = `${this.env.dds_service_url}/update_report_configuration`;
	update_report_configuration = (payload) => {
		return this.http.post<any>(this.update_report_configuration_url, payload);
	}

	private remove_report_configuration_url = `${this.env.dds_service_url}/remove_report_configuration`;
	remove_report_configuration = (payload) => {
		return this.http.post<any>(this.remove_report_configuration_url, payload);
	}

	private write_file_from_report_configuration_url = `${this.env.dds_service_url}/write_file_from_report_configuration`;
	write_file_from_report_configuration = (payload) => {
		return this.http.post<any>(this.write_file_from_report_configuration_url, payload);
	}

	private add_data_object_from_api_url = `${this.env.dds_service_url}/add_data_object_from_api`;
	add_data_object_from_api = (payload) => {
		return this.http.post<any>(this.add_data_object_from_api_url, payload);
	}

	private test_api_data_source_url = `${this.env.dds_service_url}/test_api_data_source`;
	test_api_data_source = (payload) => {
		return this.http.post<any>(this.test_api_data_source_url, payload);
	}

	private save_api_data_source_url = `${this.env.dds_service_url}/save_api_data_source`;
	save_api_data_source = (payload) => {
		return this.http.post<any>(this.save_api_data_source_url, payload);
	}

	private scan_version_dir_url = `${this.env.dds_service_url}/scan_version_dir`;
	scan_version_dir = (payload) => {
		return this.http.post<any>(this.scan_version_dir_url, payload);
	}

	//add file for testing/saving file based on mode
	private add_file_url = `${this.env.dds_service_url}/add_file`;
	add_file = (payload) => {
		return this.http.post<any>(this.add_file_url, payload);
	}
	private get_data_object_properties_url = `${this.env.dds_service_url}/get_data_object_properties`;
	get_data_object_properties = (payload) => {
		return this.http.post<any>(this.get_data_object_properties_url, payload);
	}
	//add a new column 
	private add_column_url = `${this.env.dds_service_url}/add_column`;
	add_column = (payload) => {
		return this.http.post<any>(this.add_column_url, payload);
	}
	//operations for adding column
	private custom_functions_url = `${this.env.dds_service_url}/custom_functions`;
	custom_functions = (payload) => {
		return this.http.post<any>(this.custom_functions_url, payload);
	}

	private data_obj_custom_functions_url = `${this.env.dds_service_url}/data_obj_custom_functions`;
	data_obj_custom_functions = (payload) => {
		return this.http.post<any>(this.data_obj_custom_functions_url, payload);
	}

	//rebuild column
	private rebuild_column_url = `${this.env.dds_service_url}/rebuild_column`;
	rebuild_column = (payload) => {
		return this.http.post<any>(this.rebuild_column_url, payload);
	}
	//get_computed columns	
	// private get_computed_columns_url = `${this.env.dds_service_url}/get_computed_columns`;
	// get_computed_columns = (payload) => {
	// 	return this.http.post<any>(this.get_computed_columns_url, payload);
	// }

	private get_computed_cols_details_url = `${this.env.dds_service_url}/get_computed_cols_details`;
	get_computed_cols_details = (payload) => {
		return this.http.post<any>(this.get_computed_cols_details_url, payload);
	}

	private delete_computed_col_url = `${this.env.dds_service_url}/delete_computed_col`;
	delete_computed_col = (payload) => {
		return this.http.post<any>(this.delete_computed_col_url, payload);
	}

	private get_unique_columns_url = `${this.env.dds_service_url}/get_unique_columns`;
	get_unique_columns = (payload) => {
		return this.http.post<any>(this.get_unique_columns_url, payload);
	}

	private get_null_values_of_columns_url = `${this.env.dds_service_url}/get_null_values_of_columns`;
	get_null_values_of_columns = (payload) => {
		return this.http.post<any>(this.get_null_values_of_columns_url, payload);
	}

	private get_cols_desc_url = `${this.env.dds_service_url}/get_cols_desc`;
	get_cols_desc = (payload) => {
		return this.http.post<any>(this.get_cols_desc_url, payload);
	}

	private update_col_description_url = `${this.env.dds_service_url}/update_col_description`;
	update_col_description = (payload) => {
		return this.http.post<any>(this.update_col_description_url, payload);
	}

	private set_column_datatype_url = `${this.env.dds_service_url}/set_column_datatype`;
	set_column_datatype = (payload) => {
		return this.http.post<any>(this.set_column_datatype_url, payload);
	}

	private set_computed_column_datatype_url = `${this.env.dds_service_url}/set_computed_column_datatype`;
	set_computed_column_datatype = (payload) => {
		return this.http.post<any>(this.set_computed_column_datatype_url, payload);
	}

	private get_counter_for_columnnames_url = `${this.env.dds_service_url}/get_counter_for_columnnames`;
	get_counter_for_columnnames = (payload) => {
		return this.http.post<any>(this.get_counter_for_columnnames_url, payload);
	}

	private column_counter_to_excel_url = `${this.env.dds_service_url}/column_counter_to_excel`;
	column_counter_to_excel = (payload) => {
		return this.http.post<any>(this.column_counter_to_excel_url, payload);
	}

	//drop column
	private drop_column_url = `${this.env.dds_service_url}/drop_column`;
	drop_column = (payload) => {
		return this.http.post<any>(this.drop_column_url, payload);
	}

	private delete_column_url = `${this.env.dds_service_url}/delete_column`;
	delete_column = (payload) => {
		return this.http.post<any>(this.delete_column_url, payload);
	}

	//drop table
	private drop_table_url = `${this.env.dds_service_url}/drop_table`;
	drop_table = (payload) => {
		return this.http.post<any>(this.drop_table_url, payload);
	}
	//delete table
	private delete_table_url = `${this.env.dds_service_url}/delete_table`;
	delete_table = (payload) => {
		return this.http.post<any>(this.delete_table_url, payload);
	}
	// //show_tables loaded inmemory
	// private show_tables_url = `${this.env.dds_service_url}/show_tables`;
	// show_tables = () => {
	// 	return this.http.get<any>(this.show_tables_url);
	// }

	private load_indices_url = `${this.env.dds_service_url}/load_indices`;
	load_indices = (payload) => {
		return this.http.post<any>(this.load_indices_url, payload);
	}
	private unload_indices_url = `${this.env.dds_service_url}/unload_indices`;
	unload_indices = (payload) => {
		return this.http.post<any>(this.unload_indices_url, payload);
	}

	private load_index_url = `${this.env.dds_service_url}/load_index`;
	load_index = (payload) => {
		return this.http.post<any>(this.load_index_url, payload);
	}

	private unload_index_url = `${this.env.dds_service_url}/unload_index`;
	unload_index = (payload) => {
		return this.http.post<any>(this.unload_index_url, payload);
	}

	private delete_index_url = `${this.env.dds_service_url}/delete_index`;
	delete_index = (payload) => {
		return this.http.post<any>(this.delete_index_url, payload);
	}

	private create_index_url = `${this.env.dds_service_url}/create_index`;
	create_index = (payload) => {
		return this.http.post<any>(this.create_index_url, payload);
	}
	//search_index
	private search_index_url = `${this.env.dds_service_url}/search_index`;
	search_index = (payload) => {
		return this.http.post<any>(this.search_index_url, payload);
	}
	//show_indices
	private show_indices_url = `${this.env.dds_service_url}/show_indices`;
	show_indices = (payload) => {
		return this.http.post<any>(this.show_indices_url, payload);
	}

	private get_index_sample_keys_url = `${this.env.dds_service_url}/get_index_sample_keys`;
	get_index_sample_keys = (payload) => {
		return this.http.post<any>(this.get_index_sample_keys_url, payload);
	}

	private index_operation_get_rec_all_url = `${this.env.dds_service_url}/index_operation/get_rec_all`;
	index_operation_get_rec_all = (payload) => {
		return this.http.post<any>(this.index_operation_get_rec_all_url, payload);
	}
	//show_header
	private show_header_url = `${this.env.dds_service_url}/show_header`;
	show_header = (payload) => {
		return this.http.post<any>(this.show_header_url, payload);
	}

	private get_data_object_cols_url = `${this.env.dds_service_url}/get_data_object_cols`;
	get_data_object_cols = (payload) => {
		return this.http.post<any>(this.get_data_object_cols_url, payload);
	}

	//join_table
	private join_files_url = `${this.env.dds_service_url}/join_files`;
	join_files = (payload) => {
		return this.http.post<any>(this.join_files_url, payload);
	}
	//show_custom_modules
	private show_custom_modules_url = `${this.env.dds_service_url}/show_custom_modules`;
	show_custom_modules = (payload) => {
		return this.http.post<any>(this.show_custom_modules_url, payload);
	}
	//test function_string
	private test_function_string_url = `${this.env.dds_service_url}/test_syntax_error`;
	test_function_string = (payload) => {
		return this.http.post<any>(this.test_function_string_url, payload);
	}

	private compile_function_url = `${this.env.dds_service_url}/compile_function`;
	compile_function = (payload) => {
		return this.http.post<any>(this.compile_function_url, payload);
	}
	//add_custom_function
	private add_custom_function_url = `${this.env.dds_service_url}/add_custom_function`;
	add_custom_function = (payload) => {
		return this.http.post<any>(this.add_custom_function_url, payload);
	}

	private refresh_custom_functions_url = `${this.env.dds_service_url}/refresh_custom_functions`;
	refresh_custom_functions = (payload) => {
		return this.http.post<any>(this.refresh_custom_functions_url, payload);
	}

	private delete_custom_function_url = `${this.env.dds_service_url}/delete_custom_function`;
	delete_custom_function = (payload) => {
		return this.http.post<any>(this.delete_custom_function_url, payload);
	}

	//edit_custom_function
	private edit_custom_function_url = `${this.env.dds_service_url}/edit_custom_function`;
	edit_custom_function = (payload) => {
		return this.http.post<any>(this.edit_custom_function_url, payload);
	}

	//get_custom_function
	private get_custom_function_url = `${this.env.dds_service_url}/get_custom_function`;
	get_custom_function = (payload) => {
		return this.http.post<any>(this.get_custom_function_url, payload);
	}

	private get_all_version_url = `${this.env.dds_service_url}/function/revision/history/all_versions`;
	get_all_version = (payload) => {
		return this.http.post<any>(this.get_all_version_url, payload);
	}

	private get_version_data_url = `${this.env.dds_service_url}/function/revision/history/version_data`;
	get_version_data = (payload) => {
		return this.http.post<any>(this.get_version_data_url, payload);
	}

	private get_all_script_url = `${this.env.dds_service_url}/script/revision/history/all_versions`;
	get_all_script = (payload) => {
		return this.http.post<any>(this.get_all_script_url, payload);
	}

	private get_script_url = `${this.env.dds_service_url}/script/revision/history/version_data`;
	get_script = (payload) => {
		return this.http.post<any>(this.get_script_url, payload);
	}

	private get_all_filter_func_url = `${this.env.dds_service_url}/filter/revision/history/all_versions`;
	get_all_filter_func = (payload) => {
		return this.http.post<any>(this.get_all_filter_func_url, payload);
	}

	private get_filter_data_url = `${this.env.dds_service_url}/filter/revision/history/version_data`;
	get_filter_func_data = (payload) => {
		return this.http.post<any>(this.get_filter_data_url, payload);
	}

	//get_table_details
	private get_table_details_url = `${this.env.dds_service_url}/get_table_details`;
	get_table_details = (payload) => {
		return this.http.post<any>(this.get_table_details_url, payload);
	}

	private key_value_relation_mapping_url = `${this.env.dds_service_url}/key_value_relation_mapping`;
	key_value_relation_mapping = (payload) => {
		return this.http.post<any>(this.key_value_relation_mapping_url, payload);
	}

	private get_all_custom_mapping_url = `${this.env.dds_service_url}/get_all_custom_mapping`;
	get_all_custom_mapping = (payload) => {
		return this.http.post<any>(this.get_all_custom_mapping_url, payload);
	}

	private get_custom_mapping_url = `${this.env.dds_service_url}/get_custom_mapping`;
	get_custom_mapping = (payload) => {
		return this.http.post<any>(this.get_custom_mapping_url, payload);
	}

	private create_custom_mapping_url = `${this.env.dds_service_url}/create_custom_mapping`;
	create_custom_mapping = (payload) => {
		return this.http.post<any>(this.create_custom_mapping_url, payload);
	}

	private update_custom_mapping_url = `${this.env.dds_service_url}/update_custom_mapping`;
	update_custom_mapping = (payload) => {
		return this.http.post<any>(this.update_custom_mapping_url, payload);
	}

	private delete_custom_mapping_url = `${this.env.dds_service_url}/delete_custom_mapping`;
	delete_custom_mapping = (payload) => {
		return this.http.post<any>(this.delete_custom_mapping_url, payload);
	}

	private build_mapping_url = `${this.env.dds_service_url}/build_mapping`;
	build_mapping = (payload) => {
		return this.http.post<any>(this.build_mapping_url, payload);
	}

	private get_connection_string_url = `${this.env.dds_service_url}/get_connection_string`;
	get_connection_string = (payload) => {
		return this.http.post<any>(this.get_connection_string_url, payload);
	}

	private save_connection_string_url = `${this.env.dds_service_url}/save_connection_string`;
	save_connection_string = (payload) => {
		return this.http.post<any>(this.save_connection_string_url, payload);
	}

	private update_connection_string_url = `${this.env.dds_service_url}/update_connection_string`;
	update_connection_string = (payload) => {
		return this.http.post<any>(this.update_connection_string_url, payload);
	}

	private update_connection_string_password_url = `${this.env.dds_service_url}/update_connection_string_password`;
	update_connection_string_password = (payload) => {
		return this.http.post<any>(this.update_connection_string_password_url, payload);
	}

	private test_connection_string_url = `${this.env.dds_service_url}/test_connection_string`;
	test_connection_string = (payload) => {
		return this.http.post<any>(this.test_connection_string_url, payload);
	}

	private delete_connection_string_url = `${this.env.dds_service_url}/delete_connection_string`;
	delete_connection_string = (payload) => {
		return this.http.post<any>(this.delete_connection_string_url, payload);
	}

	private get_schemas_from_connection_string_url = `${this.env.dds_service_url}/get_schemas_from_connection_string`;
	get_schemas_from_connection_string = (payload) => {
		return this.http.post<any>(this.get_schemas_from_connection_string_url, payload);
	}

	private get_tables_from_connection_string_url = `${this.env.dds_service_url}/get_tables_from_connection_string`;
	get_tables_from_connection_string = (payload) => {
		return this.http.post<any>(this.get_tables_from_connection_string_url, payload);
	}

	private get_columns_from_connection_string_url = `${this.env.dds_service_url}/get_columns_from_connection_string`;
	get_columns_from_connection_string = (payload) => {
		return this.http.post<any>(this.get_columns_from_connection_string_url, payload);
	}

	private create_filter_function_url = `${this.env.dds_service_url}/create_filter_function`;
	create_filter_function = (payload) => {
		return this.http.post<any>(this.create_filter_function_url, payload);
	}

	private update_filter_function_url = `${this.env.dds_service_url}/update_filter_function`;
	update_filter_function = (payload) => {
		return this.http.post<any>(this.update_filter_function_url, payload);
	}

	private get_filter_function_url = `${this.env.dds_service_url}/get_filter_function`;
	get_filter_function = (payload) => {
		return this.http.post<any>(this.get_filter_function_url, payload);
	}

	private get_all_filter_functions_url = `${this.env.dds_service_url}/get_all_filter_functions`;
	get_all_filter_functions = (payload) => {
		return this.http.post<any>(this.get_all_filter_functions_url, payload);
	}

	private delete_filter_function_url = `${this.env.dds_service_url}/delete_filter_function`;
	delete_filter_function = (payload) => {
		return this.http.post<any>(this.delete_filter_function_url, payload);
	}

	private compile_filter_function_url = `${this.env.dds_service_url}/compile_filter_function`;
	compile_filter_function = (payload) => {
		return this.http.post<any>(this.compile_filter_function_url, payload);
	}

	private get_global_imports_url = `${this.env.dds_service_url}/get_global_imports`;
	get_global_imports = (payload) => {
		return this.http.post<any>(this.get_global_imports_url, payload);
	}

	private get_all_global_imports_url = `${this.env.dds_service_url}/get_all_global_imports`;
	get_all_global_imports = (payload) => {
		return this.http.post<any>(this.get_all_global_imports_url, payload);
	}

	private compile_global_imports_url = `${this.env.dds_service_url}/compile_global_imports`;
	compile_global_imports = (payload) => {
		return this.http.post<any>(this.compile_global_imports_url, payload);
	}

	private create_global_imports_url = `${this.env.dds_service_url}/create_global_imports`;
	create_global_imports = (payload) => {
		return this.http.post<any>(this.create_global_imports_url, payload);
	}

	private update_global_imports_url = `${this.env.dds_service_url}/update_global_imports`;
	update_global_imports = (payload) => {
		return this.http.post<any>(this.update_global_imports_url, payload);
	}

	private delete_global_imports_url = `${this.env.dds_service_url}/delete_global_imports`;
	delete_global_imports = (payload) => {
		return this.http.post<any>(this.delete_global_imports_url, payload);
	}

	private create_or_update_global_imports_url = `${this.env.dds_service_url}/create_or_update_global_imports`;
	create_or_update_global_imports = (payload) => {
		return this.http.post<any>(this.create_or_update_global_imports_url, payload);
	}

	private get_all_tables_and_columns_url = `${this.env.dds_service_url}/get_all_tables_and_columns`;
	get_all_tables_and_columns = (payload) => {
		return this.http.post<any>(this.get_all_tables_and_columns_url, payload);
	}

	private get_pipeline_instace_log_rec_url = `${this.env.dds_service_url}/get_pipeline_instance_log_rec`;
	get_pipeline_instance_log_rec = (payload) => {
		return this.http.post<any>(this.get_pipeline_instace_log_rec_url, payload);
	}

	private get_current_running_pipelines_url = `${this.env.dds_service_url}/pipeline/get_current_running_pipelines`;
	get_current_running_pipelines = (payload) => {
		return this.http.post<any>(this.get_current_running_pipelines_url, payload);
	}

	private get_api_writer_audit_log_url = `${this.env.dds_service_url}/get_api_writer_audit_log`;
	get_api_writer_audit_log = (payload) => {
		return this.http.post<any>(this.get_api_writer_audit_log_url, payload);
	}

	private get_user_home_dashboard_url = `${this.env.db_service_url}/api/lg_show_user_home_dashboard`
	get_user_home_dashboard(filter) {
		return this.http.get<any>(this.get_user_home_dashboard_url + filter);
	}

	// notes services
	private note_add_url = `${this.env.dds_service_url}/note/add`
	note_add(payload) {
		return this.http.post<any>(this.note_add_url, payload);
	}
	private note_edit_url = `${this.env.dds_service_url}/note/edit`
	note_edit(payload) {
		return this.http.post<any>(this.note_edit_url, payload);
	}
	private note_delete_url = `${this.env.dds_service_url}/note/delete`
	note_delete(payload) {
		return this.http.post<any>(this.note_delete_url, payload);
	}
	private note_get_url = `${this.env.dds_service_url}/note/get`
	note_get(payload) {
		return this.http.post<any>(this.note_get_url, payload);
	}
	private note_get_all_url = `${this.env.dds_service_url}/note/version/get_all`
	note_get_all(payload) {
		return this.http.post<any>(this.note_get_all_url, payload);
	}

	private get_data_obj_group_url = `${this.env.dds_service_url}/dojgroup/meta/get`
	get_data_obj_group(payload) {
		return this.http.post<any>(this.get_data_obj_group_url, payload);
	}

	private create_data_obj_group_url = `${this.env.dds_service_url}/dojgroup/meta/create`
	create_data_obj_group(payload) {
		return this.http.post<any>(this.create_data_obj_group_url, payload);
	}

	private update_data_obj_group_url = `${this.env.dds_service_url}/dojgroup/meta/update`
	update_data_obj_group(payload) {
		return this.http.post<any>(this.update_data_obj_group_url, payload);
	}

	private remove_data_obj_group_url = `${this.env.dds_service_url}/dojgroup/meta/remove`
	remove_data_obj_group(payload) {
		return this.http.post<any>(this.remove_data_obj_group_url, payload);
	}

	private rebuild_all_doj_in_doj_grp_url = `${this.env.dds_service_url}/dojgroup/transform/rebuild_column`
	rebuild_all_doj_in_doj_grp(payload) {
		return this.http.post<any>(this.rebuild_all_doj_in_doj_grp_url, payload);
	}

	private unload_all_doj_in_doj_grp_url = `${this.env.dds_service_url}/dojgroup/transform/delete_table`
	unload_all_doj_in_doj_grp(payload) {
		return this.http.post<any>(this.unload_all_doj_in_doj_grp_url, payload);
	}

	private load_all_indices_doj_grp_url = `${this.env.dds_service_url}/dojgroup/transform/load_indices`
	load_all_indices_doj_grp(payload) {
		return this.http.post<any>(this.load_all_indices_doj_grp_url, payload);
	}

	// EXPORT 
	private aoi_export_db_url = `${this.env.db_service_url}/dashboard/exaoi`;
	aoi_export_db = (payload) => {
		return this.http.post<any>(this.aoi_export_db_url, payload);
	}
	private export_db_url = `${this.env.db_service_url}/dashboard/export`;
	export_db = (payload) => {
		return this.http.post<any>(this.export_db_url, payload);
	}

	// IMPORT 
	private aoi_import_db_url = `${this.env.db_service_url}/dashboard/imaoi`;
	aoi_import_db = (payload) => {
		return this.http.post<any>(this.aoi_import_db_url, payload);
	}
	private import_dshb_url = `${this.env.db_service_url}/dashboard/import`;
	import_dshb = (payload) => {
		return this.http.post<any>(this.import_dshb_url, payload);
	}

	private get_email_template_list_url = `${this.env.dds_service_url}/all_email_config_templates`;
	get_email_template_list = (payload) => {
		return this.http.post<any>(this.get_email_template_list_url, payload);
	}

	private search_soap_api_helper_data_url = `${this.env.dds_service_url}/search_soap_api_helper_data`;
	search_soap_api_helper_data = (payload) => {
		return this.http.post<any>(this.search_soap_api_helper_data_url, payload);
	}

	private search_soap_api_logs_url = `${this.env.dds_service_url}/search_soap_api_logs`;
	search_soap_api_logs = (payload) => {
		return this.http.post<any>(this.search_soap_api_logs_url, payload);
	}

	private get_home_dshb_url = `${this.env.db_service_url}/api/int/get_user_home_dashboard`;
	get_home_dshb = (payload) => {
		return this.http.post<any>(this.get_home_dshb_url, payload);
	}
	

	private get_dshb_grp_url = `${this.env.db_service_url}/api/int/get_user_dashboard_group`;
	get_dshb_grp = (payload) => {
		return this.http.post<any>(this.get_dshb_grp_url, payload);
	}

	private get_all_user_dashboard_url = `${this.env.db_service_url}/api/int/get_user_dashboard`;
	get_all_user_dashboard = (payload) => {
		return this.http.post<any>(this.get_all_user_dashboard_url, payload);
	}


	//Object Analysis
	private get_table_sql_url = `${this.env.wh_service_url}/whapi/get_table_sql`;
	get_table_sql = (payload) => {
		return this.http.post<any>(this.get_table_sql_url, payload);
	}

	private get_view_sql_url = `${this.env.wh_service_url}/whapi/get_view_sql`;
	get_view_sql = (payload) => {
		return this.http.post<any>(this.get_view_sql_url, payload);
	}

	private get_function_sql_url = `${this.env.wh_service_url}/whapi/get_function_sql`;
	get_function_sql = (payload) => {
		return this.http.post<any>(this.get_function_sql_url, payload);
	}

	private get_index_sql_url = `${this.env.wh_service_url}/whapi/get_index_sql`;
	get_index_sql = (payload) => {
		return this.http.post<any>(this.get_index_sql_url, payload);
	}

	
	///////////// Common Function ////////////////
	private add_file_format_checker_url = `${this.env.dds_service_url}/file_format_checker/add`;
	add_file_format_checker = (payload) => {
		return this.http.post<any>(this.add_file_format_checker_url, payload);
	}

	private update_file_format_checker_url = `${this.env.dds_service_url}/file_format_checker/update`;
	update_file_format_checker = (payload) => {
		return this.http.post<any>(this.update_file_format_checker_url, payload);
	}

	private remove_file_health_checker_url = `${this.env.dds_service_url}/file_format_checker/remove`;
	remove_file_health_checker = (payload) => {
		return this.http.post<any>(this.remove_file_health_checker_url, payload);
	}
	
	private get_all_file_health_checker_url = `${this.env.dds_service_url}/file_format_checker/get_all`;
	get_all_file_health_checker = (payload) => {
		return this.http.post<any>(this.get_all_file_health_checker_url, payload);
	}

	private add_excel_format_checker_url = `${this.env.dds_service_url}/excel_file_format_check/add`;
	add_excel_format_checker = (payload) => {
		return this.http.post<any>(this.add_excel_format_checker_url, payload);
	}

	private update_excel_format_checker_url = `${this.env.dds_service_url}/excel_file_format_check/update`;
	update_excel_format_checker = (payload) => {
		return this.http.post<any>(this.update_excel_format_checker_url, payload);
	}

	private get_excel_format_check_url = `${this.env.dds_service_url}/excel_file_format_check/get`;
	get_excel_format_check = (payload) => {
		return this.http.post<any>(this.get_excel_format_check_url, payload);
	}

	// method to provide dynamic width to datatable
	assign_width_to_cols(rows, cols) {
		// for optimization made canvas here instead of shifting it to method.
		// to avoid initiating in loop
		let canvas = document.createElement("canvas");
		let context = canvas.getContext("2d");
		let str_len_obj = {};
		for (let i = 0; i < rows.length; i++) {
			for (let j in rows[i]) {
				const metrics = context.measureText(rows[i][j]);
				const index_val_len = metrics.width * 2;
				if (j in str_len_obj) {
					if (str_len_obj[j] < index_val_len) {
						str_len_obj[j] = index_val_len;
					}
				} else {
					str_len_obj[j] = index_val_len;
				}
			}
		}
		for (let i = 0; i < cols.length; i++) {
			for (let j in str_len_obj) {
				if (cols[i].prop === j) {
					const measure_prop_width = context.measureText(cols[i].name);
					const prop_width = measure_prop_width.width * 2;
					if (prop_width >= str_len_obj[j]) {
						cols[i].width = prop_width;
					} else {
						cols[i].width = str_len_obj[j];
					}
				}
			}
		}
		canvas.remove();
		return cols;
	}

	convert_string_to_json(str_data) {
		if (str_data != '') {
			if (this.IsJsonString(str_data)) {
				return { 'message': '', 'valid': true, 'data': JSON.parse(str_data) }
			}
		} else {
			return { 'message': '', 'valid': true, 'data': {} }
		}
		return { 'message': 'Please enter a valid JSON', 'valid': false, 'data': '' }
	}

	IsJsonString(str) {
		try {
			JSON.parse(str);
		} catch (e) {
			return false;
		}
		return true;
	}

	parse_hidden_parameter(hidden_param_data) {
		const parsed_data = {};
		for (let i = 0; i < hidden_param_data.length; i++) {
			parsed_data[hidden_param_data[i].paramName] = hidden_param_data[i].paramValue;
		}
		return parsed_data;
	}

	filter_data_in_datatable(val, column_list, data) {

		let data_list = [];
		if (val) {
			val = val.toLowerCase();
		} else {
			return data_list = [...data];
		}

		if (!column_list.length) {
			return;
		}

		const rows = data.filter(function (d) {
			for (let i = 0; i <= column_list.length; i++) {
				const column = column_list[i];
				if (d[column] && d[column].toString().toLowerCase().indexOf(val) > -1) {
					return true;
				}
			}
		});
		data_list = rows;
		return data_list;
	}

	qry_builder_decision_to_backend(decision_data, data_type_map?) {
		var condition_map = {
			"AND": 'and',
			"OR": 'or',
			"NOT": 'not'
		};

		var operator_map = {
			"equal": '==',
			"not_equal": '!=',
			"less": '<',
			"less_or_equal": '<=',
			"greater": '>',
			"greater_or_equal": '>=',
			"is_null": "is_null",
			"is_not_null": "is_not_null",
			// 'in':'in',
			// 'not_in':'not_in',
			// 'between':'between',
			// 'not_between':'not_between',
			// 'begins_with':'begins_with',
			// 'not_begins_with':'not_begins_with',
			// 'contains':'contains',
			// 'not_contains':'not_contains',
			// 'ends_with':'ends_with',
			// 'not_ends_with':'not_ends_with',
			// 'is_empty':'is_empty',
			// 'is_not_empty':'is_not_empty',
		};

		// console.log(decision_data);
		var ret_val = {};
		var temp_list = [];
		var condition = decision_data['condition'];
		var rules = decision_data['rules'];
		var ret = {};
		var final_list = [];
		var d_type = 'str';

		for (var i = 0; i < rules.length; i++) {
			if (rules[i]['condition'] != undefined) {
				ret = this.qry_builder_decision_to_backend(rules[i], data_type_map);
			} else {
				d_type = "str";
				// if(data_type_map[rules[i]['id']]){
				// 	d_type = data_type_map[rules[i]['id']];
				// }
				temp_list.push({
					"operator": operator_map[rules[i]['operator']],
					// "type": "str",	//	not required
					// "data_type": data_type_map[rules[i]['id']],  // i think datatype not required here
					// we only need operands and operator rest datatype is already known in table map itself.
					"op1": rules[i]['id'],
					"op2": rules[i]['value']
				});
			}
		}
		if (!$.isEmptyObject(ret)) {
			final_list = temp_list.concat(ret);
		} else {
			final_list = temp_list;
		}

		ret_val[condition_map[condition]] = final_list;
		// console.log(condition);
		// console.log(final_list);
		// console.log(ret_val);
		// console.log("-------");
		// debugger
		return ret_val;
	}

	date_to_string_for_month(event) {
		const y = event.getFullYear(), m = event.getMonth();
		const firstdate = new Date(y, m, 1);
		const lastdate = new Date(y, m + 1, 0);
		const firstdate_string = firstdate.getFullYear() + '-' + (firstdate.getMonth() + 1) + '-' +firstdate.getDate();
		const lastdate_string = lastdate.getFullYear() + '-' + (lastdate.getMonth() + 1) + '-' +lastdate.getDate();
		return [firstdate_string, lastdate_string];
	}

	date_to_string_for_week(date) {
		var first = date.getDate() - date.getDay(); // First day is the day of the month - the day of the week
		var last = first + 6; // last day is the first day + 6
		var firstday = new Date(date.setDate(first));
		var lastday = new Date(date.setDate(last));
		const firstdate_string = firstday.getFullYear() + '-' + (firstday.getMonth() + 1) + '-' +firstday.getDate();
		const lastdate_string = lastday.getFullYear() + '-' + (lastday.getMonth() + 1) + '-' +lastday.getDate();
		return [firstdate_string, lastdate_string];
	  }
	
	  date_to_string_for_day(date) {
		const firstdate_string = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' +date.getDate();
		const lastdate_string = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' +date.getDate();
		return [firstdate_string, lastdate_string];
	  }

	async copy_to_clipboard(copied_text,key='',separator='\n'){
		try {
			if(typeof(copied_text) == 'string'){
				await navigator.clipboard.writeText(copied_text);
			}else if(isArray(copied_text)){
				if(key != ''){
					const values = copied_text.map(item => item[key]);
					const valuesString = values.join(separator);
					await navigator.clipboard.writeText(valuesString);
				}else{
					const valuesString = copied_text.join(separator);
					await navigator.clipboard.writeText(valuesString);
				}
			}
			this.snotifyService.success("Copied to clipboard successfully", this.utilityService.getConfig());
		} catch (error) {
			this.snotifyService.success("Cannot be copied to clipboard", this.utilityService.getConfig());
			console.log(error)
		}
	}

	sort_list_of_objects(list,key){
		list.sort((a, b) => {
			const data1 = a[key].toString().toUpperCase();
			const data2 = b[key].toString().toUpperCase();
			if (data1 < data2) {
				return -1;
			}
			if (data1 > data2) {
				return 1;
			}
			return 0;
		});
	}
}
