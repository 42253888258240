export interface Theme {
  name: string,
  properties: any
}

export const basic: Theme = {
  name : 'basic',
  properties: {
    "--primary-default":"#3C6281", // main theme color
    "--secondary-default":"#19aa8d", // secondary color
    "--background-tertiary": "#1ab394", 
    "--btn-primary-bg-color": "#3C6281", // btn color
    "--text-color-primary": "#CFCFCF", // color of text
    "--background-default":"#2f4050", // background
    "--card-header-bg-color": "#3C6281",
    "--nav-header-bg-color": "#2f4050",
    "--nav-header-secondary-color": "#293846", // lighter shade
    "--nav-seconds-level-bg-color": "#293846",
    "--logo-element-bg-color":"#2f4050",
    "--skill-btn-bg-color":"#000000",
    "--skill-btn-disabled-color": "#9e9e9e5c",
    "--list-area-box-shadow-color": "#959595",
    "--filter-border-color":"#676A6C"
  }
}

export const green: Theme = {
  name : 'green',
  properties: {
    "--primary-default":"#397B5F", // main theme color
    "--secondary-default":"#FFD63D", // secondary color
    "--background-tertiary": "#1ab394", 
    "--btn-primary-bg-color": "#397B5F", // btn color
    "--text-color-primary": "#ffffff", // color of text
    "--background-default":"#339C75", // background
    "--card-header-bg-color": "#428E6D",
    "--nav-header-bg-color": "#339C75",
    "--nav-header-secondary-color": "#428E6D", // lighter shade
    "--nav-seconds-level-bg-color": "#428E6D",
    "--logo-element-bg-color":"#339C75",
    "--skill-btn-bg-color":"#000000",
    "--skill-btn-disabled-color": "#9e9e9e5c",
    "--list-area-box-shadow-color": "#959595",
    "--filter-border-color":"#FFD63D"
  }
}

export const light: Theme = {
  name: "light",
  properties: {
    "--foreground-default": "#08090A",
    "--foreground-secondary": "#41474D",
    "--foreground-tertiary": "#797C80",
    "--foreground-quaternary": "#F4FAFF",
    "--foreground-light": "#41474D",

    "--background-default": "#F4FAFF",
    "--background-secondary": "#A3B9CC",
    "--background-tertiary": "#5C7D99",
    "--background-light": "#FFFFFF",

    "--primary-default": "#5DFDCB",
    "--primary-dark": "#24B286",
    "--primary-light": "#B2FFE7",

    "--error-default": "#EF3E36",
    "--error-dark": "#800600",
    "--error-light": "#FFCECC",

    "--background-tertiary-shadow": "0 1px 3px 0 rgba(92, 125, 153, 0.5)",
    '--text-color':'#000000',
  }
};

export const dark: Theme = {
  name: "dark",
  properties: {
    "--foreground-default": "#5C7D99",
    "--foreground-secondary": "#A3B9CC",
    "--foreground-tertiary": "#F4FAFF",
    "--foreground-quaternary": "#E5E5E5",
    "--foreground-light": "#FFFFFF",

    "--background-default": "#797C80",
    "--background-secondary": "#41474D",
    "--background-tertiary": "#08090A",
    "--background-light": "#41474D",

    "--primary-default": "#5DFDCB",
    "--primary-dark": "#24B286",
    "--primary-light": "#B2FFE7",

    "--error-default": "#EF3E36",
    "--error-dark": "#800600",
    "--error-light": "#FFCECC",

    "--background-tertiary-shadow": "0 1px 3px 0 rgba(8, 9, 10, 0.5)",
    '--text-color':'#ffffff',
  }
};