import { Component, OnInit, Input, OnChanges, SimpleChanges,ViewChild } from '@angular/core';
import { ConfigService } from '../../config-builder/service/config.service';
import { SnotifyService } from 'ng-snotify';
import { UtilityService } from 'src/app/services/utility.service';
@Component({
  selector: 'app-large-report',
  templateUrl: './large-report.component.html',
  styleUrls: ['./large-report.component.css']
})
export class LargeReportComponent implements OnInit {
	@Input() report_id = -1;
	@Input() hidden_filter_object?: any = {};
	@Input() visible_filter_object?: any = {};
	param_def = {
		'hidden_param_val':[],
		'param_val':[],
		'max_records':100,
		'lg_data_recs_order': "",
		'report_download': "direct"
	}
	loading = false
	is_downloadable = false
	is_preparing = false
	filename:string = ''
	report_data:any = {}
	prepare_btn_text = 'Prepare Report'
	interval:any;
	data_view_config: any = {};
	show_view_filter = false;
	view_filter_data = [];
	constructor(
		public _configService: ConfigService,
		public snotifyService: SnotifyService,
		public utilityService: UtilityService
	) { }

	ngOnInit() {
		// if(this.report_id != -1){
		// 	this.loading = true
		// 	this.get_report_data();
		// }
	}
	ngOnChanges(changes:SimpleChanges) {
		if(this.report_id != -1){
			this.loading = true
			this.get_report_data()
		}
	}

	ngOnDestroy(){
		this.clear_interval()
	}
	get_report_data(){
		this._configService.get_report_by_id({'id':this.report_id}).subscribe(res => {
			if(res.errCode == 0){
				this.loading = false
				this.report_data = res.data
				this.data_view_config = res.data.data_view_config? res.data.data_view_config:null;
				if(res.data.param_def != null){
					this.param_def = res.data.param_def;
				}
				let filter_object = {...this.hidden_filter_object, ...this.visible_filter_object};

				let temp_payload = {
					'hidden_filter_data':this.param_def.hidden_param_val,
					'filter_data':this.param_def.param_val,
					"hidden_filter_object": filter_object
				}
				this._configService.process_query_filters(temp_payload).subscribe(res1 => {
					if(res1.errCode == 0){
						this.param_def.hidden_param_val = res1.msg.hidden_filter_data;
						this.param_def.param_val = res1.msg.filter_data
						this.override_filters()
						this.get_job_data(true)
					}
				})
			}
		})
   	}

   	override_filters(){
   		let data = this._configService.process_and_override_filter(this.param_def.hidden_param_val,this.param_def.param_val,this.hidden_filter_object,this.visible_filter_object)
		this.param_def.hidden_param_val = data["hidden_filter_data"]
		this.param_def.param_val = data["filter_data"]
   	}
   	get_job_data(set_interval_flag = false){
   		console.log(this.interval)
   		let payload = {"report_id":this.report_id,"filter_data":this.param_def.param_val,"hidden_filter_data":this.param_def.hidden_param_val}
		this._configService.get_job_data(payload).subscribe(res => {
			if(res.errCode == 0){
				if(res.filter_data && res.filter_data.length > 0){
					this.show_view_filter = true;
					this.view_filter_data = res.filter_data;
				}
				if(res.jobdata['status'] == 'Not Found'){
					this.is_preparing = false
					this.clear_interval()
				}else if(res.jobdata['status'] == 'Finished'){
					this.is_preparing = false;
					this.filename = res.result['filename']
					if(res.result['errCode'] != 0){
						this.snotifyService.error(res.result.msg, this.utilityService.getConfig());
						this.is_downloadable = false;
					}else{
						this.is_downloadable = true;
					}
					this.clear_interval()
				}else if(res.jobdata['status'] == 'Added-To-Queue'){
					this.is_preparing = true
					this.filename = ''
					if(set_interval_flag){
						this.set_interval()
					}
				}
			}
		})
   	}

   	create_report(is_reprepare: boolean){
		this.view_filter_data = this.param_def.param_val;
   		let payload = {"report_id":this.report_id,"filter_data":this.param_def.param_val,"hidden_filter_data":this.param_def.hidden_param_val,  "reprepare": is_reprepare}
		this._configService.create_job_report(payload).subscribe(res2 => {
			if(res2.errCode == 0){
				this.is_preparing = true
				this.is_downloadable = false
				this.set_interval()
			}
		})
   	}

   	set_interval(){
   		this.clear_interval()
		this.interval = setInterval(() => {
			this.get_job_data()
		}, this._configService.job_interval);
   	}

   	clear_interval(){
   		// if(this.interval != undefined){
			clearInterval(this.interval)
		// }
   	}

   	download_report(){
		let payload2 = {"file_type": "report", "file_name": this.filename};
		if (((localStorage.getItem('leggero_username') != null) || (localStorage.getItem('leggero_username') != undefined))) {
				  const is_admin=JSON.parse(localStorage.getItem('lg_admin'))
				  payload2["logdata"] = {
					  "logtype": "report_export",
					  "logdata": {
						  "user_name": localStorage.getItem('leggero_username'),
						  "main_itemname": this.filename,
						  "main_itemid": this.report_id,
						  "dashboard_name": localStorage.getItem('lg_dashboard_name'),
						  "system_access_id": localStorage.getItem('system_access_id'),
						  "is_admin_panel": is_admin == undefined? 0:is_admin
						}
				  }
			  }
		this._configService.download_report(payload2).subscribe(res2 => {
			window.open(res2.download_link, "_blank");
		});
	}

	refresh_job_status(){
		this.get_job_data()
	}

	config_event(event){
		switch(event.case){
			case "apply_filter":
				this.create_report(true);
				break;
			case "close_filter_modal":
				break;
			case "open_filter_modal":
				break;
		}
	}

	remove_job(){
		let payload = {"report_id":this.report_id,"filter_data":this.param_def.param_val,"hidden_filter_data":this.param_def.hidden_param_val}
		this._configService.remove_job(payload).subscribe(res => {
			if(res.errCode == 0){
				this.is_preparing = false;
				this.clear_interval();
				this.is_downloadable = false;
				this.show_view_filter = false;
			}
		})
	}
}
