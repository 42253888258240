import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
// import { DataTablesModule } from 'angular-datatables';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CompositeWidgetRoutingModule } from './composite-widget-routing.module';
import { ColorPickerModule } from 'ngx-color-picker';
import { ConfigBuilderModule } from '../config-builder/config-builder.module'

import { WidgetComponent } from './widget/widget.component';
import { ViewCompositeWidgetComponent } from './view-composite-widget/view-composite-widget.component';

@NgModule({
  declarations: [WidgetComponent, ViewCompositeWidgetComponent],
  imports: [
    CommonModule,
    CompositeWidgetRoutingModule,
    FontAwesomeModule,
    // DataTablesModule,
    FormsModule,
    ReactiveFormsModule,
    ColorPickerModule,
    ConfigBuilderModule,
  ],
  exports:[
    WidgetComponent,
    ViewCompositeWidgetComponent,
    ConfigBuilderModule,
  ]
})
export class CompositeWidgetModule { }
