import { Component, OnInit, Input, ViewChild, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-record-comparison',
  templateUrl: './record-comparison.component.html',
  styleUrls: ['./record-comparison.component.css']
})
export class RecordComparisonComponent implements OnInit, OnChanges {
  @Input() record_list = [];

  header_list = [];
  filtered_header = [];
  search_input_fr_headers = '';
  search_input_fr_recs = '';

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes);
    if(changes.record_list.currentValue != undefined){
      this.record_list = changes.record_list.currentValue;
      this.header_list = [];
      if(changes.record_list.currentValue.length){
        this.serialize_headers();
        this.reset_comparison();
      }
    }
  }

  serialize_headers() {
    this.header_list = [];
    const key_arr = Object.keys(this.record_list[0]);
    for (let i = 0; i < key_arr.length; i++) {
      this.header_list.push({
        'header_name': key_arr[i],
        'is_visible': true,
        'highlighted': false,
      });
    }
    this.filtered_header = this.header_list;
  }

  search_headers(search_input) {
    this.filtered_header = [];
    const input_lowercase = search_input.toLowerCase();
    for (let i = 0; i < this.header_list.length; i++) {
      const is_header_matched = this.header_list[i].header_name.toLowerCase().includes(input_lowercase)
      this.header_list[i].is_visible = is_header_matched;
      if (this.header_list[i].is_visible) {
        this.filtered_header.push(this.header_list[i]);
      }
    }
  }

  search_records_for_col(search_input, rec_index) {
    const input_lowercase = search_input.toLowerCase();
    for (let i = 0; i < this.filtered_header.length; i++) {
      const header_name = this.filtered_header[i]['header_name'];
      const search_matched = this.record_list[rec_index][this.filtered_header[i]['header_name']].toString().toLowerCase().includes(input_lowercase);
      this.filtered_header[i].is_visible = search_matched;
      if (this.filtered_header.length === this.header_list.length) {
        this.header_list[i].is_visible = search_matched;
      } else {
        this.apply_visibility_to_col(header_name, search_matched)
      }
      this.record_list[rec_index].record_matched = search_matched;
    }

  }

  apply_visibility_to_col(header_name, is_visible) {
    for (let i = 0; i < this.header_list.length; i++) {
      if (this.header_list[i]['header_name'] == header_name) {
        this.header_list[i]['is_visible'] = is_visible;
      }
    }
  }

  compare_records() {
    const first_record = this.record_list[0];

    for (let j = 0; j < this.header_list.length; j++) {
      for (let i = 0; i < this.record_list.length; i++) {

        const initial_value = first_record[this.header_list[j].header_name];
        const current_value = this.record_list[i][this.header_list[j].header_name];
        // console.log(initial_value, current_value);
        if (initial_value == current_value) {
          this.header_list[j].highlighted = false;
        } else {
          this.header_list[j].highlighted = true;
          break;
        }
      }

    }

  }

  reset_comparison() {
    for (let i = 0; i < this.header_list.length; i++) {
      this.header_list[i].highlighted = false;
    }
  }

}
