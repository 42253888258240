import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SnotifyService } from 'ng-snotify';
import { debounceTime } from 'rxjs/operators';
import { CommonService } from 'src/app/services/common.service';
import { UtilityService } from 'src/app/services/utility.service';

declare var require: any
declare var $:any;

@Component({
  selector: 'app-dds-api-instance-logs',
  templateUrl: './dds-api-instance-logs.component.html',
  styleUrls: ['./dds-api-instance-logs.component.css']
})
export class DdsApiInstanceLogsComponent implements OnInit {

  @Input() version_id = null;
  @Input() logs_access_by = 'dds_version';
  @Output() api_logs_events: EventEmitter<any> = new EventEmitter<any>(true);


  logs_dt_config = {
    is_parent_logs: false,
    is_child_logs: false,
    is_child_node_json: false
  }
  api_instance_logs_json = {};

  // Api Instance
  api_instance_list = [];
  global_api_instance_list = [];
  api_instance_search_string: FormControl = new FormControl();
  api_instance_selected = [];
  api_instance_column = [
    // { prop: 'node_name', name: 'Activity Name'},
    // { prop: 'activity_type', name: 'Activity Type'},
    { prop: 'api_writer_type', name: 'Activity Performed From' },
    { prop: 'pipeline_name', name: 'Pipeline Name' },
    { prop: 'completion_status', name: 'Status' },
    { prop: 'record_fire_count', name: 'Total Records Fired' },
    { prop: 'start_time', name: 'Start Time' },
    { prop: 'end_time', name: 'End Time' },
  ]

  // Instance Logs
  api_writer_instance_id = null;
  api_instance_logs_list = [];
  global_api_instance_logs_list = [];
  api_instance_logs_search_string: FormControl = new FormControl();
  api_instance_logs_selected = [];
  api_instance_logs_column = [
    // { prop: 'api_writer_audit2api_writer', name: 'Api Writer ID'},
    // { prop: 'api_writer_audit2node_instance', name: 'Node ID'},
    { prop: 'create_datetime', name: 'Request Receive Time' },
    { prop: 'response_recv_time', name: 'Response Receive Time' },
    { prop: 'record_pointer', name: 'Record Number' }
  ]

  page = {
    total_elements: 0,
    page_number: 0,
    page_size: 10
  }

  xml_format_conf = {
    indentation: '  ',
    filter: (node) => node.type !== 'Comment',
    collapseContent: true,
    lineSeparator: '\n'
  };
  _input_json_conf = {
    api_type: null,
    payload: null
  };

  constructor(
    private _commonService: CommonService,
    private snotifyService: SnotifyService,
    private utilityService: UtilityService

  ) { }

  ngOnInit() {
    this.datatable_search();
    // this.setPage({ offset: 0 });
  }


  datatable_search() {
    this.api_instance_search_string.valueChanges
      .pipe(debounceTime(200))
      .subscribe(value => {
        this.search_in_dt(value, this.global_api_instance_list, this.api_instance_column, 'instance');
      });
    this.api_instance_logs_search_string.valueChanges
      .pipe(debounceTime(200))
      .subscribe(value => {
        this.search_in_dt(value, this.global_api_instance_logs_list, this.api_instance_logs_column, 'instance_logs');
      });
  }

  search_in_dt(val, global_data, column, current_case) {
    const data = global_data;
    const columns = column.map(col => col.prop);
    const filtered_data = this._commonService.filter_data_in_datatable(val, columns, data);
    switch (current_case) {
      case 'instance':
        this.api_instance_list = [...filtered_data];
        break;
      case 'instance_logs':
        this.api_instance_logs_list = [...filtered_data];
        break;
    }
  }

  back_button() {
    setTimeout(() => {
      this.api_instance_list = [...this.api_instance_list];
    }, 200);
    this.logs_dt_config.is_parent_logs = true;
    this.logs_dt_config.is_child_logs = false;
  }

  on_select_api_instance(event) {
    this.logs_dt_config.is_child_node_json = false;
    switch (event.type) {
      case 'from_pipeline':
        this.api_writer_instance_id = event.row.id;
        this.reset_page();
        this.setPage({ offset: 0 });
        break;
      case 'click':
        this.api_writer_instance_id = event.row.id;
        this.reset_page();
        this.setPage({ offset: 0 });
        break;
      case 'dblclick':
        break;
    }
  }

  on_select_api_instance_logs(event) {
    switch (event.type) {
      case 'click':
        this.clear_input_json_conf();
        // Need to change after service call
        const api_details = JSON.parse(JSON.stringify(event.row));
        if (api_details.output_json) {
          api_details['HTTP Status Code'] = api_details.output_json.status_code;
        }
        api_details['Data Sent'] = api_details.input_json;
        api_details['Data Received'] = api_details.output_json;
        this._input_json_conf.api_type = api_details.api_type;
        delete api_details.input_json;
        delete api_details.output_json;
        if (this.logs_access_by == 'dds_version') {
          delete api_details.id;
          delete api_details.api_writer_audit2pipe_ins;
          delete api_details.api_writer_audit2node_instance;
          delete api_details.api_writer_audit2api_writer;
          delete api_details.record_pointer;
          delete api_details.create_datetime;
          delete api_details.response_recv_time;
        }
        this.api_instance_logs_json = api_details;
        this.logs_dt_config.is_child_node_json = true;
        if(api_details.api_type === 'SOAP'){
          const xml = api_details['Data Sent'];
          const format = require('xml-formatter');
          this._input_json_conf.payload = format(xml, this.xml_format_conf);
        } else {
          this._input_json_conf.payload = api_details['Data Sent'];
        }
        console.log(this._input_json_conf)
        break;
      case 'dblclick':
        break;
    }
  }

  clear_input_json_conf() {
    this._input_json_conf.api_type = null;
    this._input_json_conf.payload = null;
  }

  show_modal() {
    $('#data_sent_viewer').modal({ backdrop: 'static' });
  }

  hide_modal() {
    $('#data_sent_viewer').modal('hide');
  }

  copy_payload_to_clipboard(){
    let textarea = document.createElement('textarea');
    textarea.value = this._input_json_conf.payload;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
    this.snotifyService.success('Data-Sent Copied To Clipboard.', this.utilityService.getConfig());
  }

  getRowClass(row) {
    // console.log(row)
    if (row.output_json && row.output_json.errCode && row.output_json.errCode !== 0) {
      return 'failed-api-color'; // css-class name to set bg-color
    }
    return;
  }

  get_api_instances(version_id, writer_id) {
    this.api_logs_events.emit({ type: 'click_tree', data: {} });
    this.api_instance_list = [];
    const payload = {
      activity2api_writer: writer_id,
      version_id: version_id
    };
    this._commonService.get_pipeline_instance_log_rec(payload).subscribe(res => {
      if (res.errCode == 0) {
        this.logs_dt_config.is_parent_logs = true;
        this.logs_dt_config.is_child_logs = false;
        this.api_instance_list = [...res.data];
        this.api_logs_events.emit({ type: 'api_logs_data_loaded', data: {} });
        this.global_api_instance_list = res.data;
        setTimeout(() => {
          this.api_instance_list = [...this.api_instance_list];
          this.api_logs_events.emit({ type: 'click_tree', data: {} });
        }, 500);
      }
    });
  }


  setPage(pageInfo) {
    this.page.page_number = pageInfo.offset;
    this.get_api_writer_audit_log();
  }

  reset_page() {
    this.page = {
      total_elements: 0,
      page_number: 0,
      page_size: 10
    };
  }

  get_api_writer_audit_log() {
    const payload = {
      'version_id': this.version_id,
      'api_writer_audit2node_instance': this.api_writer_instance_id,
      'page': this.page.page_number,
      'per_page': this.page.page_size
    };
    this._commonService.get_api_writer_audit_log(payload).subscribe(res => {
      if (res.errCode == 0) {
        this.page.total_elements = res.total_count;
        this.api_instance_logs_list = [...res.data];
        this.global_api_instance_logs_list = res.data;
        this.logs_dt_config.is_parent_logs = false;
        this.logs_dt_config.is_child_logs = true
        this.api_logs_events.emit({ type: 'api_audit_data_loaded', data: {} });
        if (this.logs_access_by == 'pipeline') {
          setTimeout(() => {
            this.api_instance_logs_list = [...this.api_instance_logs_list];
          }, 500);
        }
      }
    });
  }
}
