import { Component, OnInit, Input, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-side-container',
  templateUrl: './side-container.component.html',
  styleUrls: ['./side-container.component.css']
})
export class SideContainerComponent implements OnInit {
  @Input() container_width: string;
  @Input() container_title = '';
  @Input() container_id = 'default_id';
  @Input() placement = 'right';
  @Input() z_index = '9999';
  @Output() side_div_events = new EventEmitter();


  // @HostListener('click', ['$event'])
  // onDocumentClicked(event: MouseEvent) {
  //   console.log(event)
  //   if (!event) {
  //     this.side_div_events.emit({ 'type': 'clicked_outside', 'data': null });
  //     }
  //   }


  constructor(private _elementRef: ElementRef) {
  }

  ngOnInit() {
  }

  openContainer() {
    $(`#${this.container_id}`).css('border', '0' );
    $(`#${this.container_id}`).css('z-index', this.z_index );
    $(`#${this.container_id}`).width(this.container_width);
    $(`#${this.container_id}`).css('border', '2px solid #3C6281');
    this.container_placement();
    // $('#main').css('background-color', 'rgba(0,0,0,0.4)');
  }

  container_placement() {
    const direction_literal = {
      right: () => {
        $(`#${this.container_id}`).css({ 'right': '0px', 'left': '' });
      },
      left: () => {
        $(`#${this.container_id}`).css({ 'right': '', 'left': '0px' });
      },
    } // future cases top bottom tbd.
    direction_literal[this.placement](this);
  }

  closeContainer() {
    $(`#${this.container_id}`).css({ 'border': '0' });
    $(`#${this.container_id}`).width('0');
    // $('#main').css('background-color', 'white');
    this.side_div_events.emit({ type: this.container_id + '_close', data: {} });
  }

}
