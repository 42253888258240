import { Component, OnInit, Input , OnChanges} from '@angular/core';
import { ConfigService } from '../../config-builder/service/config.service';
declare var $:any;

@Component({
  selector: 'app-view-report-dashboard',
  templateUrl: './view-report-dashboard.component.html',
  styleUrls: ['./view-report-dashboard.component.css']
})
export class ViewReportDashboardComponent implements OnInit, OnChanges {
	@Input() dashboard_id?: Number = -1;
	dashboard_data:any = {};
	show_all = false;
	@Input() hidden_filter_object?:any = {};

	constructor(
		public _configService:ConfigService
	) { }

	ngOnInit() {
	}

	ngOnChanges() {
		if(this.dashboard_id != -1){
			this._configService.get_lg_report_dashboard('/' + this.dashboard_id).subscribe(res => {
				this.dashboard_data = res;
			});
		}
	}

	toggle_row(row_index){
		$("#"+row_index).toggle();
		this.dashboard_data['row_def'][row_index].toggle_up = !this.dashboard_data['row_def'][row_index].toggle_up;
	}

	toggle_all_rows(show){
		this.show_all = !show;
		for(var i=0; i<this.dashboard_data['row_def'].length; i++){
			if(show){
				$("#"+i).show();
			} else {
				$("#"+i).hide();
			}
		}
	}
}
