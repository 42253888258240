import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';

import {SnotifyService} from 'ng-snotify';
import { UtilityService } from '../../../services/utility.service';
@Component({
  selector: 'app-view-dashboard-main',
  templateUrl: './view-dashboard-main.component.html',
  styleUrls: ['./view-dashboard-main.component.css']
})
export class ViewDashboardMainComponent implements OnInit {

	// Variables Initialised
		dashboard_id = -1;
    view_configure = true;
    hidden_filter_input = '';
    hidden_filter_object:any = {};

  constructor(
  	private _activatedRouter: ActivatedRoute,
    private _router: Router,
    private snotifyService: SnotifyService,
    private utilityService: UtilityService
	) {
  	this._activatedRouter.params.subscribe(params => {
      this.dashboard_id = params.dash_id;
      
      var temp = this._router.url.split("/");
      if(temp[1] == 'app'){
        this.view_configure = false;
        this.hidden_filter_object = {"@user_name": localStorage.getItem('user_name')}
      }
    });
   
  }

  ngOnInit() {
  }

  change_dash_filter () {
    if(this.IsJsonString(this.hidden_filter_input)) {
      this.hidden_filter_object = JSON.parse(this.hidden_filter_input);
    } else {
      this.snotifyService.error('Please enter a valid JSON', this.utilityService.getConfig());
    }
    
  }

  IsJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

}
