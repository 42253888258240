import { Component, OnInit } from '@angular/core';
import * as Vis from 'vis';
import {SnotifyService} from 'ng-snotify';
import { UtilityService } from '../../../services/utility.service';

declare var $: any;

@Component({
  selector: 'app-nz-flow',
  templateUrl: './nz-flow.component.html',
  styleUrls: ['./nz-flow.component.css']
})
export class NzFlowComponent implements OnInit {

  node_shape: any = {
    video: {
      shape: 'ellipse',
      color: 'blue'
    },
    assessment: {
      shape: 'box',
      color: 'purple'
    },
    group: {
      shape: 'hexagon',
      color: 'orange'
    }
  };

  colors: any  = {
    blue : {
      border: '#20e8e9',
      background : '#acfffc'
    },
    orange : {
      border: '#e96c1c',
      background : '#ffa95d'
    },
    purple: {
      border: '#d60de9',
      background : '#fc90f2'
    },
    green : {
      border: '#04e900',
      background: '#b4fc8f'
    },
    red: {
      border: '#e9292d',
      background: '#ff7b7e'
    }
  }

  node_id = -1;
  node_label = '';
  node_type = '';
  new_node: boolean;
  node_data_ID = '';
  id_exist: boolean;
  start_id = 0;
  end_id = 9999;
  disable_node_id_list = [this.start_id, this.end_id];
  position: any = {};

  nodes = new Vis.DataSet([{
    id: this.start_id,
    label: 'Start',
    type: 'start',
    shape: 'circle',
    margin: 10,
    borderWidth: 1,
    color: this.colors.green,
    font: { color: '#000' },
    physics: false,
    x: -400,
    y: -200
  },
  {
    id: this.end_id,
    label: 'End',
    type: 'end',
    shape: 'circle',
    color: {
      border: '#e9292d',
      background: '#ff7b7e'
    },
    margin: 10,
    physics: false,
    font: { color: '#000' },
    x: 400,
    y: 200
  }
  ]);
  edges = new Vis.DataSet([]);


  constructor(
    private snotifyService: SnotifyService,
    private utilityService: UtilityService
  ) { }

  ngOnInit() {

    var self = this;
    const nodes = this.nodes;
    const edges = this.edges;

    // create an array with edges

    // create a network
    const container = document.getElementById('mynetwork');

    // provide the data in the Vis format
    const data = {
      nodes: nodes,
      edges: edges
    };
    const options = {
      // autoResize: true,
      nodes: {
        shape: 'circle',
        color: {
          highlight: {
            border: '#262626',
            background: '#d1d0d3'
          }
        },
      },
      edges: {
        arrows: {
          to: { enabled: true }
        },
        color: {
          color: '#333333',
          inherit: false
        },
        smooth: {
          enabled: false
        }
      },
      physics: {
        barnesHut: {
          gravitationalConstant: -3250,
          springLength: 100
        }
      },
      manipulation: {
        enabled: true,
        initiallyActive: true,
        addNode: function (nodeData, callback) {
          $('.edit-node-data').show();
          self.add_new_node(nodeData);

        },
        addEdge: function (edgeData, callback) {
          const data = self.restrict_edges(edgeData);
          if (data != '') {
            edges.update(data);
          } else {
            // alert("Cannot add");
            this.snotifyService.error('Cannot add!', this.utilityService.getConfig());
          }
        },
        editNode: function (nodeData, callback) {
          // this.edit_pop_up();
          $('.edit-node-data').show();
          // nodeData.label = this.node_label;

          // nodeData.label = 'hello world';
          // // nodeData.id =
          // callback(nodeData);
        },
        editEdge: true,
        deleteNode: true,
        deleteEdge: true
      }
    };

    // initialize your network!
    const network = new Vis.Network(container, data, options);

    network.on('doubleClick', function (properties) {

      const nodeID = properties.nodes[0];
      const nodeData = nodes.get(nodeID);
      const disable = self.disable_node(nodeData.id);
      if (!disable) {
        $('.edit-node-data').show();
        self.add_node_by_double_click(nodeData,properties.pointer.canvas);
        // self.node_id = nodeData.id;
        // self.node_label = nodeData.label;
        // self.node_type = nodeData.type;
        // self.position = properties.pointer.canvas;
        // if (self.node_id == undefined || self.node_id == -1) {
        //   self.new_node = true;
        // } else {
        //   self.new_node = false;
        // }
      }
    });
  }

  add_new_node(node_data) {
    this.node_id = node_data.id;
    this.position = {
      x: node_data.x,
      y: node_data.y
    };
  }

  disable_node(id) {
    let disable = false;
    for ( let i = 0; i < this.disable_node_id_list.length; i++){
      if (this.disable_node_id_list[i] == id) {
        disable = true;
        break;
      }
    }
    return disable;
  }

  add_node_by_double_click(data, position) {
    this.node_id = data.id;
    this.node_label = data.label;
    this.node_type = data.type;
    this.position = position;
    if (this.node_id == undefined || this.node_id == -1) {
      this.new_node = true;
    } else {
      this.new_node = false;
    }
  }

  restrict_edges(edge_data) {
    // tslint:disable-next-line:max-line-length
    if (edge_data.to == this.start_id || edge_data.to == edge_data.from || edge_data.from == this.end_id || (edge_data.to == this.end_id && edge_data.from == this.start_id))  {
      return 0;
    } else {
      return edge_data;
    }
  }

  close() {
    $('.edit-node-data').hide();
    this.id_exist = false;
  }

  fixed_nodes(node_id_list) {
    for (let i = 0 ; i < node_id_list.length; i++) {
      for (let j = 0; j < this.nodes.length; j++) {
        if (node_id_list[i] == this.nodes[i].id) {
          this.nodes[i]['fixed'] = {
            x: true,
            y: true
          };
        }
      }
    }
  }

  node_color(node_type){
    return this.colors[this.node_shape[node_type]['color']];
    // switch (node_type) {
    //   case 'video':
    //     current_color = this.colors.blue;
    //   break;
    //   case 'assessment':
    //     current_color = this.colors.purple;
    //   break;
    // }
  }

  update_node_data() {
    this.id_exist = false;
    this.nodes.map((node) => {
      if (node.id == this.node_id && this.new_node) {
        this.id_exist = true;
      }
    });


    if (!this.id_exist) {
      $('.edit-node-data').hide();
      let current_shape = '';
      let current_color = {};
      current_shape = this.node_shape[this.node_type]['shape'];
      current_color = this.node_color(this.node_type)
      // console.log(current_color);
      this.nodes.update({
        margin: 10,
        id: this.node_id,
        label: this.node_label,
        type: this.node_type,
        shape: current_shape,
        color: current_color,
        x: this.position.x,
        y: this.position.y
      });
      this.node_id = null;
      this.node_label = '';
      this.node_type = '';
    }

    // callback(nodeData);
  }

  right_click_options() {
    // $('mynetwork').


    $(document).bind("contextmenu", function (event) {
      event.preventDefault();
      // if (event.toElement.id == 'myCanvas') {
      $('.custom-menu').finish().toggle(100).css({
        top: event.pageY + 'px',
        left: event.pageX - 220 + 'px'
      });
      // }
    });

    $(document).bind('mousedown', function (e) {
      if (!$(e.target).parents('.custom-menu').length) {
        $(".custom-menu").hide(100);
      }
    });

    $(".custom-menu li").click(function () {
      switch ($(this).attr('data-action')) {

        case 'circle': alert('circle'); break;
        case 'box': alert('box'); break;
        case 'diamond': alert('diamond'); break;
      }

      $(".custom-menu").hide(100);
    });
  }
}
