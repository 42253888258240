import { Directive, ElementRef, HostListener, Input, TemplateRef, ViewContainerRef, AfterViewInit } from '@angular/core';
import { SharedService } from './../services/shared.service';
declare var $:any;

@Directive({
  selector: '[appNzRole]'
})
export class NzRoleDirective {

  constructor(
    private el: ElementRef,
    private _sharedService: SharedService,
  ) { }

  role_map = {};

  fetch_role(key){
    var param = key.split("__");
    // console.log(this._sharedService.roles_map);
    // return this._sharedService.roles_map[key];
    this.role_map = this._sharedService.get_roles_map();
    // return this.role_map[key];
    if(this.role_map[param[0]] != undefined && this.role_map[param[0]][param[1]] != undefined){
      return this.role_map[param[0]][param[1]];
    } else {
      return 1;
    }
  }

  @Input('appNzRole') appNzRole: string;

  private apply_role(role){
    // show = 1
    // disabled = -1
    // hidden = 0

    role = this.fetch_role(role);

    // console.log(this.el.nativeElement);
    if(role == -1){
      this.el.nativeElement.disabled = true;
    } else if(role == 0) {
      this.el.nativeElement.remove();
    } else {
      //  show - other conditions handled by *ngIf
    }

  }

  ngAfterViewInit() {
    this.apply_role(this.appNzRole);
  }

}
