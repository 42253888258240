import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

import { ReportRoutingModule } from './report-routing.module';
import { ViewReportComponent } from './view-report/view-report.component';
import { ViewDrillReportComponent } from './view-drill-report/view-drill-report.component';
import { ViewReportDashboardComponent } from './view-report-dashboard/view-report-dashboard.component';
// import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ConfigBuilderModule } from '../config-builder/config-builder.module';
import { LargeReportComponent } from './large-report/large-report.component';
import { ReportManagerComponent } from './report-manager/report-manager.component'
import { ViewGroupReportComponent } from './view-group-report/view-group-report.component';
@NgModule({
  declarations: [ViewReportComponent, ViewReportDashboardComponent,ViewDrillReportComponent, LargeReportComponent, ReportManagerComponent,ViewGroupReportComponent],
  imports: [
    CommonModule,
    ReportRoutingModule,
    // AngularFontAwesomeModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    ConfigBuilderModule,
  ],
  exports:[
  	ViewReportComponent,
    ViewDrillReportComponent,
    ViewReportDashboardComponent,
    LargeReportComponent,
    ReportManagerComponent,
    ConfigBuilderModule,
    ViewGroupReportComponent
  ]
})
export class ReportModule { }
