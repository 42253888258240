import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// import { QueryBuilderComponent } from './components/query-builder/query-builder.component';
// import { ViBuilderComponent } from './components/vi-builder/vi-builder.component';
import { ViDrillFilterComponent } from './modules/dashboard/vi-drill-filter/vi-drill-filter.component';
// import { CreateDashboardComponent } from './components/create-dashboard/create-dashboard.component';
// import { CreateDashboardGroupComponent } from './components/create-dashboard-group/create-dashboard-group.component';
// import { CustomDashboardComponent } from './components/custom-dashboard/custom-dashboard.component';
// import { ViewDashboardMainComponent } from './components/shared/view-dashboard-main/view-dashboard-main.component';
// import { CommunicationTemplateComponent } from './components/communication-template/communication-template.component';
// import { LoginComponent } from './components/user-app/login/login.component';
// import { HomepageComponent } from './components/user-app/homepage/homepage.component';
// import { UserDashboardGroupComponent } from './components/user-dashboard-group/user-dashboard-group.component';
import { NzFlowComponent } from './components/admin-app/nz-flow/nz-flow.component';
import { Resolver } from './resolver';
import { AdminLogoutComponent } from './layouts/admin-logout/admin-logout.component';
import { UserLogoutComponent } from './layouts/user-logout/user-logout.component';
import { AdminLoginComponent } from './layouts/admin-login/admin-login.component';
import { UserLoginComponent } from './layouts/user-login/user-login.component';

const routes:Routes = [
  {
    path: 'lg_admin/login',
    redirectTo: '/admin_lg/login',
    pathMatch: 'full'
  },
  {
    path: 'app/login',
    redirectTo: '/app_lg/login',
    pathMatch: 'full'
  },
  {
    path: '',
    redirectTo: '/app_lg/login',
    pathMatch: 'full'
  },
  {
    path: '',
    component: AdminLogoutComponent,
    children: [
      {
        path: 'admin_lg',
        loadChildren:'./modules/admin-auth/admin-auth.module#AdminAuthModule'
      },
    ]
  },
  {
    path: '',
    component: UserLogoutComponent,
    children: [
      {
        path: 'app_lg',
        loadChildren:'./modules/app-auth/app-auth.module#AppAuthModule'
      },
    ]
  },
  {
    path: '',
    resolve:{ roles_map: Resolver },
    component: AdminLoginComponent,
    children: [
      {
        path:'all-charts-status-map',
        loadChildren:'./modules/charts-config/charts-config.module#ChartsConfigModule'
      },
      {
        path: 'dds',
        loadChildren: './dds/dds.module#DDSModule',
      },
      {
        path: 'pipeline',
        loadChildren: './modules/pipeline/pipeline.module#PipelineModule',
      },
      {
        path: 'composite_widget',
        loadChildren:'./modules/composite-widget/composite-widget.module#CompositeWidgetModule'
      },
      {
        path: 'roles',
        loadChildren:'./modules/project-roles/project-roles.module#ProjectRolesModule',
      },
      {
        path: 'holiday',
        loadChildren:'./modules/holiday-list/holiday-list.module#HolidayListModule',
      },
      {
        path: 'shared',
        loadChildren:'./modules/shared/shared.module#SharedModule'
      },
      {
        path: 'lg_admin',
        loadChildren:'./modules/admin-app/admin-app.module#AdminAppModule'
      },
    ]
  },
  {
    path: '',
    component: UserLoginComponent,
    children: [
      {
        path: 'app',
        loadChildren:'./modules/user-app/user-app.module#UserAppModule'
      },
    ]
  },
  { path: '**', redirectTo: '/app_lg/login' }
]



@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    Resolver
  ],
})
export class AppRoutingModule { }
