import { Component, OnInit, Input, Output, OnDestroy, EventEmitter, ViewEncapsulation, OnChanges, SimpleChanges, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { ConfigService } from '../../config-builder/service/config.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { SnotifyService } from 'ng-snotify';
import * as FusionCharts from '../../../../assets/js/fusioncharts/fusioncharts';

declare var $: any;


@Component({
	selector: 'app-vi-drill-filter',
	templateUrl: './vi-drill-filter.component.html',
	styleUrls: ['./vi-drill-filter.component.css'],
	encapsulation: ViewEncapsulation.None,
})
export class ViDrillFilterComponent implements OnInit, OnChanges, OnDestroy {

	// Variables Initialised
	@ViewChild('chart_container') chart_container: ElementRef;
	@Input() id?: Number = 2;
	@Input() chart_id?: String = 'row_col';
	@Input() input_data? = [];
	@Input() allow_configuration?: boolean = true;
	@Input() hidden_filter_object?: any = {};
	@Input() visible_filter_object?: any = {};
	@Input() refresh_timer?: any = -1;
	@Input() pause_refresh_timer?: any = true;
	@Output() ext_btn_event: EventEmitter<any> = new EventEmitter<any>();
	dropdownSettings: IDropdownSettings = {
		singleSelection: false,
		selectAllText: 'Select All',
		unSelectAllText: 'UnSelect All',
		itemsShowLimit: 3,
		allowSearchFilter: true
	};
	show_lg_data_download = true
	show_lg_data_recs = true
	ct = ''
	qry_con = -1;
	query_id = -1;
	query = '';
	filter_data = [];
	hidden_filter_data = [];
	drilldown_order_data = [];
	unused_drilldown_order_data = [];
	height = 350;
	type = "";
	data = {};
	events: any;
	chart_obj: any;
	showSpinner: boolean = true;
	chart_list = [];
	selected_templates = [];
	selected_temp_index = -1;
	validate_email_id: boolean = false;
	drill_list = [];
	// For data filtering in modal
	lg_data_recs_order = [];
	data_header = [];
	data_records = [];
	selected_data_object = {};
	selected_category = '';
	selected_dataset = '';
	chart_series_level_map = {
		"column2d": 1,
		"column3d": 1,
		"bar2d": 1,
		"bar3d": 1,
		"line": 1,
		"area2d": 1,
		"pyramid": 1,
		"pie2d": 1,
		"pie3d": 1,
		"doughnut2d": 1,
		"doughnut3d": 1,
		"mscolumn2d": 10,
		"mscolumn3d": 10,
		"msbar2d": 10,
		"msbar3d": 10,
		"msline": 10,
		"stackedcolumn2d": 10,
		"stackedcolumn3d": 10,
		"stackedbar2d": 10,
		"stackedbar3d": 10,
		"radar": 10,
		"scrollcolumn2d": 10,
		'scrollbar2d': 10,
		'scrollarea2d': 10,
		'scrollline2d': 10,
		"scrollstackedcolumn2d": 10,
		"scrollstackedbar2d": 10,
		"scrollcombi2d": 10,
		"scrollcombidy2d": 10
	};
	data_view_config: any = {};
	show_refresh = true
	show_view_filter = false
	chart_render = false;
	allow_refresh = false;
	interval: any = {}
	refreshing_chart = false;
	parent_node = ""
	present_in_outer_level = true;
	drill_col_list = [];
	use_different_charts = false;
	query_column_chart_map = [];
	use_different_frames = false;
	temp_chart_object = null

	constructor(
		private _configService: ConfigService,
		private snotifyService: SnotifyService,
		private _renderer: Renderer2
	) {

		this.events = {
			dataPlotClick: (eventObj, dataObj) => {
				this.chart_click(eventObj, dataObj);
			},
			beforeLinkedItemClose: (res) => {
				if (this.drilldown_order_data.length > 0) {
					if (this.use_different_frames) {
						this.drill_list.splice(res.data.level)
						this.clear_chart_frame(res.data.level)
					}
					else {
						this.drill_list.splice(-1, 1);
					}
					if (this.drill_list.length == 0 && this.allow_refresh) {
						this.pause_refresh_timer = false;
					}
				}
			},
			initialized: (event) => {
			},
			rendered: () => {
				this.chart_render = true;
			}
		};
	}

	clear_chart_frame(index){
		for (let i = index + 2; i < this.drill_col_list.length; i++) {
			document.getElementById('chart_container_' + this.chart_id + '_' + this.id + '_' + i).innerHTML = '';
		}
	}

	ngOnInit() {
		if (this.id != -1) {
			this.render_chart();

		}
	}

	initialized($event) {
		this.temp_chart_object = $event.chart; // saving chart instance
	}
	chart_loaded() {
	}

	change_chart_type(type, action) {
		this.type = type;
		this.remove_chart_frames();
		this.chart_obj = new FusionCharts({
			type: this.type,
			id: 'unique_id_' + this.chart_id + '_' + this.id,
			renderAt: "chart_container_" + this.chart_id + '_' + this.id + '_0',
			width: "100%",
			height: this.height,
			dataFormat: "json",
			dataSource: this.data,
			events: this.events,
		}).render();
		if ((this.use_different_charts || this.use_different_frames)) {
			for (var i = 1; i < this.drill_col_list.length; i++) {
				let col_name = this.drill_col_list[i].field_name
				let col_name_index = Number(col_name[col_name.length - 1])
				let chart_config = {
					width: "100%",
					height: this.height,
					overlayButton: {
						show: true
					},
					id: 'linked-chart-' + this.chart_id + '_' + this.id + '_' + (col_name_index - 1)
				}
				if (this.use_different_frames) {
					const newDiv = document.createElement('div');
					newDiv.id = 'chart_container_' + this.chart_id + '_' + this.id + '_' + (col_name_index - 1)
					newDiv.classList.add('mt-4')
					this.chart_container.nativeElement.appendChild(newDiv);
					chart_config['renderAt'] = newDiv.id;
				}
				if (this.use_different_charts) {
					let record = this.query_column_chart_map.find(record => record.col_name === col_name);
					chart_config['type'] = record ? record.chart_name : type;
				}
				this.chart_obj.configureLink(chart_config, col_name_index - 2);
			}
		}
		this.pause_refresh_timer = false;
		this.chart_render = false;
		this.drill_list = [];
		this.showSpinner = false;
		this.refreshing_chart = false;
	}

	get_chart_list(type) {
		this.chart_list = [];
		var data = [
			["heatmap"],
			["pie2d", "pie3d", "doughnut2d", "doughnut3d"],
			["column2d", "column3d", "bar2d", "bar3d", "line", "area2d"],/*, "pyramid"*/
			["mscolumn2d", "mscolumn3d", "msbar2d", "msbar3d", "msline", "stackedcolumn2d", "stackedcolumn3d", "stackedbar2d", "stackedbar3d", "radar", 'scrollstackedcolumn2d', 'scrollstackedbar2d'],
			['scrollcolumn2d', 'scrollbar2d', 'scrollarea2d', 'scrollline2d'],
		]
		for (var i = 0; i < data.length; i++) {
			for (var j = 0; j < data[i].length; j++) {
				if (data[i][j] == type) {
					this.chart_list = data[i];
					return;
				}
			}
		}
	}

	ngOnChanges(changes: SimpleChanges) {
		if (this.id != -1 && changes['visible_filter_object'] != undefined && changes['visible_filter_object']['previousValue'] != undefined) {
			this.override_filters();
		}
		if (changes.pause_refresh_timer != undefined && changes.pause_refresh_timer.currentValue != undefined && changes.pause_refresh_timer.currentValue == -1) {
			this.clear_refresh_interval();
		}
	}

	chart_click(eventObj, dataObj) {
		if (this.use_different_frames) {
			let index = 0;
			if (eventObj.sender.id.startsWith('linked-chart')) {
				// Get the chart frame index from the id of sender
				index = Number(eventObj.sender.id[eventObj.sender.id.length - 1]);
			}
			this.drill_list.splice(index)
			if (this.drill_col_list.length > index + 2) {
				this.clear_chart_frame(index)
			}
		}
		this.selected_category = dataObj.categoryLabel;
		this.selected_dataset = dataObj.datasetName;
		if (this.selected_dataset == undefined) {
			this.selected_dataset = '';
		}
		this.selected_data_object = {
			"category": this.selected_category,
			"dataset": this.selected_dataset
		};
		if (this.allow_refresh && this.type == 'sunburst') {
			// todo
			// We can also show drill list in sunburst
			//we can use lg_data_recs also
			this.check_drill_level_in_sunburst_chart_type(dataObj);
		}

		if (1 || this.drilldown_order_data.length + this.unused_drilldown_order_data.length == 0) {
			// var data = eventObj.sender.getJSONData();
			let data = this.data
			for (let i = 0; i < this.drill_list.length; i++) {
				data = data['linkeddata'].find(record => record.id == this.drill_list[i]);
				data = data['linkedchart'];
			}
			var series = this.chart_series_level_map[this.type];
			if (series == 1) {	// single series
				var index = dataObj.dataIndex;
				this.data_records = data['data'][index]['lg_data_recs'];
			} else if (series == 10) {	// multi series
				var index = dataObj.dataIndex;
				var index2 = dataObj.datasetIndex;
				this.data_records = data['dataset'][index2]['data'][index]['lg_data_recs'];
			}
			if (this.data_records && this.data_records.length) {
				$('#datatable_' + this.chart_id).modal();
				if (this.allow_refresh) {
					this.pause_refresh_timer = true;
				}
				if (this.lg_data_recs_order.length) {
					this.data_header = this.lg_data_recs_order;
				} else {
					this.data_header = Object.keys(this.data_records[0]);
				}
			}
			if ($.inArray("email_id", this.data_header) !== -1) {
				this.validate_email_id = true;
			}
		}
		if (this.drilldown_order_data.length > 0 && dataObj.link != undefined) {
			if (this.allow_refresh) {
				this.pause_refresh_timer = true;
			}
			this.drill_list.push(this.selected_data_object["category"])
		}
	}

	check_drill_level_in_sunburst_chart_type(data: any) {
		if ((data.nodeParentId == "" || data.nodeParentId == this.parent_node) && !this.present_in_outer_level) {
			this.pause_refresh_timer = false;
			this.present_in_outer_level = true;
		} else if (this.present_in_outer_level && data.childrenCount == 0) {
			this.pause_refresh_timer = false;
		} else if (data.nodeParentId == "" && this.present_in_outer_level) {
			this.pause_refresh_timer = false;
		}
		else {
			this.present_in_outer_level = false;
			this.pause_refresh_timer = true;
		}
	}

	download_chart_report() {

		var header = [];
		for (var i = 0; i < this.data_header.length; i++) {
			header.push({ "col_name": this.data_header[i], "disp_name": this.data_header[i] });
		}
		var payload = { "data_records": this.data_records, "data_header": header };
		this._configService.download_chart_report(payload).subscribe(res => {

			var payload2 = { "file_type": "report", "file_name": res.filename };
			this._configService.download_report(payload2).subscribe(res2 => {
				window.open(res2.download_link, "_blank");
			});

		});
	}

	public removeItem(item: any, list: any[]): void {
		list.splice(list.indexOf(item), 1);
	}
	ext_buttons = []
	async render_chart() {
		// Fetched VI data - Filters and query id
		var res = await this._configService.get_vinsights_by_id({ 'id': this.id }).toPromise();
		if (res.data.email_def != undefined && res.data.email_def != null) {
			this.selected_templates = res.data.email_def.selected_temp;
		}
		this.height = 350;
		if (res.data.option_def.height != undefined && res.data.option_def.height != null) {
			this.height = res.data.option_def.height;
		}
		if (res.data.option_def.show_refresh == undefined || res.data.option_def.show_refresh == null) {
			this.show_refresh = true;
		} else {
			this.show_refresh = res.data.option_def.show_refresh
		}
		if (res.data.option_def.show_view_filter == undefined || res.data.option_def.show_view_filter == null) {
			this.show_view_filter = false;
		} else {
			this.show_view_filter = res.data.option_def.show_view_filter
		}
		if (res.data.option_def.show_lg_data_download == undefined) {
			this.show_lg_data_download = true;
		} else {
			this.show_lg_data_download = res.data.option_def.show_lg_data_download
		}
		if (res.data.option_def.show_lg_data_recs == undefined) {
			this.show_lg_data_recs = true;
		} else {
			this.show_lg_data_recs = res.data.option_def.show_lg_data_recs
		}
		if (res.data.option_def.use_different_charts) {
			this.use_different_charts = true;
		}
		if (res.data.option_def.use_different_frames) {
			this.use_different_frames = true;
		}
		if (res.data.option_def.query_column_chart_map) {
			this.query_column_chart_map = res.data.option_def.query_column_chart_map;
		}
		if (this.refresh_timer && this.refresh_timer != -1 && !this.use_different_frames) {
			this.allow_refresh = true;
			this.pause_refresh_timer = false;
			this.start_refresh_timer();
		}
		else if (!(res.data.option_def.auto_refresh_time && this.refresh_timer)) {
			this.allow_refresh = false;
		} else {
			this.refresh_timer = res.data.option_def.auto_refresh_time
			this.allow_refresh = true;
			this.pause_refresh_timer = false;
			this.start_refresh_timer();
		}
		this.hidden_filter_data = (res.data.option_def.hidden_param_val == undefined) ? [] : res.data.option_def.hidden_param_val;
		this.lg_data_recs_order = [];
		if (res.data.option_def.lg_data_recs_order != undefined && res.data.option_def.lg_data_recs_order != '') {
			this.lg_data_recs_order = res.data.option_def.lg_data_recs_order.split(";");
		}
		this.filter_data = res.data.option_def.param_val;
		this.type = res.data.option_def.type;
		this.query_id = res.data.query_id;
		this.get_chart_list(this.type);
		let filter_object = { ...this.hidden_filter_object, ...this.visible_filter_object };
		let payload = {
			"hidden_filter_data": this.hidden_filter_data,
			"filter_data": this.filter_data,
			"hidden_filter_object": filter_object
		};

		this.data_view_config = res.data.data_view_config;
		if (this.data_view_config && this.data_view_config.external_buttons) {
			this.ext_buttons = this.data_view_config.external_buttons
		} else {
			this.ext_buttons = []
		}
		let res2 = await this._configService.process_query_filters(payload).toPromise();
		if (res2.errCode == 0) {
			this.filter_data = res2.msg.filter_data;
			this.hidden_filter_data = res2.msg.hidden_filter_data
			this.override_filters();
		}
	}

	start_refresh_timer() {
		if (!this.interval["unique_id_" + this.chart_id + "_" + this.id]) {
			this.interval["unique_id_" + this.chart_id + "_" + this.id] = setInterval(() => {
				if (!this.pause_refresh_timer) {
					this.refresh_chart_data(true, 'auto_refresh');
				}
			}, this.refresh_timer * 1000);
		}
	}

	async override_filters() {
		let data = this._configService.process_and_override_filter(this.hidden_filter_data, this.filter_data, this.hidden_filter_object, this.visible_filter_object)
		this.hidden_filter_data = data["hidden_filter_data"]
		this.filter_data = data["filter_data"]
		var res1 = await this._configService.get_query_by_id({ 'id': this.query_id }).toPromise();
		this.query = res1.data.qry_string;
		this.qry_con = res1.data.query2connection;

		var test_payload = {
			"count": 0,
			"hidden_param_list": this.hidden_filter_data,
			"paramList": this.filter_data,
			"qid": this.query_id,
			"conid": this.qry_con
		};

		var columns_list = await this._configService.test_query_by_id(test_payload).toPromise();
		{
			if (columns_list.errCode == 0) {
				let data2 = this._configService.get_drill_data(columns_list)
				this.drill_col_list = data2;
				this.drilldown_order_data = data2
				this.unused_drilldown_order_data = [];
				this.refresh_chart_data(false, 'empty_and_update_data');
			}
		}
	}

	refresh_chart_data(apply_filter = false, action = '') {
		if (this.refreshing_chart && !this.chart_render) {
			return;
		}
		this.refreshing_chart = true;
		if (action != 'auto_refresh') {
			this.showSpinner = true;
		}
		if (action == 'empty_and_update_data') {
			this.data = {}
		}
		var drill_order = [];
		for (var i = 0; i < this.drilldown_order_data.length; i++) {
			drill_order.push(this.drilldown_order_data[i]['field_name']);
		}
		var filter = this.filter_data;
		if (this.input_data.length) {
			filter = this.input_data;
		}
		if (apply_filter) {
			for (var i = 0; i < filter.length; i++) {
				if (filter[i]['filterType'] == "Dynamic Date") {
					filter[i]['filterType'] = "Date";
				}
			}
		}

		this.view_filter_data = JSON.parse(JSON.stringify(filter));
		var payload = {
			"viid": this.id,
			"filter_data": filter,
			"hidden_filter_data": this.hidden_filter_data,
			"drilldown_order_data": drill_order
		};
		this._configService.get_vi_param(payload).subscribe(res => {
			if (!res.errCode) {
				if (this.ct == '') {
					this.ct = res.msg.type
				}
				if (res.msg.dataSource != undefined) {
					if (action == 'auto_refresh') {
						if (res.msg.dataSource['chart']) {
							res.msg.dataSource['chart'].animation = 0;
						}
					}
					if (this.type == 'sunburst' && res.msg.dataSource.data) {
						this.parent_node = res.msg.dataSource['data'][0]['id'];
					}
					this.data = res.msg.dataSource;
					// if($("#unique_id_"+this.chart_id+"_"+this.id).length) {
					// 	this.chart_obj.render();
					// }
				}
				this.change_chart_type(this.ct, action);
			}
		});

		// Single series data
		// this.data = {chart: { },data: [{value: 300},{value: 400},{value: 500},{value: 600},{value: 700}]}

		// MS data
		// this.data = {"data":[],"chart":{"chartRightMargin":0,"showalternatehgridcolor":"0","yAxisMaxValue":"","rotateValues":"0","legendshadow":"0","captionpadding":"30","chartLeftMargin":0,"chartBottomMargin":0,"bgcolor":"#FFFFFF","theme":"fint","canvasbordercolor":"CCCCCC","showcanvasborder":"0","subCaption":"Fund Scheme AD MS","linethickness":"3","plotgradientcolor":"","canvasBgAlpha":0,"xAxisName":"X Axis","showValues":"1","yaxisvaluespadding":"15","divlinecolor":"CCCCCC","canvasborderthickness":"1","chartTopMargin":0,"caption":"Fund Scheme AD MS","legendborderalpha":"0","showborder":"0","decimals":"2","yAxisName":"Count","canvasborderalpha":"0"},"categories":[{"category":[{"label":"BONITAS "},{"label":"FEDHEALT"},{"label":"        "},{"label":"MEDIHELP"},{"label":"DISCOVER"}]}],"dataset":[{"seriesname":"26 Count","data":[{"value":224},{"value":12},{"value":0},{"value":27},{"value":158}]},{"seriesname":"25 Count","data":[{"value":2203},{"value":45},{"value":3},{"value":6},{"value":105}]},{"seriesname":"51 Count","data":[{"value":1136},{"value":280},{"value":0},{"value":64},{"value":372}]}]};
	}

	chart_clear() {
		this.data = {};
	}

	sendMail = () => {
		var payload = {
			"template": this.selected_templates[this.selected_temp_index],
			"data_records": this.data_records
		}

		this._configService.send_mail_from_vi(payload).subscribe(
			res => {
				this.snotifyService.success("Email Sent Successfully")
			},
			err => {
				//do something if err
			}
		)
	}

	remove_chart_frames(){
		if(this.chart_obj){
			this.chart_obj.configureLink({});
			this.chart_obj.dispose();
			this.chart_obj = null;
		}
		for (let i = 0; i < this.drill_col_list.length; i++) {
			const divToRemove = this.chart_container.nativeElement.querySelector(`#chart_container_${this.chart_id}_${this.id}_${i}`);
			if (divToRemove) {
				if(i == 0){
					divToRemove.innerHTML = '';
				}else{
					this._renderer.removeChild(this.chart_container.nativeElement, divToRemove);
				}
			}
		}
	}

	close_modal() {
		$("#" + "unique_id_" + this.chart_id + "_" + this.id).modal("hide");
		this.chart_clear()
		this.clear_refresh_interval();
		this.remove_chart_frames();
	}

	ngOnDestroy() {
		this.clear_refresh_interval();
		if(this.chart_obj){
			this.chart_obj.dispose();
			this.chart_obj = null;
		}
		this.remove_chart_frames();
	}

	clear_refresh_interval() {
		if (this.interval["unique_id_" + this.chart_id + "_" + this.id]) {
			clearInterval(this.interval["unique_id_" + this.chart_id + "_" + this.id]);
			this.interval["unique_id_" + this.chart_id + "_" + this.id] = null;
		}
	}

	view_filter_data = []

	config_event(event) {
		switch (event.case) {
			case "apply_filter":
				this.refresh_chart_data(true, 'empty_and_update_data');
				break;
			case "close_filter_modal":
				if (this.allow_refresh && this.drill_list.length == 0 && this.present_in_outer_level) {
					this.pause_refresh_timer = false;
				}
				break;
			case "open_filter_modal":
				this.pause_refresh_timer = true;
				break;
		}
	}

	modal_event(event) {
		switch (event.case) {
			case "send_mail":
				this.selected_temp_index = event.value
				this.sendMail()
				// code...
				break;
			case "download_chart_report":
				// code...
				this.download_chart_report()
				break;
			case "close_modal":
				if (this.allow_refresh && this.drill_list.length == 0 && this.present_in_outer_level) {
					this.pause_refresh_timer = false;
				}
				break;

			default:
				// code...
				break;
		}
	}

	ext_btn_event_handler(event) {
		this.ext_btn_event.emit(event);
	}

}
function beforeLinkedItemClose() {
	throw new Error('Function not implemented.');
}



