import { Injectable } from '@angular/core';
import { Theme, dark, light, green,basic } from './theme';
@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  private active: Theme = light;
  private availableThemes: Theme[] = [dark, light, green,basic];

  getAvailableThemes(): Theme[] {
    return this.availableThemes;
  }

  getActiveTheme(): Theme {
    return this.active;
  }

  setTheme(theme:string): void {
    const theme_map = {
      "dark": dark,
      "light": light,
      "green": green,
      "basic":basic
    }
    this.setActiveTheme(theme_map[theme]);
  }

  setActiveTheme(theme: Theme): void {
    this.active = theme;

    Object.keys(this.active.properties).forEach(property => {
      document.documentElement.style.setProperty(
        property,
        this.active.properties[property]
      );
    });
  }

  constructor() { }
}
