import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { SnotifyService } from 'ng-snotify';
import { EnvService } from 'src/app/services/env.service';
import { ApiCacheOpsService } from 'src/app/services/api-cache-ops.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})

export class NavbarComponent implements OnInit {
  
  childnavOpen: boolean;
  isNavbarCollapsed = true;
  is_admin = true;
  project_list = [];
  project_id: number = -1;
  create_project_payload = {
    "name": "",
    "description": ""
  }
  nav_items:any = [];
  selectedItem:any = [];

  constructor(
    public _router: Router,
    private _common_service: CommonService,
    private snotifyService: SnotifyService,
private _api_cache_ops: ApiCacheOpsService,
    public env: EnvService,

  ) {

    const admin = localStorage.getItem('is_admin');
    if (admin == 'true') {
      this.is_admin = true;
    } else {
      this.is_admin = false;
    }
    
    this._router.events.subscribe(
      res => {
        if (res instanceof NavigationEnd) {
          if(this.check_for_specific_url()){
            this.onClickChangeActiveFlag(this.check_for_specific_url())
          }else{
            this.get_all_nav_item_route(res.url)
          }
        }
      }
    )
  }

  ngOnInit() {
    this.get_sidebar_tree()
  }

  check_for_specific_url(){
    //check dds substring in the url 
    var specific_url = this._router.url.includes("dds");
    if(specific_url){
      var specific_temp_url = this.nav_items.find(x=>x.state.includes('dds'));
      return specific_temp_url;
    }
    return null; 
  }

  get_matching_url(str, substrings) {
    for (var i = substrings.length - 1; i >= 0 ; i--) {
       var substring = substrings[i];
       if (str.indexOf(substring) != - 1) {
         return substring;
       }
    }
    return null; 
  }

  get_subnav_urls(){
    //get all child urls
    var child_nav_item_data = this.nav_items.filter(x=>x.type == 'dropDown');
    var child_urls = []
    for(let i=0; i<child_nav_item_data.length; i++){
      if(child_nav_item_data[i].sub){
        for(let j=0; j<child_nav_item_data[i].sub.length; j++){
          child_urls.push(child_nav_item_data[i].sub[j].state+'/')
        }
      }
    };
    return child_urls
  }

  get_main_nav_urls(){
    //get all parent nav urls
    var parent_urls = []
    var parent_nav_item_data = this.nav_items.filter(x=>x.type == 'link');
    for(let i=0; i<parent_nav_item_data.length; i++){
      parent_urls.push(parent_nav_item_data[i].state+'/')
    };
    return parent_urls
  }

  get_all_nav_item_route(url){
    //match current url with the parent nav url
    var parent_temp_url = this.get_matching_url(url+'/', this.get_main_nav_urls());
    if(parent_temp_url){
      parent_temp_url = parent_temp_url.substring(0, parent_temp_url.length - 1)
      var parent_nav_item = this.nav_items.find(x=>x.state == parent_temp_url);
    }
    if(parent_nav_item){
      this.onClickChangeActiveFlag(parent_nav_item)
    }else{
      parent_nav_item = {}
    }  

    //match current url with the child nav url
    if(Object.keys(parent_nav_item).length == 0){
      for(let i=0; i<this.nav_items.length; i++){
        if(this.nav_items[i].type == 'dropDown'){
          var temp_url = this.get_matching_url(url+'/', this.get_subnav_urls());
          if(temp_url){
            temp_url = temp_url.substring(0, temp_url.length - 1)
            if(this.nav_items[i].sub){
              var child_nav_item = this.nav_items[i].sub.find(x=>x.state == temp_url);     
              if(child_nav_item){
                this.setActiveChildItem(child_nav_item)
              }
            }
          }
        }
      }
    }
  }

  hide_sidebar() {
    if ($('body').hasClass('mini-navbar')) {
      $('body').removeClass('mini-navbar');
    } else {
      $('body').addClass('mini-navbar');
    }
  }

  async logout() {
    var payload1 = {}
    localStorage.removeItem('user_name');
   
    if (localStorage.getItem('is_admin') == 'true') {
      
      this._router.navigate(['./admin_lg/login']);
      
    } else {
      this._router.navigate(['./app_lg/login']);
    }
    localStorage.removeItem('is_admin');
    localStorage.removeItem('lg_admin');
    localStorage.removeItem('sidebar_data_1');
    localStorage.removeItem('sidebar_data_2');
    var logout_data = await this._common_service.logout(payload1).toPromise();
    localStorage.removeItem('token');
    this._api_cache_ops.reset_all_cached_data();
  }

  getProjectList = () => {
    this.project_id = -1;
    this.clear_create_project_paylaod();
    this._common_service.get_project_list({}).subscribe(res => {
      if (res.errCode === 0) {
        this.project_list = res.msg;
      }
      else {
        this.snotifyService.error("Error fetching project list")
      }
    }
    )
  }
  clear_create_project_paylaod = () => {
    this.create_project_payload = {
      "name": "",
      "description": ""
    }
  }
  show_project_id = (project_id) => {
    console.log(project_id)
    this.project_id = project_id;
  }
  //add a new project
  addProject = () => {
    const payload = {
      'name': this.create_project_payload.name,
      'description': this.create_project_payload.description,
    }
    this._common_service.add_project(payload).subscribe(res => {
      this.snotifyService.success('Project Created Successfully');
      this.clear_create_project_paylaod();
    })
  }

  get_sidebar_tree(){
    this._common_service.get_sidebar_tree({}).subscribe(res => {
      this.nav_items = res.sidebar_tree;
      this.setActiveFlag();
    });
  }
  
  selectItem(item) {
    this.inactive_child_navbar_item(item);
    this.childnavOpen = true;
    this.selectedItem = item;
    this.setActiveMainItem(item);
  }

  inactive_child_navbar_item(item){
    if(item.type == 'dropDown'){
      for(let j=0; j<item.sub.length; j++){
        item.sub[j].active = false;
      }
    }
  }
  
  onClickChangeActiveFlag(item) {
    //activate corresponding nav items
    this.setActiveMainItem(item);
    if(item.active == false){
      this.childnavOpen = false;
    }else{
      this.selectItem(item);
    }
  }

  setActiveMainItem(item) {
    this.nav_items.forEach(item => {
      item.active = false;
    });
    item.active = true;
  }

  setActiveChildItem(item) {
    //activate corresponding child nav item
    var dropdown_nav_item = this.nav_items.filter(x=>x.type == 'dropDown');
    for(let i=0; i<dropdown_nav_item.length; i++){
      if(dropdown_nav_item[i].sub){
        for(let j=0; j<dropdown_nav_item[i].sub.length; j++){
          if(dropdown_nav_item[i].sub[j] == item){
            dropdown_nav_item[i].sub[j].active = false;
            this.onClickChangeActiveFlag(dropdown_nav_item[i])  
          }
        }
      }
    };

    item.active = true;
  }

  setActiveFlag() {
    //activate corresponding nav and child nav items on refresh
    this.selectedItem = [];
    if (window.location) {
      const activeRoute = window.location.hash || window.location.pathname;
      this.nav_items.forEach(item => {
        item.active = false;
        if(this.check_for_specific_url()){
          //check for dds url
          this.selectedItem = this.check_for_specific_url();
          this.check_for_specific_url().active = true;
        }else{

          var parent_temp_url = this.get_matching_url(this._router.url+'/', this.get_main_nav_urls());
          if(parent_temp_url){   
            parent_temp_url = parent_temp_url.substring(0, parent_temp_url.length - 1)
            if (item.state == parent_temp_url) {
              this.selectedItem = item;
              item.active = true;
            }  
          }
          if (item.sub) {
            
            item.sub.forEach(subItem => {
              subItem.active = false;
              var temp_url = this.get_matching_url(this._router.url+'/', this.get_subnav_urls());

              if(temp_url){   
                temp_url = temp_url.substring(0, temp_url.length - 1)
                if (subItem.state == temp_url) {
                  this.selectedItem = item;
                  item.active = true;
                  subItem.active = true;
                }  
              }
            });
          }
        }
      });
    }
  }

}
