import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';

@Component({
  selector: 'app-view-report-dashboard-main',
  templateUrl: './view-report-dashboard-main.component.html',
  styleUrls: ['./view-report-dashboard-main.component.css']
})
export class ViewReportDashboardMainComponent implements OnInit {
	dashboard_id = -1;
	view_configure = true;

	constructor(
		private _activatedRouter: ActivatedRoute,
		private _router: Router,
	) {
		this._activatedRouter.params.subscribe(params => {
			this.dashboard_id = params.dash_id;
		  
			var temp = this._router.url.split("/");
			if(temp[1] == 'app'){
				this.view_configure = false;
			}
		});
	}
	ngOnInit() {
	}

}
