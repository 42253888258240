import { Component, OnInit, Input,ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { ConfigService } from '../../config-builder/service/config.service';

@Component({
  selector: 'app-view-group-report',
  templateUrl: './view-group-report.component.html',
  styleUrls: ['./view-group-report.component.css']
})
export class ViewGroupReportComponent implements OnInit {
  @ViewChild('group_reports_scroll') private group_reports_scroll: ElementRef;
  @Input() report_id = -1;
  @Input() hidden_filter_object?: any = {};
	@Input() visible_filter_object?: any = {};
  @Input() refresh_timer?: number = -1;
  @Input() pause_refresh_timer?: number = 0;
  loading=false;
  ext_buttons = []
  report_list = []
  data_view_config: any = {}
  param_def = {
		'hidden_param_val':[],
		'param_val':[],
		'max_records':100
	}
  show_view_filter = false
  report_order_data=[]
  download_btn_text = 'Download Group Report'
  view_filter_data=[]
  current_report_id = -1;
  disableLeftArrow = true;
  disableRightArrow = false;
  showScrollArrow = false;

  constructor(
    public config_service: ConfigService
  ) { }

  ngOnInit() {
    if(this.report_id != -1){
			this.loading = true
			this.get_group_report_data();
		}
  }

  ngOnChanges(){
    this.loading = true;
    this.override_filters();
    let data = this.config_service.process_and_override_filter(this.param_def.hidden_param_val,this.param_def.param_val,this.hidden_filter_object,this.visible_filter_object)
    this.param_def.hidden_param_val = data["hidden_filter_data"]
		this.param_def.param_val = data["filter_data"]
  }

  override_filters(){
    let data = this.config_service.process_and_override_filter(this.param_def.hidden_param_val,this.param_def.param_val,this.hidden_filter_object,this.visible_filter_object)
    this.param_def.hidden_param_val = data["hidden_filter_data"]
		this.param_def.param_val = data["filter_data"]
    this.loading=false
  }

  async get_group_report_data(){
		let res1 = await this.config_service.get_report_by_id({'id':this.report_id}).toPromise()
    this.report_list = res1.data.col_def
    this.report_order_data = JSON.parse(JSON.stringify(this.report_list));
    this.process_report_list(0);
    if(res1.data.param_def != null){
			this.param_def = res1.data.param_def;
		}
    this.data_view_config = res1.data.data_view_config? res1.data.data_view_config:null;
    if(this.data_view_config){
      this.show_view_filter = (this.data_view_config['show_view_filter']!= null && this.data_view_config['show_view_filter']!= undefined) ? this.data_view_config['show_view_filter']:false
    }
    let filter_object = {...this.hidden_filter_object, ...this.visible_filter_object};
		let temp_payload = {
			'hidden_filter_data':this.param_def.hidden_param_val,
			'filter_data':this.param_def.param_val,
			"hidden_filter_object": filter_object
		}
    let res4 = await this.config_service.process_query_filters(temp_payload).toPromise()
		if(res4.errCode == 0){
			this.param_def.hidden_param_val = res4.msg.hidden_filter_data;
			this.param_def.param_val = res4.msg.filter_data;
      this.override_filters();
		}
    this.process_filters();
    this.view_filter_data = JSON.parse(JSON.stringify(this.param_def.param_val))
    this.current_report_id = this.report_list[0].report_id;
    this.loading = false;
    this.updateArrowStates()
  }

  process_report_list(index){
    this.report_list.map((report)=>{
      report['active'] = false;
    })
    this.report_list[index].active = true;
  }

  ChangeActiveReport(index){
    this.current_report_id = this.report_list[index].report_id;
    this.process_report_list(index);
  }

  config_event(event){
    if(event.case == "apply_filter"){
      this.refresh_group_report_data();
      this.process_filters();
    }
  }

  process_filters(){
    let temp_visible_filter_object={}
    for (var i = this.param_def.param_val.length - 1; i >= 0; i--) {
      // if(this.param_def.hidden_param_val[i]?.nested_form_id){
      //   for (var j = this.param_def.hidden_param_val[i].form.length - 1; j >= 0; j--) {
      //       temp_visible_filter_object[this.param_def.hidden_param_val[i].form[j].paramName] = this.param_def.hidden_param_val[i].form[j].paramValue;
      //   }
      // }else
        if(this.param_def.param_val[i].paramValue != undefined  && ((this.param_def.param_val[i].mandatory && this.param_def.param_val[i].paramValue != "" ) || !this.param_def.param_val[i].mandatory)){
        temp_visible_filter_object[this.param_def.param_val[i].paramName] = this.param_def.param_val[i].paramValue;
      }
    }
    this.visible_filter_object = JSON.parse(JSON.stringify(temp_visible_filter_object))
  }

  refresh_group_report_data(){
    this.report_list = JSON.parse(JSON.stringify(this.report_order_data));
    let index = this.report_list.findIndex(list => list.report_id == this.current_report_id);
    this.process_report_list(index);
    this.view_filter_data = JSON.parse(JSON.stringify(this.param_def.param_val))
  }
    
  download_report(){
    this.download_btn_text = 'Downloading'
    var payload = {
      "group_report_id": this.report_id,
      "sheet_list": this.report_list,
      "filter_data": this.param_def.param_val,
      "hidden_filter_data": this.param_def.hidden_param_val
    };
    this.config_service.create_group_report(payload).subscribe(res => {
      var payload2 = {"file_type": "report", "file_name": res.filename};
      if (((localStorage.getItem('leggero_username') != null) || (localStorage.getItem('leggero_username') != undefined))) {
        const is_admin=JSON.parse(localStorage.getItem('lg_admin'))
        payload2["logdata"] = {
          "logtype": "report_export",
          "logdata": {
            "user_name": localStorage.getItem('leggero_username'),
            "main_itemname": res.filename,
            "main_itemid": this.report_id,
            "dashboard_name": localStorage.getItem('lg_dashboard_name'),
            "system_access_id": localStorage.getItem('system_access_id'),
            "is_admin_panel": is_admin == undefined? 0: is_admin,
          }
        };

      }
      this.config_service.download_report(payload2).subscribe(res2 => {
        this.download_btn_text = 'Download Report'
        window.open(res2.download_link, "_blank");
      });

    },error => {
        this.download_btn_text = "Download Report"
    });
  }

  onScroll() {
    this.updateArrowStates();
  }

  updateArrowStates() {
    setTimeout(() => {
      if (this.group_reports_scroll) {
        const scrollElement = this.group_reports_scroll.nativeElement;
        const atStart = scrollElement.scrollLeft === 0;
        const atEnd = (scrollElement.scrollWidth - scrollElement.clientWidth) <= scrollElement.scrollLeft + 5;
        this.disableLeftArrow = atStart;
        this.disableRightArrow = atEnd;
        this.showScrollArrow =  scrollElement.scrollWidth > scrollElement.clientWidth;
      }
    }, 100);
  }

  scroll_options(to_right: boolean = true) {
    let element = this.group_reports_scroll.nativeElement;
    let scroll_position = 0;
    if (to_right) {
      scroll_position = element.scrollLeft + element.clientWidth - (element.clientWidth/2);
    } else {
      scroll_position = element.scrollLeft - element.clientWidth + (element.clientWidth/2); 
    }
    element.scrollTo({ left: scroll_position, behavior: 'smooth' });
    this.updateArrowStates();
  }

  ext_btn_event_handler(event){}
}
