import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardTabsComponent } from './dashboard-tabs/dashboard-tabs.component';

const routes: Routes = [
  // {
  //   path: 'dash-tabs/:dash_grp_id',
  //   component: DashboardTabsComponent
  // },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
