import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ApiCacheOpsService {
  cached_data: any = {}

  constructor() { }

  set_data(service:string, params: any): string {
    let parameter = ''
    switch (service) {
      case "/lg_query/get_all_query":
        if(params.is_filter_query){
          parameter = 'filter_query'
        }else{
          parameter = 'all_query'
        }
        break;

      case "/whapi/getTableColumns":
        if(params.conid == null || params.conid == ""){
          parameter = "0"
        }else{
          parameter = params.conid
        }
        break;

      case "/whapi/all_ao_map":
        if(params.id == null || params.id == ""){
          parameter = "0"
        }else{
          parameter = params.id
        }
        break;
    }
    return parameter
  }


  //set cached data corresponding to their parameters
  set_cached_data(service:string, params: any, response: any): void {
    const parameter = this.set_data(service, params)
    if(parameter != ''){
      if (this.cached_data[service] == undefined){
        this.cached_data[service] = {}
      }
      this.cached_data[service][parameter] = response
    }else{
      this.cached_data[service] = response
    }
  }

  //get cache data corresponfing to the params(optional). If cache data not found, set cache data after api call
  get_cached_data(service: string, params: any): object {
    let data = {}
    const parameter = this.set_data(service, params) //use for setting parameter name for the service's different params
    if(parameter != '' && this.cached_data[service]){
      data = this.cached_data[service][parameter]
    }else{
      data = data = this.cached_data[service];
    }
    if(data == undefined){
      return { "status": false, "data": data }
    }
    return { "status": true, "data": data }
  }

  //reset cache data on update or save the data
  reset_cached_data(service): void{
    if(this.cached_data[service]){
      this.cached_data[service] = null;
    }
  }


  //reset all cached data. Call only on logout action
  reset_all_cached_data(): void{
    this.cached_data = {};
  }


  //find the data to be updated and update. If data not found in the list just push data to the list. 
  // if cache data not set, do nothing
  
  // update_cached_data(service: string, data: any){
  //   switch(service){
  //     case "/lg_query/get_all_query":
  //       if(this.cached_data[service]){
  //         let list1 = this.cached_data[service]['all_query']
  //         let list2 = this.cached_data[service]['filter_query']
  //         let data1 = this.create_or_update_list(list1, data);
  //         let data2 = this.create_or_update_list(list2, data);
  //         if(!data['is_filter_query'] && list2){
  //           if(data2.index == -1){
  //             data2.index = list2['data'].length-1;
  //           }
  //           data2['list']['data'].splice(data2.index, 1);
  //         }
  //         this.cached_data[service]['all_query'] = data1.list;
  //         this.cached_data[service]['filter_query'] = data2.list;
  //       }
  //       break;
  //   }
  //   console.log(this.cached_data);
  // }


  //return object with required updated keys 

  // transform_object(object, keys){
  //   let ret_obj = {}
  //   keys.forEach(key => {
  //     ret_obj[key] = object[key];
  //   })
  //   return ret_obj;
  // }


  //if data found, update the data else create new object
  
  // create_or_update_list(list, data){
  //   let index = null;
  //   let keys = null;
  //   if(list){
  //     index = list['data'].findIndex(item => item.id == data.id);
  //     keys = Object.keys(list['data'][0]);
  //     if(index == -1){
  //       list['data'].push(this.transform_object(data, keys))
  //     }else if(index != -1){
  //       list['data'][index] = this.transform_object(data, keys);
  //     }
  //   }
  //   return {'list':list, 'index':index};
  // }
}
