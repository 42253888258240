import { AfterViewInit, Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { EnvService } from './services/env.service';
import { ThemeService } from './services/theme.service';
import * as Raphael from 'raphael';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewInit {
  title = 'raptor';

  current_url = '';
  current_theme = this._env.css_theme;
  constructor(public _router: Router,private themeService: ThemeService, private _env: EnvService) {
    this._router.events.subscribe(
      res => {
        if (res instanceof NavigationEnd) {
          const url_name = res.url.split('/');
          if (url_name[1] != undefined && url_name[2] != undefined) {
            this.current_url = url_name[1] + '/' + url_name[2];
          }
        }
        $('body').removeClass('modal-open');
        $('.modal-backdrop').remove();
      });
    this.initializeApp();
  }

  initializeApp() {
    Raphael.eve.on('raphael.new', function() {
      this.raphael._url = this.raphael._g.win.location.href.replace(/#.*?$/, '');
    });
  }

  ngAfterViewInit(){
    this.themeService.setTheme(this.current_theme);
  }

  validate_login_condition(){
    return this.current_url == '' || this.current_url == 'admin_lg/login'||  this.current_url == 'app_lg/login'
  }

}
