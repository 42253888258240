import { Injectable } from '@angular/core';
import { HttpClient,HttpErrorResponse } from '@angular/common/http';
import { EnvService } from '../../../services/env.service';

import { Observable } from 'rxjs';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';


@Injectable({
  providedIn: 'root'
})
export class ConfigService {
	public search_dropdownSettings = {
	    singleSelection: false,
	    selectAllText: 'Select All',
	    unSelectAllText: 'UnSelect All',
	    itemsShowLimit: 3,
	    allowSearchFilter: true
	}
	public search_SingledropdownSettings = {
	    singleSelection: true,
	    itemsShowLimit: 3,
	    allowSearchFilter: true
	}
	public job_interval = 10000
	post_lg_dashboards: any;
	private fsc_key = '';

	// env_map:any = {}
	constructor(
	    private http: HttpClient,
		public env: EnvService
	) { 
	}
	env_map = {
		'wh_service_url':this.env.wh_service_url,
		'db_service_url':this.env.db_service_url,
	}
	raptor_switch = this.env.raptor_switch
	
	private get_fsc_key_url = `${this.env_map.db_service_url}/api/get_fsc_key`;
	get_fusionchart_key(){
		return this.http.post<any>(this.get_fsc_key_url, {});
	}
	// Services
	
	private get_comp_widget_url = `${this.env_map.wh_service_url}/whapi/getCompWidget`;
	get_comp_widget(payload) {
		return this.http.post<any>(this.get_comp_widget_url, payload);
	}
	private get_pivot_report_url = `${this.env_map.wh_service_url}/whapi/getPivotReport`;
	get_pivot_report(payload) {
		return this.http.post<any>(this.get_pivot_report_url, payload);
	}

	private get_flat_pivot_report_url = `${this.env_map.wh_service_url}/whapi/getFlatPivotReport`;
	get_flat_pivot_report(payload) {
		return this.http.post<any>(this.get_flat_pivot_report_url, payload);
	}
	private get_drill_report_url = `${this.env_map.wh_service_url}/whapi/getDrillReport`;
	get_drill_report(payload) {
		return this.http.post<any>(this.get_drill_report_url, payload);
	}
	private get_style_report_url = `${this.env_map.wh_service_url}/whapi/getStyleReport`;
	get_style_report(payload) {
		return this.http.post<any>(this.get_style_report_url, payload);
	}

	private create_group_report_url = `${this.env_map.wh_service_url}/whapi/create_group_report`;
	create_group_report(payload) {
		return this.http.post<any>(this.create_group_report_url, payload).catch(this.errorHandler);
	}
	
	private test_query_by_id_url = `${this.env_map.wh_service_url}/whapi/queryTestById`;
	test_query_by_id(payload) {
		return this.http.post<any>( this.test_query_by_id_url, payload);
	}

	private process_query_filters_url = `${this.env_map.wh_service_url}/whapi/processQueryFilters`;
	process_query_filters(payload) {
		return this.http.post<any>(this.process_query_filters_url, payload);
	}
		
	private lg_rdashboards_url = `${this.env_map.db_service_url}/api/lg_dashboards`;
	get_lg_rdashboards(filter) {
		return this.http.get<any>( this.lg_rdashboards_url + filter);
	}
	
	private create_report_url = `${this.env_map.wh_service_url}/whapi/create_report`;
	create_report(payload) {
		return this.http.post<any>(this.create_report_url, payload).catch(this.errorHandler);
	}
	
	private download_report_url = `${this.env_map.wh_service_url}/whapi/download_file`;
	download_report(payload) {
		return this.http.post<any>(this.download_report_url, payload).catch(this.errorHandler);
	}	

	
	private download_chart_report_url = `${this.env_map.wh_service_url}/whapi/create_chart_report`;
	download_chart_report(payload) {
		return this.http.post<any>(this.download_chart_report_url, payload).catch(this.errorHandler);
	}
	
	errorHandler(error: HttpErrorResponse) {
		console.log(error);
		return Observable.throw(error.message || 'Server Error');
	}
	

	private lg_reports_url = `${this.env_map.db_service_url}/api/lg_reports`;
	get_lg_reports(filter) {
		return this.http.get<any>(this.lg_reports_url + filter);
	}

	private lg_query_url = `${this.env_map.db_service_url}/api/lg_query`;
	get_lg_query(filter) {
		return this.http.get<any>(this.lg_query_url + filter);
	}

	private get_query_by_id_url = `${this.env_map.db_service_url}/lg_query/get_query_by_query_id`;
	get_query_by_id = (payload) => {
		return this.http.post<any>(this.get_query_by_id_url, payload)
	}


	private lg_report_dashboard_url = `${this.env_map.db_service_url}/api/lg_report_dashboard`;
	get_lg_report_dashboard(filter) {
		return this.http.get<any>(this.lg_report_dashboard_url + filter);
	}

	private lg_vinsights_url = `${this.env_map.db_service_url}/api/lg_vinsights`;
	get_lg_vinsights(filter) {
		return this.http.get<any>( this.lg_vinsights_url + filter);
	}

	private get_widget_by_id_url = `${this.env_map.db_service_url}/lg_composite_widget/get_widget_by_id`;
	get_widget_by_id = (payload) => {
		return this.http.post<any>(this.get_widget_by_id_url, payload)
	}

	private get_report_by_id_url = `${this.env_map.db_service_url}/lg_report/get_report_by_id`;
	get_report_by_id = (payload) => {
		return this.http.post<any>(this.get_report_by_id_url, payload)
	}

	private get_vinsights_by_id_url = `${this.env_map.db_service_url}/lg_vinsights/get_vinsights_by_id`;
	get_vinsights_by_id = (payload) => {
		return this.http.post<any>(this.get_vinsights_by_id_url, payload)
	}

    private get_vi_param_url = `${this.env_map.wh_service_url}/whapi/getVIParam`;
	get_vi_param(payload) {
		return this.http.post<any>( this.get_vi_param_url, payload);
	}


	private filter_from_query_url = `${this.env_map.wh_service_url}/whapi/filterFromQuery`;
	filter_from_query(payload) {
		return this.http.post<any>( this.filter_from_query_url, payload);
	}

	private send_mail_from_vi_url = `${this.env_map.wh_service_url}/whapi/send_mail_from_vi`;
	send_mail_from_vi(payload) {
		return this.http.post<any>( this.send_mail_from_vi_url, payload);
	}
	private lg_dashboards_list_url = `${this.env_map.db_service_url}/api/lg_dashboards`;
	get_lg_dashboards_list(filter) {
		return this.http.get<any>( this.lg_dashboards_list_url + filter);
	}

	private post_lg_dashboards_list_url = `${this.env_map.db_service_url}/lg_dshb/get_dshb_by_grp_id`;
	post_lg_dashboards_list(payload) {
		return this.http.post<any>(this.post_lg_dashboards_list_url, payload);
	}

	private get_all_dashboard_groups_url = `${this.env_map.db_service_url}/lg_dashboards/get_all_dashboard_groups`;
	get_all_dashboard_groups(payload) {
        return this.http.post<any>(this.get_all_dashboard_groups_url, payload);
    }

	private get_dshb_group_by_id_url = `${this.env_map.db_service_url}/lg_dashboards/get_dshb_group_by_id`;
	get_dshb_group_by_id(payload) {
        return this.http.post<any>(this.get_dshb_group_by_id_url, payload);
	}

	private get_all_alloc_dshb_group_url = `${this.env_map.db_service_url}/lg_dashboards/get_all_users`;
	get_all_alloc_dshb_group(payload) {
        return this.http.post<any>(this.get_all_alloc_dshb_group_url, payload);
	}

    private lg_dashboards_url = `${this.env_map.db_service_url}/lg_dshb/get_dshb_by_id`;
	get_lg_dashboards(payload) {
		return this.http.post<any>(this.lg_dashboards_url, payload);
	}

	
	private lg_composite_widgets_url = `${this.env_map.db_service_url}/api/lg_composite_widgets`;
	get_lg_composite_widgets(filter) {
		return this.http.get<any>(this.lg_composite_widgets_url + filter);
	}

	private create_job_report_url = `${this.env_map.wh_service_url}/whapi/job/create_report`;
	create_job_report(payload) {
		return this.http.post<any>(this.create_job_report_url,payload);
	}


	private get_job_data_url = `${this.env_map.wh_service_url}/whapi/job/data`;
	get_job_data(payload) {
		return this.http.post<any>(this.get_job_data_url,payload);
	}

	private remove_job_url = `${this.env_map.wh_service_url}/whapi/job/remove`;
	remove_job(payload) {
		return this.http.post<any>(this.remove_job_url,payload);
	}


	private lg_dshbgroup_dashboard_url = `${this.env_map.db_service_url}/api/lg_dshbgroup_dashboard`;
	get_lg_dshbgroup_dashboard(filter) {
		return this.http.get<any>(this.lg_dshbgroup_dashboard_url + filter);
	}

	private get_all_dashboards_url = `${this.env_map.db_service_url}/lg_dashboards/get_all_dashboards`;
	get_all_dashboards = (payload) => {
		return this.http.post<any>(this.get_all_dashboards_url, payload)
	}




 

	// Functions
  	get_drill_data(columns_list){
		// var columns_list = await this.test_query(test_payload).toPromise();
		var x = 0;
		var default_drill_order = [];
		for(var i=0; i<columns_list.msg.length; i++){
			if(columns_list.msg[i].field_name.indexOf('_LgV') == -1) {
				var tmp = columns_list.msg[i].field_name.split("_");
				var last_elem = tmp[tmp.length-1];

				if(!isNaN(last_elem)){
					default_drill_order[x++] = columns_list.msg[i];
				}
			}
		}
		return default_drill_order;	
	}

	check_nested_multiselect_filter_query(param_name: string, selected_cols=[]){
		let search_list = param_name.substring(1).split('_')
		let order = 0;
		for(let i = 0; i < search_list.length-2; i++){
			order++;
			if(!selected_cols.find(cols => cols.field_name == search_list[i]+'_'+order)){
				return false;
			}
		}
		return true;
	}

	create_nested_multiselect_payload(node_list, current_list=[], temp_list=[]){
		for(let i=0;i<node_list.length;i++){
			temp_list.push(
			  {
				'name': node_list[i].col_name,
				'value': node_list[i].name
			  }
			)
			if(node_list[i].children && node_list[i].children.length){
			  [current_list, temp_list] = this.create_nested_multiselect_payload(node_list[i].children, current_list, temp_list)
			}else{
			  current_list.push([...temp_list]);
			}
			temp_list.pop()
		}
		return [current_list, temp_list]
	}

	process_and_override_filter(hidden_filter_data,filter_data,hidden_filter_object,visible_filter_object){
		// Should be applicable for hidden_filter_data
		for (var i = filter_data.length - 1; i >= 0; i--) {
			if(!filter_data[i].mandatory){
				if(filter_data[i]['filterType'] == "Static List" || filter_data[i]['filterType'] == "Query List"){
					filter_data[i]['paramValueList'] = []
				}
				filter_data[i].paramValue = ""
			}else{
				if(filter_data[i]['filterType'] == "Static List" || filter_data[i]['filterType'] == "Query List"){
					filter_data[i]['paramValueList'] = [];
					if(filter_data[i]['paramName'].indexOf('list') != -1){
						filter_data[i]['paramValueList'] = JSON.parse(JSON.stringify(filter_data[i]['filterList']))
						filter_data[i]['paramValue'] = filter_data[i]['paramValueList'].join(';')
					}
					if(filter_data[i].pKey == 'multiselect'){ 
						filter_data[i]['paramValue'] = this.create_nested_multiselect_payload(filter_data[i].filterList)[0]
					}
				}
			}
			if(filter_data[i]['inputType'] == 'Searchable Select' || filter_data[i]['inputType'] == 'Dropdown'){
				if(filter_data[i].default_value && filter_data[i].filterList.includes(filter_data[i].default_value)){
					filter_data[i].paramValue = filter_data[i].default_value;
				}
			}
		}

		for(var i=0; i<filter_data.length; i++) {
			var key = filter_data[i]['paramName'];
			if(visible_filter_object[key] != undefined){
				filter_data[i]['paramValue'] = visible_filter_object[key];
				if(filter_data[i]['filterType'] == "Static List" || filter_data[i]['filterType'] == "Query List" ){
					filter_data[i]['paramValueList'] = [];
					if(!key.endsWith('multiselect') && (filter_data[i]['inputType'] == 'Searchable Select' || filter_data[i]['inputType'] == 'Searchable Multi-Select')){
						filter_data[i]['paramValueList'] = visible_filter_object[key].split(";");
					}
					if(key.endsWith('multiselect')){
						filter_data[i].selection_status = visible_filter_object[key+'selection_status']
						filter_data[i].selected_leaf_nodes = visible_filter_object[key+'selected_leaf_nodes']
					}
				}
			}
		}

		for(var i=0; i<hidden_filter_data.length; i++) {
			var key = hidden_filter_data[i]['paramName'];
			if(hidden_filter_object[key] != undefined){
				hidden_filter_data[i]['paramValue'] = hidden_filter_object[key];
			}
		}
		let ret = {
			"hidden_filter_data": hidden_filter_data,
			"filter_data": filter_data	
		}
		return ret
	}

}
