import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { TreeService } from '../tree/tree.service';
declare var $: any;
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.css'],
  host: {
    '(window:resize)': 'onResize($event)'
  }
})
export class TreeComponent implements OnChanges,OnInit, AfterViewInit {

	//	Variables Initialised

  	@Input() case: string = '';
  	@Input() tree_id: string;
  	@Input() id: string;
    @Input() search_placeholder: string = "Search within Hierarchy";

    @Output() tree_event: EventEmitter<any> = new EventEmitter<any>(true); // passed true to make event async
    @ViewChild('mainScreen') elementView: ElementRef;

  	menu_map = {
      "all_ao_map": {
        "menu": {
          "table": ["show_table_sql"],
          "view": ["show_view_sql"],
          "function": ["show_function_sql"],
          "trigger_function":["show_trigger_function_sql"],
          "column": [],
          "index":["show_index_sql"]
        },
        "service": "all_ao_map",
      },
      "query_cols": {
        "menu": {
          "query": [],
          "column": []
        },
        "service": "query_cols",
      },
      "project_map": {
        "menu": {
          "project": {
            "5__5001": "create_version",
            "5__5002": "view_audit_logs",
            "5__5003": "download_ver_json"
          },
          "version_unloaded": {
            "5__5004": "load_version",
            "5__50021":"change_ver_status",
            "5__5005": "copy_version"
          },
          "version_loaded": {
            "5__50019": "view_version",
            "5__50021":"change_ver_status",
            "5__50020": "copy_version"
          },
          "tables_parent": [],
          "hybrid_table": [],
          "custom_func_parent": [],
          "table": [],
          "column": [
            // "drop_column"
          ],
          "api_definition_parent": {
            "4__5006": "create_api"
          },
          "auth": {
            "5__50010": "view_api",
            "5__50011": "delete_api"
          },
          "api": {
            "5__50010": "view_api",
            "5__50011": "delete_api"
          },
          "script_definition_parent": {
            "5__5007": "create_script_config"
          },
          "script_definition": {
            "5__50017": "view_script_config",
            "5__50018": "delete_script_config",
            "5__50022": "view_audits_script"
          },
          "ftp_config_parent": {
            "5__5008": "create_ftp_config"
          },
          "ftp_config": {
            "5__50012":  "view_ftp_config",
            "5__50021":  "change_ftp_pass",
            "5__50013":  "delete_ftp_config"
          },
          "email_config_parent": {
            "5__5009": "create_email_config"
          },
          "email_config": {
            "5__50014": "view_email_config",
            "5__50015": "change_email_pass",
            "5__50016": "delete_email_config"
          },
          "email_config_template_parent": {
            "5__5005": "create_email_config_template"
          },
          "email_config_template": {
            "5__50014": "view_email_config_template",
          },
          "file_healthcheck_config_parent": {
            "5__5009": "create_file_health_checker"
          },
          "file_healthcheck_config": {
            // "5__50014":  "create_excel_format_checker",
            // "5__5009": "view_update_excel_format",
            "5__50021":  "update_file_health_checker",
            "5__50013":  "delete_file_health_checker"
          },
        },
        "service": "get_project_tree"
      },
      "version_map": {
        menu: {},
        context_menu_items_map: null,
        icon_types: null,
        service: "get_version_tree"
      },
      "ver_source_map": {
        "menu": {},
        "service": "version/source_tree"
      },
    };

  context_menu_items_map = [
    {
      item_value: 'change_email_pass',
      item_name: 'Change Email Password',
      _disabled: false
    },
    {
      item_value: 'delete_email_config',
      item_name: 'Delete',
      _disabled: false
    },
    {
      item_value: 'view_email_config',
      item_name: 'View/Update',
      _disabled: false
    },
    {
      item_value: 'create_email_config',
      item_name: 'Create Email Config',
      _disabled: false
    },
    {
      item_value: 'create_file_health_checker',
      item_name: 'Create File Health Checker',
      _disabled: false
    },
    // {
    //   item_value: 'view_update_excel_format',
    //   item_name: 'View/Update Excel Format',
    //   _disabled: false
    // },
    {
      item_value: 'update_file_health_checker',
      item_name: 'Update File Health Checker',
      _disabled: false
    },
    // {
    //   item_value: 'create_excel_format_checker',
    //   item_name: 'Create Excel Format Checker',
    //   _disabled: false
    // },
    {
      item_value: 'delete_file_health_checker',
      item_name: 'Remove File Health Checker',
      _disabled: false
    },
    {
      item_value: 'create_email_config_template',
      item_name: 'Create Email Config Template',
      _disabled: false
    },
    {
      item_value: 'view_email_config_template',
      item_name: 'View/Update',
      _disabled: false
    },
    {
      item_value: 'view_audit_logs',
      item_name: 'View Audit Logs',
      _disabled: false
    },
    {
      item_value: 'download_ver_json',
      item_name: 'Download Version JSON',
      _disabled: false
    },
    {
      item_value: 'copy_version',
      item_name: 'Copy Version',
      _disabled: false
    },
    {
      item_value: 'create_version',
      item_name: 'Create Version',
      _disabled: false
    },
    {
      item_value: 'create_api',
      item_name: 'Create',
      _disabled: false
    },
    {
      item_value: 'view_api',
      item_name: 'View',
      _disabled: false
    },
    {
      item_value: 'delete_api',
      item_name: 'Delete',
      _disabled: false
    },
    {
      item_value: 'create_script_config',
      item_name: 'Create Script',
      _disabled: false
    },
    {
      item_value: 'view_script_config',
      item_name: 'View/Update',
      _disabled: false
    },
    {
      item_value: 'delete_script_config',
      item_name: 'Delete',
      _disabled: false
    },
    {
      item_value: 'view_audits_script',
      item_name: 'View Script Revision History',
      _disabled: false
    },
    {
      item_value: 'create_ftp_config',
      item_name: 'Create FTP Config',
      _disabled: false
    },
    {
      item_value: 'change_ftp_pass',
      item_name: 'Update Password',
      _disabled: false
    },
    {
      item_value: 'view_ftp_config',
      item_name: 'View/Update',
      _disabled: false
    },
    {
      item_value: 'delete_ftp_config',
      item_name: 'Delete',
      _disabled: false
    },
    {
      item_value: 'view_version',
      item_name: 'View Version',
      _disabled: false
    },
    {
      item_value: 'load_version',
      item_name: 'Load Version',
      _disabled: false
    },
    {
      item_value: 'change_ver_status',
      item_name: 'Set Status',
      _disabled: false
    },
    {
      item_value: 'show_table_sql',
      item_name: 'Show Table SQL',
      _disabled: false
    },
    {
      item_value: 'show_view_sql',
      item_name: 'Show View SQL',
      _disabled: false
    },
    {
      item_value: 'show_function_sql',
      item_name: 'Show Function SQL',
      _disabled: false
    },
    {
      item_value: 'show_trigger_function_sql',
      item_name: 'Show Trigger Function SQL',
      _disabled: false
    },
    {
      item_value: 'show_index_sql',
      item_name: 'Show Index SQL',
      _disabled: false
    }
  ]

  	menu_items = {};
  	service = '';

  	icon_types: any = {
  		"default" : {
  			"icon" : "jstree-remove-icon"
      },
      "version_loaded" : {
  			"icon" : "fas fa-check-circle"
      },
      "version_unloaded" : {
  			"icon" : "fas fa-times-circle"
      },
  		"In-Memory API" : {
  			"icon" : "fa fa-table"
      },
      "In-Memory" : {
  			"icon" : "fa fa-table"
      },
      "In-Memory Database Table" : {
  			"icon" : "fa fa-table"
      },
      "In-Memory Excel" : {
  			"icon" : "fa fa-table"
      },
      "Hybrid": {
        "icon": "far fa-hdd"
      },
  		"query" : {
  			"icon" : "fa fa-table"
  		},
      "api": {
        "icon": "fas fa-exchange-alt"
      },
      "auth": {
        "icon": "fas fa-key"
      },
      "directory": {
        "icon": "fas fa-folder-open"
      },
      "file": {
        "icon": "fas fa-file"
      },
      "email_config": {
        "icon": "fas fa-envelope-open-text"
      },
      "email_config_template": {
        "icon": "fas fa-envelope-open-text"
      },
      "script_definition": {
        "icon": "fas fa-file-code"
      },
      "ftp_config": {
        "icon": "fas fa-file-export"
      },
      "ftp_definition": {
        "icon": "fas fa-file-export"
      }
  	}

    tree_data = [];
    tree_height = '';
    role_map = {};

	constructor(
    private _tree_service: TreeService,
    private _sharedService: SharedService
		) {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.id.firstChange) {
      this._update_tree_data(this.case, this.tree_id, changes.id.currentValue);
    }
  }

	ngOnInit() {
    var self = this;
    this.role_map = this._sharedService.get_roles_map();
  }

  ngAfterViewInit() {
    this.onResize('');
    if (this.case != '') {
      this._get_tree_data();
    }
    this.tree_event.emit({'case': 'tree_view_init', 'data': {} }); 
    this.tree_event.emit({'case': 'tree_view_init', 'data': {} });
  }

  onResize(event) {
    // 70 value for the margin bottom
    let offset_top_value = $('.top-position').offset().top
    if (offset_top_value == 0) {
      setTimeout(() => {
        this.adjust_tree_height($('#'+this.tree_id).offset().top)
      },1000)
    } else {
      this.adjust_tree_height(offset_top_value)
    }
  }

  adjust_tree_height(offset_top_value) {
    const w = window.innerHeight - offset_top_value  - 70;
    const divHeight = w.toString() + 'px' ;
    document.getElementById(this.tree_id).setAttribute('style', `height:${divHeight}`);
    this.tree_event.emit({'case': 'tree_div_height', 'data': w });
  }

	jsTree(tree_id, res_data){

    var to: any = false;

    $('#search_'+tree_id).keyup(function() {
      if (to) {
        clearTimeout(to);
      }
      to = setTimeout(function() {
        var v = $('#search_'+tree_id).val();
        $('#'+tree_id).jstree(true).search(v);
      }, 250);
    });

    tree_id = tree_id;
    // tree_id = 'js_tree_id';
		var self = this;
		// alert(self);
		var main_tree = $('#'+tree_id).jstree({
			'core' : {
				'data' : res_data.data,
				'themes': {
          'name': 'proton',
          'responsive': true,
					'icons': true
				}
			},
      "plugins": ["types", "contextmenu", "search"],
      "search": {
        "show_only_matches": true,
      },
			// "plugins": ["contextmenu"],
      "types": res_data.icon_map ? res_data.icon_map : this.icon_types,
			"contextmenu": {
		    "items": function(node) {
          return self.context_menu_options(self, node, res_data.context_menu_items_map);
        }
	    }

		}).bind("select_node.jstree", function (evt, data){
			self.left_click_options(self, data);
    });
    this.tree_event.emit({'case': 'tree_loaded', 'data': {} });
  }

  //	FUNCTIONS

  context_menu_options(self, node, context_item_map){
    // this method can be imrpoved, items can be processed once.
  	// var tree = $("#"+tree_id).jstree(true);	//	IMPORTANT
    var final_items = {};

    var items = {
      // add_new_division: {
      //     label: "Add New Division",
      //     action: function (data) {
      //     	self.tree_to_parent({'case': 'add_new_division', 'data': node.original});
      //     }
      // },
      // deactivate: {
      //     label: "Deactivate Practice",
      //     action: function (data) {
      //     	self.tree_to_parent({'case': 'deactivate', 'data': node.original});
      //     }
      // }
    };
    const context_menu_map = context_item_map ? context_item_map : this.context_menu_items_map;
    // for(var item in context_menu_map){
    //   items[item] = {
    //     label: context_menu_map[item],
    //     value: item,
    //     action: function (data) {
    //       self.tree_to_parent({'case': data.item.value, 'data': node.original});
    //     }
    //   };
    // }
    for (let i = 0; i < context_menu_map.length; i++) {
      items[context_menu_map[i].item_value] = {
        label: context_menu_map[i].item_name,
        _disabled: context_menu_map[i]._disabled,
        value: context_menu_map[i].item_value,
        action: function (data) {
          self.tree_to_parent({ 'case': data.item.value, 'data': node.original });
        }
      };
    }

    for(var i=0; i<node.data.context_items.length; i++) {
    	final_items[node.data.context_items[i]] = items[node.data.context_items[i]];
    }

    return final_items;

  }

  left_click_options(self, data){
  	var internal_case = data.node.original.type;
		// alert(internal_case);
    switch(self.case){

      case 'all_ao_map':
        switch(internal_case){
          default:
            //  do nothing
          break;
        }
      break;

      case 'query_cols':
        switch(internal_case){
          default:
            //  do nothing
          break;
        }
      break;

      case 'project_map':
        switch(internal_case){
          default:
            self.tree_to_parent({ 'case': self.case + '_left_' + internal_case, 'data': data.node.original });
          break;
        }
      break;

      case 'version_map':
        switch (internal_case) {
          default:
            self.tree_to_parent({ 'case': self.case + '_left_' + internal_case, 'data': data.node.original });
          break;
        }
      break;

      case 'ver_source_map':
        switch (internal_case) {
          default:
            self.tree_to_parent({ 'case': self.case + '_left_' + internal_case, 'data': data.node.original });
          break;
        }
      break;

			default:
				// self.default_click(data.node);
			break;
		}

  }

  tree_to_parent(data: any): void {
      this.tree_event.emit(data);
  }

  _get_tree_data() {
    this.update_menu_items();
    console.log(this.id)
    this._tree_service.get_tree_data(this.service, this.id, this.menu_items).subscribe(
      res => {
        this.tree_data = res.data;
        // console.log(this.tree_data);
        this.jsTree(this.tree_id,res);
        // this.change_tree_node_color();
      },
      err => {
        console.log(err)
      }
    );
  }

  get_parent_node_data(node_data: any) {
    const parent_node_data = this.tree_data.find(parent_node => {
      return parent_node.id === node_data.parent;
    });
    return parent_node_data;
  }

  get_child_node_data(parent_node_id: string) {
    const child_nodes = this.tree_data.filter(child_node => {
      return child_node.parent === parent_node_id;
    });
    return child_nodes;
  }

  _update_tree_data(case_type, tree_id, id) {
  	this.case = case_type;
  	this.tree_id = tree_id;
  	this.id = id;

  	this.update_menu_items();

	    this._tree_service.get_tree_data(this.service, this.id, this.menu_items).subscribe(
	      res => {
          this.tree_data = res.data;
	        $('#'+ this.tree_id).jstree(true).settings.core.data = this.tree_data;
          $('#'+ this.tree_id).jstree(true).refresh();
          this.tree_event.emit({'case': 'tree_loaded', 'data': this.tree_data });
	      },
	      err => {
	        console.log(err)
	      }
	    );

  }

  update_menu_items(){
    this.menu_items = this.filter_menu_by_role(this.menu_map[this.case]['menu']);
  	// this.menu_items = this.menu_map[this.case]['menu'];
  	this.service = this.menu_map[this.case]['service'];

  }

  filter_menu_by_role(menu_items){
    // return menu_items;
    var return_menu_obj = {};
    for (var type in menu_items) {
      return_menu_obj[type] = [];
      for (var role_key in menu_items[type]) {
        if(this.fetch_role(role_key) == 1){
          return_menu_obj[type].push(menu_items[type][role_key]);
        } else {
          // skip adding to menu
        }
      }
    }

    return return_menu_obj;
  }

  fetch_role(key){
    var param = key.split("__");
    if(this.role_map[param[0]] != undefined && this.role_map[param[0]][param[1]] != undefined){
      return this.role_map[param[0]][param[1]];
    } else {
      return 1;
    }
  }

  get_tree_data_local(_case) {
    setTimeout( () => {
      this.tree_to_parent({'case':_case,'data':this.tree_data});
    },500);
  }

  set_tree_data_local(tree_id, tree_data) {
    this.jsTree(tree_id, tree_data);
  }

  update_tree_data_local(tree_id, tree_data) {
    $('#' + tree_id).jstree(true).settings.core.data = tree_data;
    $('#' + tree_id).jstree(true).refresh();
  }

  tree_state_close() {
    $('#'+ this.tree_id).jstree("close_all");
    $('#'+ this.tree_id).jstree(true).refresh();
  }

  tree_state_open() {
    $('#'+ this.tree_id).jstree("open_all");
  }

  // dds specific method
  change_tree_node_color() {
    const self = this;
    if (this.case !== 'version_map') {
      return;
    }
    $('#' + this.tree_id)
      .bind('ready.jstree', 'refresh.jstree', function (e, data) {
        // invoked after jstree has loaded
        self.add_color_css_to_node();
      })
      .bind('refresh.jstree', function (e, data) {
        // invoked after jstree has refreshed
        self.add_color_css_to_node();
      })
  }

  add_color_css_to_node() {
    for (let i = 0; i < this.tree_data.length; i++) {
      if (this.tree_data[i].data_object_loaded != undefined) {
        var element = document.getElementById(`${this.tree_data[i].id}_anchor`);
        if (this.tree_data[i].data_object_loaded) {
          element.classList.add("color-green");
        } else {
          element.classList.add("color-red");
        }
      }
    }
  }

}
