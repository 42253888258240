import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.css']
})
export class SpinnerComponent implements OnInit {

  isLoading = false;

  constructor(
    private sharedService: SharedService
  ) {
    this.sharedService.isLoading.subscribe(res => {
      this.isLoading = res;
      // console.log('show spinner', this.isLoading);
      if (!this.isLoading) {
        $(`.top-position`).trigger('click');
      }
    });
  }

  ngOnInit() {
  }



}
