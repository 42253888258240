import { Component, Input, OnInit } from '@angular/core';
import { SnotifyService } from 'ng-snotify';
import { UtilityService } from 'src/app/services/utility.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-copy-text',
  templateUrl: './copy-text.component.html',
  styleUrls: ['./copy-text.component.css']
})
export class CopyTextComponent implements OnInit {

  @Input() copy_content : any;
  @Input() tooltip = 'Copy';
  @Input() css_class ?='pills-link';
  @Input() msg ?= 'Copied Successfully';

  constructor(
    private snotifyService: SnotifyService,
    private utilityService: UtilityService,
    private _common_service: CommonService
  ) { }

  ngOnInit() {
  }

  copy_text(){
    // let copyText = document.createElement('input');
    // copyText.value = this.copy_content;
    // console.log(copyText.value)
    // document.body.appendChild(copyText);
    // copyText.select();
    // document.execCommand('copy');
    // this.snotifyService.success(this.msg, this.utilityService.getConfig());
    // document.body.removeChild(copyText);
    this._common_service.copy_to_clipboard(this.copy_content);
  }

}
