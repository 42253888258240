import { Component, OnInit, Input, Output, EventEmitter, ViewChild, SimpleChanges, OnChanges, AfterViewInit, ElementRef } from '@angular/core';
import { NgCompleterService } from './ng-completer.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-ng-completer-v2',
  templateUrl: './ng-completer-v2.component.html',
  styleUrls: ['./ng-completer-v2.component.css']
})

export class NgCompleterV2Component implements OnInit, OnChanges, AfterViewInit {
  
  @Input() searchKeywordsList?: string[] = [];
  @Input() displayKeyword: string = '';
  @Input() inputValue: string = ''; // ngmodel binded value
  @Input() placeHolder?: string = 'Search'; // placholder
  @Input() isDisable?: boolean = false; // toggle
  @Input() clearIfWrongInput?: boolean = false; // clear input automatic if the value is bot matched in dropdoen
  @Input() clearOnFocus?: boolean = false; // Automatic change input to placeholder
  @Input() dynamicData?: boolean = false; // For real time response
  @Input() serviceCallData?: any; // applicable only if dynamic data is true (basic payload of service)
  @Input() url?: string = ''; // applicable only if dynamic data is true, service call url
  @Input() searchKey?: string = 'query'; // applicable only if dynamic data is true which key reatian the value if search text
  @Input() retainPrevValue?: boolean = false; // Retaining the previous record as placeholder
  @Input() z_index: number = 100; // Make sure to pass z-index, pass different value if we are using multiple completer on  one page
  @Input() search_api_res_data_key ?= 'msg';
  @Output() outputValue: EventEmitter<any> = new EventEmitter<any>(); // emitter with different cases
  
  @ViewChild('auto') auto;

  private subject: Subject<string> = new Subject();

  global_inputValue = ''; // input value for repopulating
  global_placeholder = ''; // default placeholder for repopulating

  searchKeyword?: string = 'search_key';
  data: any = [];
  isLoading = false;
  debounceTime = 200;
  @Input()
  set dataList(val: any) {
    this.data = val;
  }
  get dataList(): any { return this.data; }

  constructor(
    public _completerService: NgCompleterService,
    private _el: ElementRef
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.placeHolder) {
      this.global_placeholder = changes.placeHolder.currentValue
    }
    if(changes.inputValue && changes.inputValue.currentValue == '') {
      if(this.dynamicData) {
        this.dataList = []
      }
    }
    if(changes.dataList) {
      this.map_data()
    }
  }

  ngAfterViewInit() {
    // Avoid overlapping of multiple completer
    const obj = this._el.nativeElement.querySelector('.autocomplete-container');
    obj.setAttribute('style', `z-index: ${this.z_index}`);
  }

  ngOnInit() {
    if (this.dynamicData) {
      this.subject.pipe(debounceTime(this.debounceTime)).subscribe((title) => {
        const payload = this.serviceCallData;
        payload[this.searchKey] = title;
        this.dataList = []
        this.isLoading = true;
        this._completerService.get_searched_data(this.url, payload).subscribe(res => {
          if (res.errCode == 0) {
            this.dataList = res[this.search_api_res_data_key];
            this.map_data();
          }
          this.isLoading = false;
        });
      });
    } else {
      this.map_data();
    }

  }

  map_data() {
    this.dataList.map(data => {
      data[this.searchKeyword] = ''
      for (let i = 0; i < this.searchKeywordsList.length; i++) {
        if (data[this.searchKeywordsList[i]] != undefined && 
            data[this.searchKeywordsList[i]] != null && 
            data[this.searchKeywordsList[i]] != '')
        data[this.searchKeyword] += data[this.searchKeywordsList[i]] + ' ';
      }
    });
  }

  completer_select(event) {
    if(event != null) {
      this.inputValue = event[this.displayKeyword];
      this.global_inputValue = event[this.displayKeyword];
      this.emit_data({case:'select_v2',data:event})
    }
  }
  
  completer_input_changed($event) {
    console.log($event);
    if($event != '') {
      if (this.dynamicData) {
        this.completer_advanced_search($event);
      } else {
        // DO nothing
      }
    } else {
      // this.emit_data({case:'clear',data: {}});
    }
  }

  completer_closed() {
    this.clear_if_input_wrong();
    this.populate_prev_value();
  }

  completer_focus(event) {
    this.store_prev_value()
    this.clear_on_focus();
  }

  emit_data(data) {
    this.outputValue.emit(data);
  }

  // Custom functions 
  clear_if_input_wrong() {
    if (this.clearIfWrongInput) {
      const data = this.autocomplete_value_exist(this.dataList,this.displayKeyword,this.inputValue)
      if(!data.status) {
        this.emit_data({case:'wrong_input', data:{}})
      }
    }
  }

  autocomplete_value_exist(data_list, search_keyword, formNameValue) {
    if (typeof (formNameValue) == 'object') {
      for (let i = 0; i < data_list.length; i++) {
        if (data_list[i][search_keyword] == formNameValue[search_keyword]) {
          return { status: true, value: formNameValue };
        }
      }
    } else if (typeof (formNameValue) == 'string') {
      for (let i = 0; i < data_list.length; i++) {
        if (data_list[i][search_keyword] == formNameValue) {
          return { status: true, value: formNameValue };
        }
      }
    }
    return { status: false, value: null };
  }

  populate_prev_value() {
    if(this.dynamicData) {
      if(this.retainPrevValue) {
        this.inputValue = this.global_inputValue;
      } else {
        // this.dataList = [];
      }
    } else {
      if(this.retainPrevValue) {
        this.inputValue = this.global_inputValue;
      } else {
        // Do nothing 
      }
    }
  }

  store_prev_value() {
    if(this.retainPrevValue) {
      if(this.inputValue == '') {
        this.placeHolder = this.global_placeholder
      } else {
        this.placeHolder = this.inputValue;
      }
      this.global_inputValue = this.inputValue;
    }
  }

  clear_on_focus() {
    if (this.clearOnFocus) {
      this.inputValue = '';
      if(!this.dynamicData) {
        this.auto.clear();
      }
    }
  }

  completer_advanced_search(value) {
    let title = value.trim();
    if (title.toString().length > 2) {
      this.subject.next(title);
    } else if (title.toString().length <= 3) {
      // this.dataList = [];
    }
  }

  completer_cleared() {
    if(this.inputValue != '') { // When we press cross icon
      this.global_inputValue = '';
      this.placeHolder = this.global_placeholder;
      this.inputValue = '';
      if(this.dynamicData) {
        this.dataList = [];
      }
      this.emit_data({case:'clear',data: {}});
      this.auto.close()
    } else { // Normal on changed by backspace 
      // Do nothing
    }

  }

}
