import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SnotifyService } from 'ng-snotify';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-view-dashboard-tab-main',
  templateUrl: './view-dashboard-tab-main.component.html',
  styleUrls: ['./view-dashboard-tab-main.component.css']
})
export class ViewDashboardTabMainComponent implements OnInit {
  dashboard_grp_id = -1;
  view_configure = true;
  hidden_filter_input = '';
  hidden_filter_object:any = {};
  constructor(
    private _activatedRouter: ActivatedRoute,
    private _router: Router,
    private snotifyService: SnotifyService,
    private utilityService: UtilityService
  ) { 
    this._activatedRouter.params.subscribe(params => {
      this.dashboard_grp_id = params.dash_grp_id;
      
      var temp = this._router.url.split("/");
      if(temp[1] == 'app'){
        this.view_configure = false;
        this.hidden_filter_object = {"@user_name": localStorage.getItem('user_name')}
      }
    });
  }

  ngOnInit() {
  }

  change_dash_filter () {
    if(this.IsJsonString(this.hidden_filter_input)) {
      this.hidden_filter_object = JSON.parse(this.hidden_filter_input);
    } else {
      this.snotifyService.error('Please enter a valid JSON', this.utilityService.getConfig());
    }
    
  }

  IsJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
  }


}
