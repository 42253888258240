import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfigService } from '../../config-builder/service/config.service';

@Component({
  selector: 'app-dashboard-tabs',
  templateUrl: './dashboard-tabs.component.html',
  styleUrls: ['./dashboard-tabs.component.css']
})
export class DashboardTabsComponent implements OnInit {
  @ViewChild('scrollUl') scrollUl: ElementRef;

  @Input() dsh_grp_id = -1;
  @Input() allow_auto_filter = true;
  @Input() hidden_filter_object?:any = {};
  @Input() default_dashboard_id?:any = -1;
  dash_grp_config: any = {
    dshbrd_list: [],
    dhsgrp_dshbrd_ids: [],
    dshgrp_dashboards: [],
    current_dash: {},
    current_dashboard_id: null,
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private configService: ConfigService,
  ) {
    // if (!this.is_child) {
    //   this.activatedRoute.params.subscribe(params => {
    //     this.dsh_grp_id = params.dash_grp_id;
    //   });
    // }
  }

  ngOnInit() {
    this.init_methods();
  }

  async init_methods() {
    await this.get_lg_dshbgroup_dashboard();
  }

  async get_lg_dshbgroup_dashboard() {
    const res: any = await this.configService.post_lg_dashboards_list({'id':this.dsh_grp_id}).toPromise();
    this.dash_grp_config.dshgrp_dashboards = res.data
    let defaultDashboardFound = false
    for (const dash of res.data) {
      if(this.default_dashboard_id != -1 && dash.dashboard_id ===  this.default_dashboard_id) {

        this.dash_grp_config.current_dashboard_id = this.default_dashboard_id
        this.dash_grp_config.current_dash = dash
        defaultDashboardFound = true;
        break;
      }
    }
    if(!defaultDashboardFound){
      this.dash_grp_config.current_dashboard_id = res.data[0].dashboard_id
      this.dash_grp_config.current_dash = res.data[0]
    }
  }


  on_tab_change(dashboard_id) {
    const current_dash = this.dash_grp_config.dshgrp_dashboards.find(dshb => dshb.dashboard_id == dashboard_id)
    this.dash_grp_config.current_dash = current_dash;
  }

  scroll(direction) {
    if (direction == 'left') {
      this.scrollUl.nativeElement.scrollLeft -= 150;
    } else if (direction == 'right') {
      this.scrollUl.nativeElement.scrollLeft += 150;
    }
  }

}
