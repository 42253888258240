import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SharedService } from 'src/app/services/shared.service';
declare var $:any;
interface newTab {
  'type':string,
  'name': string,
  'default_name':string,
  'id': string,
  'is_visible': boolean,
  'icon':string,
  'is_loaded_in_memory':boolean,
  'data_json':object,
}
@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.css']
})
export class TabsComponent implements OnInit {

  @Input() tabs_config = [];
  @Input() current_tab;
  @Input() data_json;

  @Output() tab_events: EventEmitter<any> = new EventEmitter<any>();
  tab_title = '';
  is_editing = false;
  constructor(
    private shared:SharedService
  ) { }

  ngOnInit() {
    // this.process_datajson();
    this.toggle_tab(this.current_tab);
  }

  process_datajson(){
    for(let i=0; i < this.tabs_config.length;i++){
      this.tabs_config[i].data_json = JSON.parse(JSON.stringify(this.data_json));
    }
  }

  add_new_tab(){   //called whenever + button in clicked and a new dynamic tab is added in tabs_config
    const dynamic_tab_obj = JSON.parse(JSON.stringify(this.shared.tab_panel_json.query.new_tabs_json));
    const new_tab: newTab = {
      'type':'dynamic',
      'name': dynamic_tab_obj.default_name,
      'default_name': dynamic_tab_obj.default_name,
      'id': this.get_unique_dynamic_tab_id(dynamic_tab_obj.default_name),
      'is_visible': true,
      'icon':'',
      'is_loaded_in_memory':false,
      'data_json':JSON.parse(JSON.stringify(this.data_json)),
    }
    this.tabs_config.push(new_tab);
    // this.tabs_config = [...this.tabs_config];
    this.toggle_tab(new_tab)
  }

  remove_tab(idx){ // called whenever x icon is clicked and is used to remove a tab from tabs_config
    this.tabs_config.splice(idx,1);
    if(idx != 0){
      this.toggle_tab(this.tabs_config[idx-1]);
    }else if(this.tabs_config.length){
      this.toggle_tab(this.tabs_config[idx]);
    }else{
      this.add_new_tab();
    }
  }

  toggle_tab(tab){
    tab.is_loaded_in_memory = true;
    this.current_tab = tab;
    this.tab_events.emit({'type':'on_tab_change','all_tabs_list':this.tabs_config,'current_tab':tab});      
  }

  get_unique_dynamic_tab_id(tab_name){  //returns a unique id for each dynamic tab by using tab_name and current date and time.
    return tab_name + this.get_current_time_with_milliseconds();
  }

  get_current_time_with_milliseconds() {
    const currentDate = new Date();
    const milliseconds = currentDate.getMilliseconds();
    const formattedDate = currentDate.toISOString().slice(0, -1);
    return `${formattedDate}.${milliseconds}`;
  }

  change_scratch_pad_title(){
    this.current_tab['name'] = this.tab_title;
    for(let i=0;i<this.tabs_config.length;i++){
      if(this.tabs_config[i].id == this.current_tab.id){
        this.tabs_config[i].name = this.tab_title;
      }
    }
    this.close_change_scratch_pad_title_modal();
  }

  jump_to_selected_tab(event){  //used for quick switch between different tabs_config by using a dropdown.
    for(let i=0;i<this.tabs_config.length;i++){
      if(this.tabs_config[i].id == event){
        this.toggle_tab(this.tabs_config[i]);
        break;
      }
    }
  }

  reset_tab_title(){
    this.tab_title = this.current_tab.name;
    this.open_change_scratch_pad_title_modal();
  }

  open_change_scratch_pad_title_modal(){
    $('#scratch_pad_title').modal({ backdrop: 'static' });
  }

  close_change_scratch_pad_title_modal(){
    $('#scratch_pad_title').modal('hide');
  }

  edit_tab_title(){
    this.current_tab.is_title_edit_enabled = true;
  }

  disable_editing(){
    this.current_tab.is_title_edit_enabled = false;
  }

  reload_tabs(){
    this.shared.reset_tabs_config();
    this.tabs_config = this.shared.tabs_config.tabs_entities.query.config;
    this.toggle_tab(this.tabs_config[0]);
    this.tab_events.emit({'type':'on_tabs_reload','all_tabs_list':this.tabs_config,'current_tab':this.current_tab});
  }

  close_all_tabs(){
    for(let i=0;i<this.tabs_config.length;i++){
      if(this.tabs_config[i].type == 'dynamic'){
        this.tabs_config.splice(i, this.tabs_config.length - i);
      }
    }
    this.toggle_tab(this.tabs_config[0]);
    this.tab_events.emit({'type':'on_close_all_tabs','all_tabs_list':this.tabs_config,'current_tab':this.current_tab});
    this.close_modal();
  }

  open_modal(){
    $('#close_all_tabs_confirmation').modal({ backdrop: 'static', keyboard: false })
  }

  close_modal(){
    $('#close_all_tabs_confirmation').modal('hide');
  }

}
