import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-view-filter',
  templateUrl: './view-filter.component.html',
  styleUrls: ['./view-filter.component.css']
})
export class ViewFilterComponent implements OnInit {
  @Input() data:any = {}
  show_filter = false
  constructor() { }

  ngOnInit() {
    console.log(this.data)
  }
  
  show_card(){
    let filter_count = 0
    if(this.data['filter_data']){
      for(let i =0; i<this.data['filter_data'].length;i++){
        if(this.data['filter_data'][i]['paramValue']){
          filter_count ++;
        }
      }
    }
    if(filter_count == 0){
      return false
    }
    return true
  }

}
