import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-email-input',
  templateUrl: './email-input.component.html',
  styleUrls: ['./email-input.component.css']
})
export class EmailInputComponent implements OnInit, OnChanges {

  @Input() email_case: string = '';
  @Input() initialData?: any = [];
  @Input() is_multiple?: boolean = true;
  @Input() maxSelectedItems?: Number = 10;
  @Input() placeholder?: string = 'Select';
  @Input() notFoundText?: string = 'No items found';
  @Input() is_disable?: boolean = false;

  @Output() selected_email_data_event?: EventEmitter<any> = new EventEmitter<any>();

  email_data: any = [];
  selected_email = [];

  constructor(
  ) { }

  ngOnInit() {
    
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.initialData) {
      this.selected_email = changes.initialData.currentValue;
    }
  }

  addTagFn(name) {
    return name;
  }

  onAddEmail(name) {
    this.selected_email.push(name);
    this.selected_email_data_event.emit({
      'emails': this.selected_email
    });
  }

  onRemoveEmail(event) {
    this.selected_email_data_event.emit({
      'emails': this.selected_email
    });
  }

  onClearEmail() {
    this.selected_email_data_event.emit({
      'emails': this.selected_email
    });
  }
}
