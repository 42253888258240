import { Component, OnInit, Input, EventEmitter, Output, AfterViewInit} from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-info-tooltip',
  templateUrl: './info-tooltip.component.html',
  styleUrls: ['./info-tooltip.component.css']
})
export class InfoTooltipComponent implements OnInit {

  @Input() title_text = "hi";
  @Input() placement_direction = "right";
  @Input() info_style = "#71D2B3";
  @Input() icon_class = "ml-2 fa fa-info-circle open";

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit(){
    $('[data-toggle="tooltip"]').tooltip()
  }

}
