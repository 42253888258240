import { Directive, ElementRef, EventEmitter, HostListener, Injectable, Output } from '@angular/core';
import { NgControl } from '@angular/forms';
import { SnotifyService } from 'ng-snotify';
import { UtilityService } from 'src/app/services/utility.service';

@Directive({
  selector: '[appInputAlphaNumeric]',
})


export class InputAlphaNumericDirective {

  private alpha_numeric_regex: RegExp = new RegExp(/^[ 0-9a-zA-Z_-]*$/g)
  private escape_sequence_regex: RegExp = new RegExp(/[\r\n\t]/g)
  private special_characters: RegExp = new RegExp(/[./!"`'#%^&,:;<>=@{}~'\$\(\)\*\+\/\\\?\[\]\^\|]+/g);
  @Output() ngModelChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(private el: ElementRef,
    private ngControl: NgControl,
    private snotifyService: SnotifyService,
    private utilityService: UtilityService) { }

  @HostListener('keydown', ['$event'])

  onKeyUp(event) {
    const current: string = this.el.nativeElement.value;
    //  const change: string = String(current).replace(this.escape_sequence_regex, '');
    const next: string = current.concat(event.key)
    if ((next && !String(next).match(this.alpha_numeric_regex))) {
      event.preventDefault();
    } else {
    }
  }

  @HostListener('keyup', ['$event'])

  onPaste(event) {
    const current: string = this.el.nativeElement.value;
    let change: string = String(current).replace(this.escape_sequence_regex, ' ');
    if (!String(change).match(this.alpha_numeric_regex)) {
      change = String(current).replace(this.special_characters, '');
      this.snotifyService.warning('All special characters removed', this.utilityService.getConfig());
    } else { }
    this.el.nativeElement.value = change
    this.ngControl.control.patchValue(this.el.nativeElement.value);
    this.ngModelChange.emit(this.el.nativeElement.value)
  }

}
