import { Component, OnInit, Input, SimpleChanges, OnChanges, ViewChild, ElementRef, HostListener
 } from '@angular/core';
import {NestedTreeControl} from '@angular/cdk/tree';
import {MatTreeNestedDataSource} from '@angular/material/tree';
declare var $:any;

interface TestNode {
  name: string;
  children?: TestNode[];
  isSelected: boolean;
}

@Component({
  selector: 'app-multi-select-nested-filter',
  templateUrl: './multi-select-nested-filter.component.html',
  styleUrls: ['./multi-select-nested-filter.component.css']
})
export class MultiSelectNestedFilterComponent implements OnInit, OnChanges {
  @Input() multi_select_param:any = {};
  Object = Object;
  filterValue = ''
  treeControl = new NestedTreeControl<TestNode> (node => node.children);
  dataSource = new MatTreeNestedDataSource<TestNode>();
  @Input() unique_id:any;

  childIsSelectedList = [];
  selections: TestNode[] = [];
  selectionStatus = {};
  nodeLookup = {};
  selected_leaf_nodes = {};
  selected_leaf_node_parent = "";
  node_name_level_separator = '$$'
  global_data_json = [];
  select_text = "Select All"
  expand_text = "Expand All"
  select_all_value = 0;
  is_expanded = false;
  

  // Resizing Variables
  resizing: boolean = false;
  lastMouseX: number;
  lastMouseY: number;
  margin: number = 50;
  maxWidth: number;
  maxHeight: number;
  minWidth: number = 160;
  minHeight: number = 160;
  constructor(private el: ElementRef) {
    
  }
  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent): void {
    if (this.resizing) {
      if(!this.maxWidth || this.maxHeight){
        this.set_max_height_and_width();
      }
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;
      if (event.clientX > windowWidth - this.margin || event.clientY > windowHeight - this.margin) {
        return;
      }
      const deltaX = event.clientX - this.lastMouseX;
      const deltaY = event.clientY - this.lastMouseY;
      const div = $('[id="' + this.unique_id + 'multiselect-container"]')[0];
      
      let newWidth = div.offsetWidth + deltaX;
      let newHeight = div.offsetHeight + deltaY;
      if(newWidth <= this.minWidth || newHeight <= this.minHeight || newWidth > this.maxWidth || newHeight > this.maxHeight){
       return;
      }
      $('[id="' + this.unique_id + 'multiselect-container"]').css({"maxHeight":newHeight,"maxWidth":newWidth, "overflow": "auto"});
      div.style.width = `${newWidth}px`;
      div.style.height = `${newHeight}px`;

      this.lastMouseX = event.clientX;
      this.lastMouseY = event.clientY;
    }
  }

  set_max_height_and_width(){
    const contentElement = $('[id="' + this.unique_id + 'multiselect-container"]')[0];
    this.maxWidth = contentElement.scrollWidth + 100;
    this.maxHeight = contentElement.scrollHeight  + 50;
  }

  @HostListener('document:mouseup', ['$event'])
  onMouseUp(event: MouseEvent): void {
    if(this.resizing){
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;
      if(event.clientY > windowHeight - this.margin || event.clientX > windowWidth - this.margin){
        setTimeout(() => {
          $('[id="' + this.unique_id + 'multi_select_dropdown"]').dropdown('toggle')
        }, 10);     
      }
    }
    this.resizing = false;
  }

  onMouseDown(event: MouseEvent): void {
    this.resizing = true;
    this.lastMouseX = event.clientX;
    this.lastMouseY = event.clientY;
  }

  hasChild = (_: number, node: TestNode) => !!node.children && node.children.length > 0;
  partlySelected = (node) => (this.selectionStatus[node.name + this.node_name_level_separator + node.level] == 1);
  allSelected = (node) => (this.selectionStatus[node.name + this.node_name_level_separator + node.level] == 2);


  ngOnInit() {
    this.dataSource.data = this.multi_select_param.filterList;
    this.global_data_json = this.dataSource.data;
    this.treeControl.dataNodes = this.dataSource.data;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.dataSource.data = this.multi_select_param.filterList;
    this.treeControl.dataNodes = this.dataSource.data;
    if(!this.multi_select_param['selection_status'] && this.multi_select_param.mandatory){
      for(let i=0;i<this.multi_select_param.filterList.length;i++){
        this.selectionToggle(true, this.multi_select_param.filterList[i]);
      }
      this.global_data_json = this.dataSource.data;
      this.select_all_value = 2;
    }else if(this.multi_select_param['selection_status']){
      this.selectionStatus = this.multi_select_param['selection_status'];
      this.selected_leaf_nodes = this.multi_select_param['selected_leaf_nodes']
    }
  }

  set_selected_leaf_node_parent(node_name){
    let level_list = node_name.split(this.node_name_level_separator)[1].split('_');
    if(level_list.length == 1){
      return;
    }
    let node_list = this.multi_select_param.filterList;
    for(let i=0; i<level_list.length - 1; i++){
      this.selected_leaf_node_parent = this.selected_leaf_node_parent + node_list[level_list[i]].name + (i<level_list.length-2 ? " --> " : "")
      node_list = node_list[level_list[i]].children
    }
  }

  selectionToggle(isChecked, node, update_parent = true, on_search = false) {
    let newValue = isChecked? 2: 0;
    let node_with_level = node.name + this.node_name_level_separator + node.level
    this.selectionStatus[node_with_level] = newValue;
    if (node.children && node.children.length) {
      for (let child of node.children) {
        this.selectionToggle(isChecked, child, on_search, on_search);
      }
    }
    else{
      if(this.selected_leaf_nodes[node_with_level] && newValue == 0){
        delete this.selected_leaf_nodes[node_with_level];
      } else if(!this.selected_leaf_nodes[node_with_level] && newValue == 2){
        this.selected_leaf_nodes[node_with_level] = node.name;
      }
    }
    if(update_parent){
      let level_list = node.level.split('_')
      this.update_parent_nodes(level_list, newValue);
    }
  }

  filterTree(word) {
    let newData = []
    for (let child of this.multi_select_param.filterList) {
      let result = this.filterNode(child, word, false);
      if (result) newData.push(result);
    }
    return newData;
  }

  filterNode(node, word, selectAll){
    let newNode = {
      col_name: node.col_name,
      level: node.level,
      name: node.name,
      isSelected: node.isSelected,
      children: []
    }
    let nodeHit = newNode.name.toLowerCase().includes(word);
    if(nodeHit){
      newNode['highlight'] = true;
    }
    if (node.children && node.children.length) {
      for (let child of node.children) {
        let subtree = this.filterNode(child, word, nodeHit);
        if (subtree || nodeHit) {
          newNode.children.push(subtree);
        }
      }
    }
    if (nodeHit || newNode.children.length > 0 || selectAll) {
      return newNode;
    }
    return null;
  }

  update_parent_nodes(level_list, checked_value){
    if(level_list.length == 1){
      return;
    }
    let node_list = this.multi_select_param.filterList;
    let parent_node_childs = [], parent_name = ''
    for(let i=0; i<level_list.length - 1; i++){
      parent_name = node_list[level_list[i]].name + this.node_name_level_separator + node_list[level_list[i]].level
      parent_node_childs = node_list = node_list[level_list[i]].children
    }
    if(parent_name != ''){
      this.selectionStatus[parent_name] = this.get_selection_status(parent_node_childs, checked_value)
    }
    if(level_list.length>2){
      this.update_parent_nodes(level_list.slice(0, -1), checked_value)
    }
  }

  get_selection_status(child_nodes, checked_value){
    let status = checked_value;
    for(let i=0; i<child_nodes.length; i++){
      let selected_node_name = this.selectionStatus[child_nodes[i].name + this.node_name_level_separator + child_nodes[i].level]
      if(((selected_node_name==0 || selected_node_name == undefined) && checked_value == 2)
        || (selected_node_name == 1) || (selected_node_name == 2 && checked_value == 0)
      ){
        status = 1;
        break;
      }
    }
    return status;
  }

  clearSearch(){
    this.filterValue = '';
    this.dataSource.data = this.global_data_json;
    this.treeControl.dataNodes = this.global_data_json;
    if (this.is_expanded){
      this.ExpandAll(true)
    }
  }

  filterChanged(inputValue) {
    if(inputValue == ''){
      this.clearSearch()
      return;
    }
    let newData = this.filterTree(inputValue.toLowerCase());
    this.dataSource.data = newData;
    this.treeControl.dataNodes = this.dataSource.data;
    this.treeControl.expandAll();
  }

  remove_node(item){
    let node = {
      'name': item.split(this.node_name_level_separator)[0],
      'children': [],
      'level': item.split(this.node_name_level_separator)[1]
    }
    this.selectionToggle(0, node)
  }

  create_nested_multiselect_payload(current_list=[], temp_list=[], node_list = this.multi_select_param.filterList){
    for(let i=0;i<node_list.length;i++){
      let node_with_level = node_list[i].name+this.node_name_level_separator+node_list[i].level
      if(this.selectionStatus[node_with_level] != undefined && this.selectionStatus[node_with_level] > 0){
        temp_list.push(
          {
            'name': node_list[i].col_name,
            'value': node_list[i].name
          }
        )
        if(node_list[i].children && node_list[i].children.length){
          [current_list,temp_list ] =  this.create_nested_multiselect_payload(current_list, temp_list, node_list[i].children)
        }else{
          current_list.push([...temp_list]);
        }
        temp_list.pop()
      }
    }
    return [current_list, temp_list]
  }

  ToggleAllSelection(isChecked:boolean, force_removed = false){
    if(force_removed){
      this.clearSearch();
    }
    for(let i=0;i<this.dataSource.data.length;i++){
      this.selectionToggle(isChecked, this.dataSource.data[i], true, true);
    }
    this.select_text = isChecked ? "Deselect All" : "Select All";
    this.select_all_value = isChecked ? 1: 0;
  }

  ExpandAll(isChecked: boolean){
    if (isChecked){
      this.treeControl.expandAll();
      this.expand_text = "Collapse All";
      this.is_expanded = true
    }else{
      this.treeControl.collapseAll();
      this.expand_text = "Expand All"
      this.is_expanded = false
    }
  }
}
