import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WidgetComponent } from './widget/widget.component';
import { ViewCompositeWidgetComponent } from './view-composite-widget/view-composite-widget.component';

const routes: Routes = [
  {
    path: 'widget',
    component: WidgetComponent
  },
  {
    path: 'viewCompositeWidget',
    component: ViewCompositeWidgetComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CompositeWidgetRoutingModule { }
