import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class EnvService {
	public service_host = 'deldshbtest.alteram.co.za';

	public whhost = 'deldshbtest.alteram.co.za';
	public dbhost = 'deldshbtest.alteram.co.za';
	public ddshost = 'deldshbtest.alteram.co.za';
	public schedulerhost = 'deldshbtest.alteram.co.za';

	public dbport = 4892;
	public whport = 4893;
	public ddsport = 4894;
	public schedulerport = 4896;

	public wh_service_url = 'https://' + this.whhost + ':' + this.whport;
	public db_service_url = 'https://' + this.dbhost + ':' + this.dbport;
	public dds_service_url = 'https://' + this.ddshost + ':' + this.ddsport;
	public scheduler_service_url = 'https://' + this.schedulerhost + ':' + this.schedulerport;

	public dds_server_active = true;
	public max_records = 100;

	public isAdmin = true;
     
        public cache_service = ['/whapi/all_ao_map','/whapi/getTableColumns']; // (New Variable)


	public skip_spinner_for_services = [
		'/whapi/getTableColumns',
		'/get_all_tables_and_columns',
		'/get_pipeline_instance_logs',
                '/whapi/job/data',
                '/whapi/all_ao_map',
                '/auth',
                '/api/get_user_role_map'
	];
	public services_to_skip = [ '/auth' ];

	public io_host = 'deldshbtest.alteram.co.za';
	public io_port = '3001';
	public io_namespace = 'test';
	public io_url = 'https://' + this.io_host + ':' + this.io_port + '/' + this.io_namespace;

	public css_theme = 'basic';

	public dynamic_date_options = [
		{ key: 'Today', value: 'Today' },
		{ key: 'Yesterday', value: 'Yesterday' },
		{ key: 'Tommorrow', value: 'Tommorrow' },
		{ key: 'Current Month Start', value: 'Current Month Start' },
		{ key: 'Current Month End', value: 'Current Month End' },
		{ key: 'Next Month Start', value: 'Next Month Start' },
		{ key: 'Previous Month Start', value: 'Previous Month Start' },
		{ key: 'Previous Month End', value: 'Previous Month End' },
		{ key: 'Current Year', value: 'Current Year' },
		{ key: 'Current Month', value: 'Current Month' },
		{ key: 'Previous Year', value: 'Previous Year' },
		{ key: 'Previous Month', value: 'Previous Month' },
		{ key: 'Next Year', value: 'Next Year' },
		{ key: 'Next Month', value: 'Next Month' }
	];

	public external_btn_conf_cases = [
        {
            value_value: 'view_task',
            value_name: 'view_task'
        },
        {
            value_value: 'view_meeting',
            value_name: 'view_meeting'
        },
		{
			value_value: 'view_case',
			value_name: 'view_case'
		}
    ];


public system_access_options = [
    {
      value_value: 'Config Access',
      value_name: 'Config Access'
    },
    {
      value_value: 'Internal App',
      value_name: 'Internal App'
    },
    {
      value_value: 'External App',
      value_name: 'External App'
    }
  ];

 public  raptor_switch = true;

 public max_query_history_length = 50;
	constructor () {}
}

