import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilityService } from '../../../services/utility.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { SnotifyService } from 'ng-snotify';

declare var $;

@Component({
  selector: 'app-custom-datatable',
  templateUrl: './custom-datatable.component.html',
	styleUrls: ['./custom-datatable.component.css'],
	host: {
    '(window:resize)': 'onResize($event)'
  }
})
export class CustomDatatableComponent implements OnInit, AfterViewInit {
	@ViewChild(DataTableDirective) dtElement: DataTableDirective;
	dtOptions: any = {};
	selected_row:any = {};
	dtTrigger:Subject<any> = new Subject();
	single_select = true;
	@Input() columns;
	@Input() all_rows;
	@Input() table_key;
	@Input() table_column_search = false;
    @Output() dtevent: EventEmitter<any> = new EventEmitter<any>();

	constructor(
		private _activatedrouter: ActivatedRoute,
		private _route_link: Router,
		private utilityService: UtilityService,
	    private snotifyService: SnotifyService,
	) { }

	ngOnInit() {
		const self = this;

		this.dtOptions = {
			language: {
				search: "",
				searchPlaceholder: 'Search',
			},
			// pagingType: 'first_last_numbers',
			lengthChange: false,
			paging: true,
			select : true,
			bInfo : false,
			pagingType: "numbers",
		};
		var ind;
		this.dtOptions.rowCallback = function (this, row: Node, data: any[] | Object, index: number) {
			$.fn.once = function(a, b) {
			    return this.each(function() {
			        $(this).off(a).on(a,b);
			    });
			};
			function click_bind(){
				ind = self.get_index(data)
				self.selected_row = self.all_rows[ind];
				self.dt_to_parent({'data':self.selected_row,'case':'click'});
			}
			function dbclick_bind(){
				ind = self.get_index(data)
				self.dt_to_parent({'data':self.selected_row,'case':'dblclick'});
			}
			$('td', row).once('click', click_bind)
			$('td', row).once('dblclick', dbclick_bind);
			return row;
		};

		if(this.table_column_search){
			this.dtOptions.initComplete = function () {
	      	this.api().columns().every( function () {
	            var column = this;
	            var select = $('<select class="form-control form-control-sm select" style=""><option value=""></option></select>')
	                .appendTo( $(column.footer()).empty())
	                .on( 'change', function () {
	                    var val = $.fn.dataTable.util.escapeRegex($(this).val().toString());
		   
	                    column
	                        .search( val ? '^'+val+'$' : '', true, false )
	                        .draw();
                    } );
		   
		            column.data().unique().sort().each( function ( d, j ) {
		                select.append( '<option value="'+ d +'">'+d+'</option>' )
		            } );
		    } );
	    	}
	   	}

		this.dtOptions.stateSaveCallback = function (settings, data) {
      		localStorage.setItem('table_key', JSON.stringify(data));
    	};

    	this.dtOptions.stateLoadCallback = function (settings) {
      		return JSON.parse(localStorage.getItem('table_key'));
   		};
		// setTimeout(function(){
		// 	self.rerender()
		// },1000);

	}

	ngAfterViewInit() {
		this.onResize('');
	}
	onResize(event) {
    // 70 value for the margin bottom custom-datatable
		let offset_top_value = $('#cdt-' + this.table_key).offset().top
    if (offset_top_value == 0) {
      setTimeout(() => {
        this.adjust_tree_height($('#cdt-' + this.table_key).offset().top)
      },1000)
    } else {
      this.adjust_tree_height(offset_top_value)
    }
	}
	
	adjust_tree_height(offset_top_value) {
    const w = window.innerHeight - offset_top_value  - 70;
		const divHeight = w.toString() + 'px' ;
    document.getElementById('cdt-' + this.table_key).setAttribute('style', `height:${divHeight}`);
	}
	
	rerender(): void {
		if(this.dtElement.dtInstance != undefined){
		  this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
		    dtInstance.destroy();
		    this.dtTrigger.next();
		  });
		} else {
		  this.dtTrigger.next();
		}
	}

	dt_to_parent(data: any): void {
	      this.dtevent.emit(data);
	}
	get_index(data){
		for (var i = this.all_rows.length - 1; i >= 0; i--) {
			if(this.all_rows[i][this.columns[0]['prop']] == data[0]){
				return i;
			}
		}
		return -1
	}
}
