import { Component, OnInit } from '@angular/core';
import { ReactiveFormsModule, FormControl, FormBuilder, Validators, FormGroup } from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';
import { data } from 'jquery';
import { Subject } from 'rxjs';
import {CommonService} from 'src/app/services/common.service';
declare var $;
@Component({
  selector: 'app-email-config',
  templateUrl: './email-config.component.html',
  styleUrls: ['./email-config.component.css']
})
export class EmailConfigComponent implements OnInit {
  table_column_search;
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject();
  data = [
    { Id: 1, Name: 'Name1', Subject: 'Test1' },
    { Id: 2, Name: 'Name2', Subject: 'Test2' },
    { Id: 3, Name: 'Name3', Subject: 'Test3' },
    { Id: 4, Name: 'Name4', Subject: 'Test4' },
    { Id: 5, Name: 'Name5', Subject: 'Test5' },
    { Id: 6, Name: 'Name6', Subject: 'Test6' },
    { Id: 7, Name: 'Name7', Subject: 'Test7' },
    { Id: 8, Name: 'Name8', Subject: 'Test8' },
    { Id: 9, Name: 'Name9', Subject: 'Test9' },
    { Id: 10, Name: 'Name10', Subject: 'Test10' },
    { Id: 11, Name: 'Name11', Subject: 'Test11' },
    { Id: 12, Name: 'Name12', Subject: 'Test12' },
    { Id: 13, Name: 'Name13', Subject: 'Test13' },
    { Id: 14, Name: 'Name14', Subject: 'Test14' }
  ]
  columns = ['Id', 'Name', 'Subject'];
  constructor(private _fb: FormBuilder, private temp: CommonService) {
    this.initialize_email_config()

  }
  ngOnInit() {
    const self = this;
    this.dtOptions = {
      language: {
        search: "",
        searchPlaceholder: 'Search',
      },
      lengthChange: false,
      paging: true,
      select: true,
      bInfo: false,
      pagingType: "numbers",
    };
    var ind, s, n;
    this.dtOptions.rowCallback = function (this, row: Node, data: any[] | Object, index: number) {
      // console.log(data);
      $.fn.once = function (a, b) {
        return this.each(function () {
          $(this).off(a).on(a, b);
        });
      };
      function click_bind() {
        self.get_index(data)
      }
      $('td', row).once('click', click_bind)
      return row;
    };//End This function


  }

  // Oninit End Here

  email_config: FormGroup;
  disable_field = false;
  value: string

  initialize_email_config() {
    this.email_config = this._fb.group({
      name: this._fb.control('', Validators.required),
      to: this._fb.control('', [Validators.required, Validators.email]),//form conntrole se array kise bnaye smj nhi aa rha tha
      cc: this._fb.control(''),
      subject: this._fb.control('', Validators.required),
      body: this._fb.control('', Validators.required)
    });
  }
  submited = false

  login_data() {
    let data: String[] = [this.email_config.value.name, this.email_config.value.to,
    this.email_config.value.cc, this.email_config.value.subject,
    this.email_config.value.body];
    this.submited = true

  }
  validate_emai_config_payload() {
    this.submited = true;
    if (this.email_config.status == 'VALID' || this.email_config.status == 'DISABLED') {
      return true;
    }
    return false
  }
  toggle_form(bool) {
    if (bool) {
      this.disable_field = false;
      this.email_config.enable();
    }
    else {
      this.disable_field = true;
      this.email_config.disable()
    }
  }
  // populate_form_data(data:any){
  //   this.email_config.patchValue({
  //     name: data.name,
  //     to: data.to,
  //     cc: data.cc,
  //     subject: data.subject,
  //     body: data.body
  //   })
  // }

  new_record(){
    const payload={
      "body": this.body, 
      "name": this.name,  
      "cc":this.cc, 
      "template2emailconfig": null, 
      "template2project": null, 
      "to": this.to,  
      "subject": this.subject
    };this.temp.create_email_template_new_record(payload).subscribe(response => {
        console.log(response);
      }, error => {
        console.log(error);
      });
  }

  clear_form() {
    this.email_config.patchValue({
      name: "",
      to: null,
      cc: null,
      subject: "",
      body: ""
    })
  }
  get name() {
    return this.email_config.get('name')
  }

  get subject() {
    return this.email_config.get('subject')
  }
  get to() {
    return this.email_config.get('to')
  }
  get cc() {
    return this.email_config.get('cc')
  }
  get body() {
    return this.email_config.get('body')
  }
  submitVal(val) {
    this.value = val.value
  }
  compexist: boolean = true;
  remove() {
    this.compexist = false;
  }
  get_index(data) {
    let nam, sub;
    nam = data[1]
    sub = data[2]
    this.email_config.patchValue({
      name: nam,
      subject: sub
    })

  }

}

