import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'jsonText'})
export class JsonText implements PipeTransform {
  transform(value:any): string {
    if(typeof value == "object"){
    	return JSON.stringify(value)
    }else{
    	return value
    }
  }
}