import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedRoutingModule } from './shared-routing.module';

import { FormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { CodemirrorModule } from 'ng2-codemirror';
import { ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Ng2CompleterModule } from 'ng2-completer';
import { CKEditorModule } from 'ng2-ckeditor';

import { Ng2LoadingSpinnerModule } from 'ng2-loading-spinner';
import { ANIMATION_TYPES } from 'ng2-loading-spinner';
import { AceEditorModule } from 'ng2-ace-editor';

import { DashboardModule } from '../dashboard/dashboard.module'
import { ReportModule } from '../report/report.module'
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { NgSelectModule } from '@ng-select/ng-select';

import { NavbarComponent } from '../../components/shared/navbar/navbar.component';
import { ViewReportDashboardMainComponent } from '../../components/shared/view-report-dashboard-main/view-report-dashboard-main.component';
import { LoginComponent } from '../../components/user-app/login/login.component';
import { ViewDashboardMainComponent } from '../../components/shared/view-dashboard-main/view-dashboard-main.component';
import { TreeComponent } from '../../components/tree/tree.component';
import { CustomDatatableComponent } from '../../components/shared/custom-datatable/custom-datatable.component';
import { SpinnerComponent } from 'src/app/components/shared/spinner/spinner.component';
import { SideContainerComponent } from 'src/app/components/shared/side-container/side-container.component';
import { DdsApiInstanceLogsComponent } from 'src/app/components/shared/dds-api-instance-logs/dds-api-instance-logs.component';
import { ExternalBtnConfigComponent } from 'src/app/components/shared/external-btn-config/external-btn-config.component';
import { InfoTooltipComponent } from 'src/app/components/shared/info-tooltip/info-tooltip.component';
import { TestComponent } from './test/test.component';
import { NgCompleterComponent } from './ng-completer/ng-completer.component';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { ViewDashboardTabMainComponent } from 'src/app/components/shared/view-dashboard-tab-main/view-dashboard-tab-main.component';
import { EmailConfigComponent } from './email-config/email-config.component';
import { PagniatedDatatableComponent } from './pagniated-datatable/pagniated-datatable.component';
import { EmailInputComponent } from './email-input/email-input.component';
import { DatatableSearchComponent } from './datatable-search/datatable-search.component';
import { NgCompleterV2Component } from './ng-completer-v2/ng-completer-v2.component';
import { RecordComparisonComponent } from './record-comparison/record-comparison.component';

import { ButtonClickDirective } from '../../directive/button-click.directive';
import { NzRoleDirective } from '../../directive/nz-role.directive';
import { LowerCaseTextDirective } from '../../directive/lower-case-text.directive';
import { TogglerComponent } from './toggler/toggler.component';
import { BtnLoaderDirective } from './btn-loader/btn-loader.directive';
import { InputAlphaNumericDirective } from 'src/app/directive/input-alpha-numeric.directive';
import { AdminLogoutComponent } from 'src/app/layouts/admin-logout/admin-logout.component';
import { AdminLoginComponent } from 'src/app/layouts/admin-login/admin-login.component';
import { UserLoginComponent } from 'src/app/layouts/user-login/user-login.component';
import { UserLogoutComponent } from 'src/app/layouts/user-logout/user-logout.component';
import { FooterComponent } from 'src/app/components/shared/footer/footer.component';
import { SidebarComponent } from 'src/app/components/user-app/sidebar/sidebar.component';
import { SnotifyModule } from 'ng-snotify';
import { CopyTextComponent } from 'src/app/components/shared/copy-text/copy-text.component';
import { TabsComponent } from 'src/app/components/shared/tabs/tabs.component';
import { ClipboardKeyParserComponent } from 'src/app/components/shared/clipboard-key-parser/clipboard-key-parser.component';
import { TogglePasswordComponent } from 'src/app/components/shared/toggle-password/toggle-password.component';

const shared_components = [
  DdsApiInstanceLogsComponent,
  ExternalBtnConfigComponent,
  TestComponent,
  TreeComponent,
  NavbarComponent,
  SpinnerComponent,
  CustomDatatableComponent,
  PagniatedDatatableComponent,
  DatatableSearchComponent,
  EmailInputComponent,
  SideContainerComponent,
  InfoTooltipComponent,
  NgCompleterComponent,
  NgCompleterV2Component,
  DatatableSearchComponent,
  SidebarComponent,
  FooterComponent,
  UserLogoutComponent,
  UserLoginComponent,
  AdminLoginComponent,
  AdminLogoutComponent,
  RecordComparisonComponent,
  CopyTextComponent,
  TabsComponent,
  ClipboardKeyParserComponent,
  TogglePasswordComponent
]

const components = [
  LoginComponent,
  ViewDashboardMainComponent,
  ViewDashboardTabMainComponent,
  ViewReportDashboardMainComponent,
  EmailConfigComponent,
]

const shared_modules = [
  FormsModule,
  ReactiveFormsModule,
  DataTablesModule,
  CodemirrorModule,
  FontAwesomeModule,
  Ng2CompleterModule,
  CKEditorModule,
  DashboardModule,
  ReportModule,
  AceEditorModule,
  NgxDatatableModule,
  NgxJsonViewerModule,
  SnotifyModule
]

const modules = [
  CommonModule,
  SharedRoutingModule,
  AutocompleteLibModule,
  Ng2LoadingSpinnerModule.forRoot({animationType  : ANIMATION_TYPES.dualCircle, spinnerSize: 'xl'}),
  NgSelectModule,
]

const directives = [
  ButtonClickDirective, //appButtonClick
  NzRoleDirective, // appNzRole
  TogglerComponent, // toggler
  BtnLoaderDirective, // btnLoader
  LowerCaseTextDirective, // LowerCase
  InputAlphaNumericDirective // appInputAlphaNumeric
]
@NgModule({
  declarations: [
    shared_components,
    components,
    directives
  ],
  imports: [
    shared_modules,
    modules,
  ],
  exports: [
    shared_modules,
    shared_components,
    directives,
  ],
})
export class SharedModule { }
