import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvService } from '../../services/env.service';

@Injectable({
	providedIn: 'root'
})
export class TreeService {

	constructor(
		private http: HttpClient, 
		private env: EnvService
	) { }

	private _div_tree_data = `${this.env.wh_service_url}/whapi/`;
	//dds url
	private get_dds_tree_url = `${this.env.dds_service_url}/`;

	get_tree_data(service, id, menu_items) {
		let data = { "id": id, "menu_items": menu_items };
		if (service === 'get_project_tree' || service === 'get_version_tree' || service === 'version/source_tree') {
			return this.http.post<any>(this.get_dds_tree_url + service, data);
		}else if(service =='all_ao_map'){
			data['refresh'] = localStorage.getItem('ref_cache') == 'true' ? true:false
			return this.http.post<any>(this._div_tree_data + service, data);
		}
		 else {
			return this.http.post<any>(this._div_tree_data + service, data);
		}
	}

}
