import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../../services/shared.service';
import { Router, NavigationEnd} from '@angular/router';

import 'metismenu';
declare var $:any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  internal_role = '';
  submenu = false;
  user_sidebar_data: any;
  group_sidebar_data: any;
  sidebar_data = [];
  user_name = '';

  constructor(
    private _shared_service: SharedService,
    private _router: Router
  ) {
  //  this.get_user_sidebar_data();
      // this.get_group_sidebar_data();
    this.user_sidebar_data = JSON.parse(localStorage.getItem('sidebar_data_2'));
    this.group_sidebar_data = JSON.parse(localStorage.getItem('sidebar_data_1'));

    this.user_name = '';
    if(localStorage.getItem("user_name") != null){
      this.user_name = localStorage.getItem("user_name");
    }
    if(localStorage.getItem("internal_role") != null){
      this.internal_role = localStorage.getItem("internal_role");
    }
    

   }

  hide_data(index) {
    
  }

  get_group_sidebar_data() {
    this._shared_service.current_sidebar_data_1.subscribe(
      res => {
        this.group_sidebar_data = res;
        console.log(res);
      }
    );
  }

  get_user_sidebar_data() {
    this._shared_service.current_sidebar_data_2.subscribe(
      res => {
        this.user_sidebar_data = res;
        console.log(res);
      }
    );
  }

  ngOnInit() {
    setTimeout(() => {
      // alert(2000);
      $('#side-menu').metisMenu();
    }, 50);
  }

}
