import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TestComponent } from './test/test.component';
import{ EmailConfigComponent } from './email-config/email-config.component';

const routes: Routes = [
  {
    path: 'test',
    component: TestComponent
  },
  {
    path:'email-config',
    component:EmailConfigComponent 
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SharedRoutingModule { }
