import { Component, ElementRef, OnInit } from '@angular/core';
// import { FontawesomeService } from 'src/app/dce-views/dynamic-form/fontawesome.service';

@Component({
  selector: '[togglePassword]',
  templateUrl: './toggle-password.component.html',
  styleUrls: ['./toggle-password.component.css']
})
export class TogglePasswordComponent implements OnInit {

  showPass: boolean = true;
  constructor(
    private el: ElementRef,
    // public fa:FontawesomeService,
  ) { }

  ngOnInit() {
    console.log(this.el.nativeElement.type)
  }

  
  toggleInputType() {
    const currentInputType = this.el.nativeElement.querySelector('input').type;
    if(currentInputType == 'text') {
      this.el.nativeElement.querySelector('input').type = 'password'
    } else {
      this.el.nativeElement.querySelector('input').type = 'text'
    }
    this.showPass = !this.showPass
  }
}