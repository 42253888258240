import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// import { DashboardModule } from './modules/dashboard/dashboard.module';
import { SharedModule } from './modules/shared/shared.module';
import { HttpClientModule} from '@angular/common/http';

// import { DataTablesModule } from 'angular-datatables';
// import { CodemirrorModule } from 'ng2-codemirror';
// import { QueryBuilderComponent } from './components/query-builder/query-builder.component';
// import { QueryBuilderFormComponent } from './components/query-builder-form/query-builder-form.component';
// import { CustomDashboardComponent } from './components/custom-dashboard/custom-dashboard.component';
// import { ViDrillFilterComponent } from './components/vi-drill-filter/vi-drill-filter.component';
// import { ViBuilderComponent } from './components/vi-builder/vi-builder.component';
// import { Ng2CompleterModule } from 'ng2-completer';
// import { AngularFontAwesomeModule } from 'angular-font-awesome';
// import { ReactiveFormsModule } from '@angular/forms';
// import { Ng2LoadingSpinnerModule } from 'ng2-loading-spinner'
// import { ANIMATION_TYPES } from 'ng2-loading-spinner';
import { SharedService } from './services/shared.service';

// // FusionCharts
// import { FusionChartsModule } from 'angular-fusioncharts';
// import * as FusionCharts from '../assets/js/fusioncharts/fusioncharts';
// import * as Charts from '../assets/js/fusioncharts/fusioncharts.charts';
// FusionChartsModule.fcRoot(FusionCharts, Charts);

// import { CreateDashboardComponent } from './components/create-dashboard/create-dashboard.component';
// import { CreateDashboardGroupComponent } from './components/create-dashboard-group/create-dashboard-group.component';
// import { CamelCaseWithoutOrderPipe } from './pipe/camel-case-without-order.pipe';
// import { DndListModule } from 'ngx-drag-and-drop-lists';
import { SnotifyModule, SnotifyService, ToastDefaults } from 'ng-snotify';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

// import { NavbarComponent } from './components/navbar/navbar.component';
// import { ViewDashboardComponent } from './components/view-dashboard/view-dashboard.component';
// import { ViewDashboardMainComponent } from './components/view-dashboard-main/view-dashboard-main.component';
// import { SidebarComponent } from './components/user-app/sidebar/sidebar.component';
import { NzFlowComponent } from './components/admin-app/nz-flow/nz-flow.component';
// import { GenerateReportComponent } from './components/adminb-app/generate-report/generate-report.component';
// import { TreeComponent } from './components/tree/tree.component';
// import { CKEditorModule } from 'ng2-ckeditor';
// import { CommunicationTemplateComponent } from './components/communication-template/communication-template.component';
// import { LoginComponent } from './components/user-app/login/login.component';
// import { HomepageComponent } from './components/user-app/homepage/homepage.component';
// import { UserDashboardGroupComponent } from './components/user-dashboard-group/user-dashboard-group.component';
import { httpInterceptorProviders } from './services/index';
// import { CreateReportDashboardComponent } from './components/admin-app/create-report-dashboard/create-report-dashboard.component';
// import { CreateReportDashboardGroupComponent } from './components/admin-app/create-report-dashboard-group/create-report-dashboard-group.component';
// import { CustomReportDashboardComponent } from './components/admin-app/custom-report-dashboard/custom-report-dashboard.component';
// import { UserReportDashboardGroupComponent } from './components/admin-app/user-report-dashboard-group/user-report-dashboard-group.component';
// import { ViewReportDashboardMainComponent } from './components/shared/view-report-dashboard-main/view-report-dashboard-main.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
// import { ViewDashboardTabMainComponent } from './components/shared/view-dashboard-tab-main/view-dashboard-tab-main.component';
// import { FooterComponent } from './components/shared/footer/footer.component';
@NgModule({
  declarations: [
    AppComponent,
    // QueryBuilderComponent,
    // QueryBuilderFormComponent,
    // CustomDashboardComponent,
    // ViDrillFilterComponent,
    // ViBuilderComponent,
    // CreateDashboardComponent,
    // CreateDashboardGroupComponent,
    // NavbarComponent,
    // CamelCaseWithoutOrderPipe,
    // ViewDashboardComponent,
    // TreeComponent,
    // ViewDashboardMainComponent,
    // CommunicationTemplateComponent,
    // LoginComponent,
    // HomepageComponent,
    // SidebarComponent,
    NzFlowComponent,
    // ViewDashboardTabMainComponent,
    // FooterComponent,
    // CreateReportDashboardComponent,
    // CreateReportDashboardGroupComponent,
    // CustomReportDashboardComponent,
    // UserReportDashboardGroupComponent,
    // ViewReportDashboardMainComponent,
    // GenerateReportComponent,
    // UserDashboardGroupComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    // DashboardModule,
    SharedModule,
    // DataTablesModule,
    // FormsModule,
    HttpClientModule,
    // CodemirrorModule,
    // Ng2CompleterModule,
    // AngularFontAwesomeModule,
    // FusionChartsModule,
    // ReactiveFormsModule,
    // DndListModule,
    // SnotifyModule,
    // CKEditorModule,
    NgbModule.forRoot(),
    FontAwesomeModule,
    BrowserAnimationsModule
    // Ng2LoadingSpinnerModule.forRoot({animationType  : ANIMATION_TYPES.dualCircle,spinnerSize: 'xl',}),
  ],
  providers: [
    SharedService,
    { provide: 'SnotifyToastConfig', useValue: ToastDefaults},
    SnotifyService,httpInterceptorProviders
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    library.add(fas);
  }
 }
