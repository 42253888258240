import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../../services/common.service';
import { SharedService } from '../../../services/shared.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router,NavigationEnd } from '@angular/router';
import {SnotifyService} from 'ng-snotify';
import { UtilityService } from '../../../services/utility.service';
import { EnvService } from '../../../services/env.service';
import 'metismenu';

declare var $:any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

	// Variables Initialised
  login_payload: FormGroup;
  login_loader = false;
  is_admin: boolean;
  url = [];
  user_roles = []
  base64_route:any = '';

  constructor(
  	private _common_service: CommonService,
    private _env: EnvService,
    private _activatedRoute: ActivatedRoute,
  	private _fb: FormBuilder,
    private _route_link: Router,
    private _shared_service: SharedService,
    private snotifyService: SnotifyService,
    private utilityService: UtilityService
  ) {
    this.url = this._route_link.url.split('/');
    if (this._env.isAdmin && this.url[1] == 'admin_lg') {
      this.is_admin = true;
    }else if(!this._env.isAdmin && this.url[1] == 'admin_lg'){
      this._route_link.navigate(['./app_lg/login']);
    } else {
      this.is_admin = false;
    }
    this._activatedRoute.queryParams.subscribe(
      (params) => {
        this.base64_route = params.redirect;
      }
    );
	}

  ngOnInit() {
		this.login_payload = this._fb.group({
      'username': this._fb.control('', Validators.required),
      'pwd': this._fb.control('', Validators.required)
		});

  }

  validate() {

  }
  
  async login() {
    this.login_loader = true;
    var log_payload=this.login_payload.value;  
    log_payload["is_admin_panel"]=this.is_admin==true?1:0
  	var login_data = await this._common_service.login(log_payload).toPromise();
  	if(!login_data.errCode) {
      const user_name = this.login_payload.value.username;
      this.user_roles = login_data.user_roles
			localStorage.setItem('user_name', user_name);
      localStorage.setItem( 'token' , JSON.stringify(login_data.token));
      localStorage.setItem('system_access_id', login_data.system_access_id);
      this._shared_service.reset_query_history();
      this._shared_service.reset_tabs_config();
      
  		// redirect to home

      if (this._env.isAdmin && this.url[1] == 'admin_lg') {
        localStorage.setItem('is_admin', 'true');
        localStorage.setItem('lg_admin','1');
        this.is_admin = true;
      } else {
        localStorage.setItem('is_admin', 'false');
        localStorage.setItem('lg_admin','0')
        this.is_admin = false;
      }

      if (this.is_admin && login_data.is_admin == '1') {
        if (this.base64_route != undefined) {
          const desired_route = atob(this.base64_route);
          this._route_link.navigateByUrl(desired_route);
          return;
        }
        this._route_link.navigate(['./lg_admin/querySetup'+'/-1'+'/-1']);
      } else if (this.is_admin && login_data.is_admin == '0') {
        this.snotifyService.error('Invalid credentials', this.utilityService.getConfig());
        this.login_loader = false;
        // alert("Invalid credentials");
      } else {
        // this._route_link.navigate(['./app/home']);
        // this.select_role()
        let internals = this.user_roles.filter(x => x.system_access_level == 'Internal App')
        if(internals.length == 0){
          this.select_role(null)
        }else if(internals.length == 1){
          this.select_role(internals[0]['role_name'])
        }else{
          $("#role_selection").modal("show");
        }
        this.login_loader = false;
      }

  	} else {
      this.login_loader = false;
  		// alert("Invalid Login");
      // this.snotifyService.error('Invalid Login', this.utilityService.getConfig());

  	}

  }
  async select_role(role){
    $("#role_selection").modal("hide");
    const user_name = this.login_payload.value.username;
    localStorage.setItem('internal_role', role)
    let dg_payload = {
      user_name:user_name,
      user_profile_name: role
    }
    var sidebar_data_2 = await this._common_service.get_dshb_grp(dg_payload).toPromise();
    // update sidebar through shared service
    // console.log(this._shared_service.user_dashgroup_data);
    if(sidebar_data_2.errCode == 0){
      var sidebar_data_1 = await this._common_service.get_all_user_dashboard(dg_payload).toPromise();
      if(sidebar_data_1.errCode == 0){
        this._shared_service.user_dashgroup_data = sidebar_data_2.data;
        this._shared_service.group_dashboard_data = sidebar_data_1.data;
        // console.log(this._shared_service.user_dashgroup_data);

        localStorage.setItem( 'sidebar_data_1' , JSON.stringify(sidebar_data_1.data));
        localStorage.setItem( 'sidebar_data_2' , JSON.stringify(sidebar_data_2.data));
      }
    }
    this._route_link.navigate(['./app/home']);
    
  }
}


